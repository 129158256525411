import React, { useState, useRef, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { UxAction, ClockIcon } from "@wne-spa/ux-component-library";
import { useTranslation } from "react-i18next";
import configs from "../../helpers/configs";
import "./sessionManager.scss";
import { connect } from "react-redux";
import { renewToken, logout } from "../../authService/AuthService";
import { persistor, AppDispatch } from "../../Redux/Store";
import { AdminService } from "../../services/helpers/api.services";
import moment from "moment";
import jwt_decode from "jwt-decode";
import GetToken from "../../authService/GetToken";
import { UserService } from "../../services/helpers/user.services";
import { updateDoLittleToken, updateSessionModal } from "../../Redux/Action";
import ReduxProps from "Redux/Redux.props";
import { BehaviorSubject } from "rxjs";
function mapStateToProps(state: ReduxProps) {
  return {
    getSidebarActiveClass: state.getSidebarActiveClass,
    getUserRoleId: state.getUserRoleId,
    getUserId: state.getUserId,
    getUserRole: state.getUserRole,
    getDaDetails: state.getDaDetails,
    getBreadCrumbsData: state.getBreadCrumbsData,
    isSessionModal: state.isSessionModal
  };
}
type sessionManagerProps = {
  dispatch: AppDispatch;
  tokenSubject: BehaviorSubject<string>;
};

interface Props extends sessionManagerProps, ReduxProps {}
function SessionManager(props: Props) {
  const { getUserId, getUserRole, tokenSubject, getUserRoleId, dispatch } = props;
  const { t } = useTranslation();
  const interval = useRef<ReturnType<typeof setTimeout>>();
  const [showTimeOut, setShowTimeOut] = useState(false);
  const remMinsRef = useRef(getUserRoleId === 6 ? 2 : 4);
  const remSecsRef = useRef(59);
  const [remMins, setRemMins] = useState(getUserRoleId === 6 ? 2 : 4);
  const [remSecs, setRemSec] = useState(59);

  useEffect(() => {
    dispatch(updateSessionModal(false));
    if (showTimeOut) {
      dispatch(updateSessionModal(true));
      setTimeout(() => {
        document.getElementById("modaltimer").click();
      }, 2000);

      window.addEventListener("keydown", function (event) {
        let StudentNoButton = document.querySelector(".sessioncancelButton");
        if (StudentNoButton === document.activeElement && event.key === "Tab") {
          event.preventDefault();
          let timeOutHeaderElement = document.getElementById("timeout");
          timeOutHeaderElement.focus();
        }
      });
    }

    if (showTimeOut) {
      if (window.devicePixelRatio > 2) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    }
  }, [showTimeOut]);

  window.onresize = () => {
    if (showTimeOut) {
      if (window.devicePixelRatio > 2) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    }
  };

  const clickref = useRef();
  useOutsideAlerter(clickref);

  async function tokenCheck() {
    //@ts-ignore
    let nbf = jwt_decode(GetToken()).nbf;
    let timeDiff = new Date().getTime() - new Date(nbf * 1000).getTime();

    timeDiff = Math.ceil(timeDiff / 1000 / 60);

    if (timeDiff >= parseInt(window.APP_ENV.refresh_token_expiry_time)) {
      Logout();
    } else {
      if (localStorage.getItem("lms:isRenew") === "false") {
        localStorage.setItem("lms:isRenew", "true");
        try {
          await renewToken().then(async (user) => {
            tokenSubject.next(user.access_token);
          });
          localStorage.setItem("lms:isRenew", "false");
        } catch {
          Logout();
        }
      }
    }
  }

  const handleOnIdle = () => {
    if (!showTimeOut) {
      setShowTimeOut(true);
      interval.current = setInterval(() => {
        decrementClock();
      }, 1000);
    }
  };

  const handleOnActive = () => {
    //do nothing
  };

  const handleOnAction = () => {
    //do nothing
  };

  async function decrementClock() {
    if (remMinsRef.current === 0 && remSecsRef.current === 0) {
      remMinsRef.current = 0;
      remSecsRef.current = 0;
      setRemMins(0);
      setRemSec(0);
      setShowTimeOut(false);
      localStorage.removeItem("lms:isRenew");
      Logout();
    }
    if (remMinsRef.current !== 0 && remSecsRef.current === 0) {
      remMinsRef.current -= 1;
      remSecsRef.current = 60;
      setRemMins((remMins) => remMins - 1);
    }
    remSecsRef.current -= 1;
    setRemSec((prevValue) => (remSecsRef.current === 59 ? 59 : prevValue - 1));
  }

  function Logout() {
    localStorage.removeItem("oidc:session");
    localStorage.removeItem("page");
    localStorage.removeItem("oidc:state");
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).includes("oidc.")) {
        localStorage.removeItem(localStorage.key(i));
      }
    }
    localStorage.removeItem("persist:mypath-reports");
    sendLogoutEvent();
  }

  async function sendLogoutEvent() {
    try {
      const clientDateTime = moment().format();
      const userAgent = navigator.userAgent;
      const userId = getUserId;
      const userRole = getUserRole;
      const jsonObj = {
        userId: userId,
        clientDateTime: clientDateTime,
        userAgent: userAgent,
        userRole: userRole
      };
      if (getUserRoleId === 6) {
        await fetch(`${window.APP_ENV.userApiUrl}/api/studentlogoutEvent`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetToken()}`
          },
          body: JSON.stringify(jsonObj)
        });
      } else {
        AdminService.saveLogoutevent(jsonObj).then(({ status, data }: { status: number; data: Response }) => {
          //do nothing
        });
      }

      await persistor.purge();
      localStorage.clear();
      logout();
    } catch (err) {
      localStorage.clear();
      logout();
    }
  }

  async function close() {
    clearInterval(interval.current);
    setShowTimeOut(false);
    remMinsRef.current = getUserRoleId === 6 ? 2 : 4;
    remSecsRef.current = 59;
    setRemMins(getUserRoleId === 6 ? 2 : 4);
    setRemSec(59);
    localStorage.setItem("lms:isRenew", "false");
    await tokenCheck();
    if (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) {
      getDoLittleToken();
    }
  }

  async function getDoLittleToken() {
    try {
      await UserService.getDoLittleToken().then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          setDoLittleToken(data);
        } else {
          // do nothing
        }
      });
    } catch (err) {}
  }

  async function setDoLittleToken(responsedata: Response) {
    let details = await responsedata.json();
    dispatch(updateDoLittleToken(details.token));
  }

  useIdleTimer({
    timeout: Number(
      `${getUserRoleId === 6 ? 1000 * 60 * configs.studentSessionTimeout : 1000 * 60 * configs.sessionTimeout}`
    ),
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  });
  function useOutsideAlerter(ref: React.MutableRefObject<HTMLDivElement>) {
    useEffect(() => {
      document.addEventListener("click", outsideClick);
      function outsideClick(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          event.preventDefault();
          document.getElementById("layoutId").style.pointerEvents = "none";
        }
      }

      return () => {
        document.removeEventListener("click", outsideClick);
      };
    }, [ref]);
  }
  return (
    <div>
      {showTimeOut ? (
        <div
          className="timeOutBackground sessionoverlay"
          id="modaldialog"
          ref={clickref}
          role="dialog"
          aria-labelledby="dialogHeading"
        >
          <div className="timeOutDailog" id="modaltimer">
            <div className="timeOutHeader" id="timeout" tabIndex={0} aria-live="polite" role="heading" aria-level={2}>
              <p className="tHeading" id="dialogHeading">
                {getUserRoleId === 6 ? " Are You Still There?" : "Session Timeout"}{" "}
              </p>
            </div>
            <div className="timeOutMessage" aria-hidden="true">
              <p className={getUserRoleId === 6 ? "tMessage tStudentMessage" : "tMessage"}>
                <img alt="" src={ClockIcon} className="clockIcon" />
                {getUserRoleId === 6
                  ? "You don't seem to be active and will be logged out in"
                  : "Your session has been inactive and is about to timeout in: "}
              </p>
            </div>
            <div className="timeOutTimer">
              <div className="timeOutMins" role="timer" aria-live="polite" aria-atomic="true">
                <p className="tMins"> {remMins}</p>
                <p style={{ opacity: 0 }}> minutes</p>
              </div>

              <div className="timeOutSecs" role="timer" aria-live="polite" aria-atomic="true">
                <p className="tMins">
                  {" "}
                  {remSecs < 10 ? 0 : null}
                  {remSecs}{" "}
                </p>
                <p style={{ opacity: 0 }}>seconds</p>
              </div>
            </div>
            <div>
              {getUserRoleId === 6 ? (
                <p className="tMessage tStudentMessage" aria-hidden="true">
                  Are you still working?
                </p>
              ) : (
                <>
                  <p className="tMessage"> Please click "Continue" to keep working or click </p>
                  <p className="tMessage"> "Logout" to end your session now.</p>
                </>
              )}
            </div>
            <div className="timeOutFooter" style={{ marginLeft: getUserRoleId === 6 ? "160px" : "120px" }}>
              <UxAction
                callback={() => {
                  dispatch(updateSessionModal(false));
                  close();
                }}
                text={getUserRoleId === 6 ? t("Yes") : t("Continue")}
                className={getUserRoleId === 6 ? "btn-primary sessionbutton " : "btn-primary"}
                size={"lg"}
              />
              <UxAction
                callback={async () => {
                  localStorage.removeItem("lms:isRenew");
                  localStorage.removeItem("persist:growth-reports");
                  localStorage.removeItem("persist:mypath-reports");
                  dispatch(updateSessionModal(false));
                  Logout();
                }}
                text={getUserRoleId === 6 ? t("No") : t("Logout")}
                className={
                  getUserRoleId === 6
                    ? "sessionbutton cancelButton sessionLogout sessioncancelButton"
                    : "cancelButton sessionLogout"
                }
                size={"lg"}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default connect(mapStateToProps)(SessionManager);
