/* eslint-disable array-callback-return */
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  TimesIcon,
  UXCSVExport,
  UxFilterSlider,
  UxIcon,
  UxIconEnum,
  UxMUITable,
  UxPaginate,
  UxPaginationController,
  UxSearchInput,
  UxSizeEnum,
  UxSliderAction
} from "@wne-spa/ux-component-library";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import configMessages from "../../helpers/configMessages";
import { paginationControllers } from "../../helpers/configs";
import HelpIcon from "../../images/help_search_icon.png";
import {
  updateActivityIndicator,
  updateBreadCrumbs,
  updateCicEnable,
  updateEducatorListSort,
  updateEducatorsFilter,
  updatePaginationControllerState
} from "../../Redux/Action";
import ReduxProps from "../../Redux/Redux.props";
import { AdminService } from "../../services/helpers/api.services";
import { UserService } from "../../services/helpers/user.services";
import "./CreateEducators.scss";
import {
  ChildProps,
  CicsettingsRequestProps,
  EducatorListProps,
  TypesListProps
} from "./EducatorList.props";
import { IEducators } from "./IEducators";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getActivityIndicator: state.getActivityIndicator,
    getUserRoleId: state.getUserRoleId,
    getDaDetails: state.getDaDetails,
    getEducatorsFilter: state.getEducatorsFilter,
    getUserId: state.getUserId,
    getTabChange: state.getTabChange,
    getEducatorListSort: state.getEducatorListSort,
    getPaginationControllerState: state.getPaginationControllerState,
    getFlagrKeys: state.getFlagrKeys,
    getUserRole: state.getUserRole,
    getIsOrgHasRegistrationOwned: state.getIsOrgHasRegistrationOwned
  };
}
interface Props extends EducatorListProps, ReduxProps {}

function EducatorsList(props: Props): JSX.Element {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const navigate = useNavigate();
  const {
    isfrom,
    getBreadCrumbsData,
    dispatch,
    getUserRoleId,
    getDaDetails,
    getEducatorsFilter,
    getUserId,
    getTabChange,
    getEducatorListSort,
    getPaginationControllerState,
    getFlagrKeys
  } = props;
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const searchResultRef = useRef(false);
  const [icon, setIcon] = useState(false);
  const [data, setData] = useState({
    page: 0,
    size: 0,
    pageSize: 0,
    values: []
  });
  const sortBy = useRef(getEducatorListSort.name);
  const sortOrder = useRef(getEducatorListSort.val);
  const [educatorNameBool, setEducatorNameBool] = useState<boolean | null>(null);
  const [usernameBool, setUsernameBool] = useState<boolean | null>(null);
  const [schoolBool, setSchoolBool] = useState<boolean | null>(null);
  const [roleBool, setRoleBool] = useState<boolean | null>(null);
  const [statusBool, setStatusBool] = useState<boolean | null>(null);
  const [stateName, setStateName] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [sliderLeft, setSliderLeft] = useState("-330px");
  const [opacity, setOpacity] = useState(0);
  const [contentVisibility, setContentVisibility] = useState(false);
  const activeFilters = React.useRef<Array<string>>([]);
  const [filterCountClick, setFilterCountClick] = useState(false);
  const textMessage = React.useRef("none");
  const [emptyTableText, setEmptyTableText] = useState<string | JSX.Element>();
  const [hasDistrictAdminFilter, setHasDistrictAdminFilter] = useState(false);
  const elementRef = useRef(null);
  const [tableHgt, setTableHgt] = useState(0);
  const [columnNames, setColumnNames] = useState([
    "Educator Name",
    "Username",
    "Institution",
    "External ID",
    "Educator Type",
    "Status"
  ]);
  const [rowMapper, setrowMapper] = useState([
    ["lastName", "firstName"],
    "loginName",
    "organizations",
    "districtAssignedId",
    "roleName",
    "isEnabled"
  ]);
  const typesHeader = ["Type"];
  const types = {
    "School Administrator": "Type",
    "District Administrator": "Type",
    Teacher: "Type",
    Active: "Status",
    Inactive: "Status"
  };
  const [typesList, setTypesList] = useState<TypesListProps>({
    Type: ["District Administrator", "School Administrator", "Teacher"]
  });
  const filterStates = useRef<object>({
    "School Administrator": false,
    "District Administrator": false,
    Teacher: false,
    Active: false,
    Inactive: false
  });

  const helpSearch = (
    <div className="emptyContentWrapper">
      <img src={HelpIcon} className="resultIcon" />
      <div className="helpMessage">No Results Found </div>
      <div className="helpcenter">
        Try modifying the search terms or use the{" "}
        <a href="https://help.edgenuitymypath.com/hc/en-us/articles/4412008995479" target="_blank">
          tips
        </a>{" "}
        in this Help Center article.
      </div>
    </div>
  );
  const [csvData, setCSVData] = useState([]);
  const headersForCsv = [
    {
      label: "School ID",
      key: "schoolID"
    },
    {
      label: "Educator ID",
      key: "educatorID"
    },
    {
      label: "Educator Name",
      key: "educatorName"
    },
    {
      label: getFlagrKeys["ReadinessFeature_Educator_Email_Loginname"] ? "Username/Email" : "Username",
      key: "userName"
    },
    {
      label: "Institution",
      key: "institution"
    },
    {
      label: "External ID",
      key: "externalID"
    },
    {
      label: "Educator Type",
      key: "educatorType"
    },
    {
      label: "Status",
      key: "status"
    }
  ];
  if (activeFilters.current.includes("Active")) {
    const index = activeFilters.current.indexOf("Active");
    if (index !== -1) {
      activeFilters.current.splice(index, 1);
    }
  }
  async function callback(args: number) {
    let updateState = getPaginationControllerState;
    updateState[1] = { paginationPageName: "Educators", paginationCount: args };
    setPageNo(1);
    dispatch(updatePaginationControllerState(updateState));
    try {
      dispatch(updateActivityIndicator(true));
      UserService.updatePaginationCount(getUserId, 2, args).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            getEducators(1, searchText);
          } else {
            dispatch(updateActivityIndicator(false));
          }
        }
      );
    } catch (err) {
      dispatch(updateActivityIndicator(false));
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight > 650) {
        //@ts-ignore
        setTableHgt(
          //@ts-ignore
          parseInt(window.innerHeight - (elementRef.current.offsetTop + 75))
        );
      } else {
        //@ts-ignore
        setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [getTabChange]);

  useEffect(() => {
    if (window.innerHeight > 650) {
      //@ts-ignore
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef.current.offsetTop + 75))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
    }
  }, [getTabChange]);

  useEffect(() => {
    if (getFlagrKeys["ReadinessFeature_Educator_Email_Loginname"]) {
      if (isfrom === "institutions") {
        setColumnNames(["Educator Name", "Username/Email", "Institution", "External ID", "Educator Type", "Status"]);
      } else if (isfrom === "school") {
        setColumnNames(["Educator Name", "Username/Email", "External ID", "Educator Type", "Status"]);
        setrowMapper([["lastName", "firstName"], "loginName", "districtAssignedId", "roleName", "isEnabled"]);
      }
    }
    if (isfrom === "school" && !getFlagrKeys["ReadinessFeature_Educator_Email_Loginname"]) {
      setColumnNames(["Educator Name", "Username", "External ID", "Educator Type", "Status"]);
      setrowMapper([["lastName", "firstName"], "loginName", "districtAssignedId", "roleName", "isEnabled"]);
    }

    if (window.innerHeight > 650) {
      //@ts-ignore
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef.current.offsetTop + 75))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
    }
    if (getEducatorListSort.val === "desc" || getEducatorListSort.val === "DESC") {
      if (getEducatorListSort.name === "lastname") {
        setEducatorNameBool(true);
      } else if (getEducatorListSort.name === "loginname") {
        setUsernameBool(true);
      } else if (getEducatorListSort.name === "rolename") {
        setRoleBool(true);
      } else if (getEducatorListSort.name === "organizations") {
        setSchoolBool(true);
      } else {
        setStatusBool(true);
      }
    } else if (getEducatorListSort.val === "asc" || getEducatorListSort.val === "ASC") {
      if (getEducatorListSort.name === "lastname") {
        setEducatorNameBool(false);
      } else if (getEducatorListSort.name === "loginname") {
        setUsernameBool(false);
      } else if (getEducatorListSort.name === "rolename") {
        setRoleBool(false);
      } else if (getEducatorListSort.name === "organizations") {
        setSchoolBool(false);
      } else {
        setStatusBool(false);
      }
    }

    let gLevel: string[] = [];
    let statesShow = {};
    if (isfrom === "school") {
      if (getEducatorsFilter.includes("District Administrator")) {
        setHasDistrictAdminFilter(true);
        const removeDA = getEducatorsFilter;
        removeDA.splice(removeDA.indexOf("District Administrator"), 1);
        activeFilters.current = removeDA;
      } else {
        activeFilters.current = getEducatorsFilter;
      }
    } else {
      activeFilters.current = getEducatorsFilter;
    }
    getEducatorsFilter.forEach((row: string) => {
      if (row === "Active") {
        statesShow = { ...statesShow, Status: ["Active"] };
      } else if (row === "Inactive") {
        statesShow = { ...statesShow, Status: ["Inactive"] };
      } else if (row === "District Administrator") {
        if (isfrom !== "school") {
          filterStates.current = { ...filterStates.current, [row]: true };
          gLevel.push(row);
        }
      } else {
        filterStates.current = { ...filterStates.current, [row]: true };
        gLevel.push(row);
      }
    });
    if (gLevel.length > 1) {
      statesShow = { ...statesShow, Type: gLevel };
    } else if (gLevel.length === 1) {
      statesShow = { ...statesShow, Type: [gLevel[0]] };
    }
    setStateName(statesShow);
    const fetchData = async () => {
      try {
        let FilterRoles = [];
        let filterStatus = [];
        for (let i in activeFilters.current) {
          if (activeFilters.current[i] === "District Administrator") {
            if (isfrom !== "school") {
              FilterRoles.push(3);
            }
          } else if (activeFilters.current[i] === "School Administrator") {
            FilterRoles.push(4);
          } else if (activeFilters.current[i] === "Teacher") {
            FilterRoles.push(5);
          }
        }
        const jsonObj = {
          Page: 1,
          PageSize: getPaginationControllerState[1].paginationCount,
          OrgGuid: id,
          SearchText: "",
          FilterRoleTypes: FilterRoles.join(),
          FilterStatus: "true",
          SortBy: sortBy.current,
          SortOrder: sortOrder.current
        };
        let urltype = "";
        if (isfrom === "school") {
          urltype = `educators`;
        } else {
          urltype = `dauser`;
        }

        if (urltype === "educators") {
          await UserService.getEducatorsSchoolLevelCSV(jsonObj).then(
            ({ status, data }: { status: number; data: Response }) => {
              gotCSVData(data);
            }
          );
          UserService.getEducatorsWithGuid(jsonObj).then(({ status, data }: { status: number; data: Response }) => {
            if (status === 200) {
              gotResponse(data);
            } else {
              dispatch(updateActivityIndicator(false));
            }
          });
        } else {
          await UserService.getEducatorsDistrictLevelCSV(jsonObj).then(
            ({ status, data }: { status: number; data: Response }) => {
              gotCSVData(data);
            }
          );
          UserService.getDAUsersWithGuid(jsonObj, id).then(({ status, data }: { status: number; data: Response }) => {
            if (status === 200) {
              gotResponse(data);
            } else {
              dispatch(updateActivityIndicator(false));
            }
          });
        }
      } catch (err) {
        dispatch(updateActivityIndicator(false));
        //do nothing
      }
    };
    dispatch(updateActivityIndicator(true));
    fetchData();
    if (isfrom === "school") {
      setTypesList({
        Type: ["School Administrator", "Teacher"],
        Status: ["Active", "Inactive"]
      });
    }
    // eslint-disable-next-line
  }, [id, isfrom]);

  useEffect(() => {
    fetchspanishids();
  }, [getTabChange]);

  function sortChange(type: string) {
    switch (type) {
      case "Educator Name":
        sortOrder.current = !educatorNameBool ? "desc" : "asc";
        sortBy.current = "lastname";
        break;
      case "Username/Email":
        sortOrder.current = !usernameBool ? "desc" : "asc";
        sortBy.current = "loginname";
        break;
      case "Institution":
        sortOrder.current = !schoolBool ? "desc" : "asc";
        sortBy.current = "organizations";
        break;
      case "Educator Type":
        sortOrder.current = !roleBool ? "desc" : "asc";
        sortBy.current = "rolename";
        break;
      default:
        sortOrder.current = !statusBool ? "desc" : "asc";
        sortBy.current = "isenabled";
    }
    getEducators(1, searchText);
    setPageNo(1);
  }
  const sortByFunc = () => {
    switch (sortBy.current) {
      case "lastname":
        setUsernameBool(null);
        setRoleBool(null);
        setStatusBool(null);
        setSchoolBool(null);
        break;
      case "loginname":
        setEducatorNameBool(null);
        setRoleBool(null);
        setStatusBool(null);
        setSchoolBool(null);
        break;
      case "organizations":
        setEducatorNameBool(null);
        setUsernameBool(null);
        setStatusBool(null);
        setRoleBool(null);
        break;
      case "rolename":
        setEducatorNameBool(null);
        setUsernameBool(null);
        setStatusBool(null);
        setSchoolBool(null);
        break;
      default:
        setEducatorNameBool(null);
        setUsernameBool(null);
        setRoleBool(null);
        setSchoolBool(null);
    }
  };

  async function gotResponse(responsedata: Response) {
    let allData = JSON.parse(await responsedata.text());
    setLoading(false);
    dispatch(updateActivityIndicator(false));
    sortByFunc();
    if (allData.size === 0) {
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });
      emptyTableTextFunc();
    } else {
      windowInnerHeight();
      let allUserid = [];
      const filteredDataOverIsSyncedValue = allData.values.filter((item) => item.isSynced !== true);
      for (let x in filteredDataOverIsSyncedValue) {
        //@ts-ignore
        allUserid.push(filteredDataOverIsSyncedValue[x].userGuid);
      }
      setData(allData);
    }
  }

  const windowInnerHeight = () => {
    if (window.innerHeight > 650) {
      //@ts-ignore
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef?.current?.offsetTop + 75))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(650 - (elementRef?.current?.offsetTop + 0)));
    }
  };
  const emptyTableTextFunc = () => {
    if ((activeFilters.current.includes("Active") || activeFilters.current.length === 0) && !searchResultRef.current) {
      if (isfrom === "school") {
        setEmptyTableText(<i>{configMessages.noEducatorsNoEdit}</i>);
      } else {
        setEmptyTableText(<i>{configMessages.noDistrictAdministrator}</i>);
      }
    } else if (
      (activeFilters.current.length > 0 && searchResultRef.current) ||
      activeFilters.current.length > 0 ||
      searchResultRef.current
    ) {
      setIcon(true);
      setEmptyTableText(helpSearch);
    } else {
      //do nothing
    }
  };

  const fetchspanishids = async () => {
    let apiObj = { guid: id.split("_")[0] };
    await AdminService.getParentsWithGuid(apiObj).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        getspanishidsResponse(data);
      } else {
        dispatch(updateActivityIndicator(false));
        // do nothing
      }
    });
  };

  async function getspanishidsResponse(responsedata: Response) {
    let spanishparents = [];
    let json: CicsettingsRequestProps = {
      currentLevelGuid: { type: "", guid: "" },
      parentsGuid: []
    };
    let details = await responsedata.json();
    let i = 0;
    let levelId = { type: "Custom", guid: details.orgGUID.split("_")[0] };

    if (details.parents != null) {
      let parentid = details.parents;

      for (i; i < parentid.length; i++) {
        spanishparents[i] = {
          type: "Custom",
          guid: parentid[i].orgGUID.toString()
        };
      }
    }

    json = {
      ...json,
      currentLevelGuid: levelId,
      //@ts-ignore
      parentsGuid: spanishparents
    };

    fetchCicsettings(json);
  }

  const fetchCicsettings = async (json: CicsettingsRequestProps) => {
    try {
      await AdminService.getCicSettingsWithGuid(json).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          gotCicSettingsResponse(data);
        } else {
          // do nothing
        }
      });
    } catch (err) {
      // do nothing
    }
  };
  async function gotCicSettingsResponse(responsedata: Response) {
    let details = await responsedata.json();
    dispatch(updateCicEnable(details.cicImportEnabled));
    localStorage.setItem("SpanishMathEnable", details.spanishLanguageEnabled);
    localStorage.setItem("SpanishScienceEnable", details.spanishLanguageScienceEnabled);
  }

  const handleClick = (row: ChildProps) => {
    let breadcrumbs = getBreadCrumbsData.crumbs;
    if (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) {
      breadcrumbs.push({
        path: `/cvieweducator/${row.userGuid}`,
        name: `${row.firstName} ${row.lastName}`
      });
      dispatch(updateBreadCrumbs({ crumbs: breadcrumbs }));
      navigate(`/cvieweducator/${id}_${row.userGuid}`);
    } else if (getUserRoleId === 3) {
      breadcrumbs.push({
        path: `/dvieweducator/${row.userGuid}`,
        name: `${row.firstName} ${row.lastName}`
      });
      dispatch(updateBreadCrumbs({ crumbs: breadcrumbs }));
      navigate(`/dvieweducator/${id}_${row.userGuid}`);
    } else if (getUserRoleId === 4 && getDaDetails.type === 2) {
      breadcrumbs.push({
        path: `/svieweducator/${row.userGuid}`,
        name: `${row.firstName} ${row.lastName}`
      });
      dispatch(updateBreadCrumbs({ crumbs: breadcrumbs }));
      navigate(`/svieweducator/${id}_${row.userGuid}`);
    } else if (getUserRoleId === 4 && getDaDetails.type === 1) {
      breadcrumbs.push({
        path: `/suvieweducator/${row.userGuid}`,
        name: `${row.firstName} ${row.lastName}`
      });
      dispatch(updateBreadCrumbs({ crumbs: breadcrumbs }));
      navigate(`/suvieweducator/${id}_${row.userGuid}`);
    }
    // do nothing
  };

  const Styles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        "& > *": {
          marginTop: theme.spacing(2)
        }
      }
    })
  );
  let classes = Styles();

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(updateActivityIndicator(false));
    setPageNo(value);
    getEducators(value, searchText);
  };

  function restoreData() {
    activeFilters.current = [];
    filterStates.current = {
      "School Administrator": false,
      "District Administrator": false,
      Teacher: false,
      Active: false,
      Inactive: false
    };
    setFilterCountClick(false);
    setStateName({});
    getEducators(1, "");
  }

  function handleSearchTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.charAt(0) !== " ") {
      setSearchText(event.target.value);
    }
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key === "Enter" && searchText !== "") {
      searchResultRef.current = true;
      textMessage.current = "Search";
      getEducators(1, searchText);
      setPageNo(1);
    }
  }

  function renderSearchButton() {
    return <UxIcon icon={UxIconEnum.SEARCH} size={UxSizeEnum.MEDIUM} className="searchIcon" />;
  }

  function handleSearchButtonClick(): void {
    if (searchText !== "") {
      searchResultRef.current = true;
      textMessage.current = "Search";
      getEducators(1, searchText);
      setPageNo(1);
    }
  }
  const getEducatorsSubFunc = async (pagenumber: number, stext: string) => {
    let FilterRoles = [];
    let filterStatus = [];
    for (let i in activeFilters.current) {
      switch (activeFilters.current[i]) {
        case "District Administrator":
          FilterRoles.push(3);
          break;
        case "School Administrator":
          FilterRoles.push(4);
          break;
        case "Teacher":
          FilterRoles.push(5);
          break;
        case "Active":
          filterStatus.push(true);
          break;
        case "Inactive":
          filterStatus.push(false);
      }
    }
    const jsonObj = {
      Page: pagenumber,
      PageSize: getPaginationControllerState[1].paginationCount,
      OrgGuid: id,
      SearchText: stext,
      FilterRoleTypes: FilterRoles.join(),
      FilterStatus: "true",
      SortBy: sortBy.current,
      SortOrder: sortOrder.current
    };
    let urltype = "";
    if (isfrom === "school") {
      urltype = `educators`;
    } else {
      urltype = `dausers`;
    }

    if (urltype === "educators") {
      const getEducators = async () => {
        await UserService.getEducatorsSchoolLevelCSV(jsonObj).then(
          ({ status, data }: { status: number; data: Response }) => {
            gotCSVData(data);
          }
        );
        await UserService.getEducatorsWithGuid(jsonObj).then(({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            gotResponse(data);
          } else {
            dispatch(updateActivityIndicator(false));
          }
        });
      };
      setTimeout(getEducators, 1000);
    } else {
      UserService.getEducatorsDistrictLevelCSV(jsonObj).then(({ status, data }: { status: number; data: Response }) => {
        gotCSVData(data);
      });
      UserService.getDAUsersWithGuid(jsonObj, id).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          gotResponse(data);
        } else {
          dispatch(updateActivityIndicator(false));
        }
      });
    }
  };
  async function gotCSVData(responsedata: Response) {
    const csvData = JSON.parse(await responsedata.text());
    setCSVData(csvData);
  }
  async function getEducators(pagenumber: number, stext: string) {
    try {
      if (activeFilters.current.length === 0) {
        if (hasDistrictAdminFilter) {
          let newFilter = [];
          newFilter.push("District Administrator");
          dispatch(updateEducatorsFilter(newFilter));
        } else {
          dispatch(updateEducatorsFilter([]));
        }
      } else {
        if (isfrom === "school") {
          if (hasDistrictAdminFilter) {
            let newFilter = activeFilters.current.slice();
            newFilter.push("District Administrator");
            dispatch(updateEducatorsFilter(newFilter));
          } else {
            dispatch(updateEducatorsFilter(activeFilters.current));
          }
        } else {
          dispatch(updateEducatorsFilter(activeFilters.current));
        }
      }
      dispatch(updateActivityIndicator(true));
      dispatch(updateEducatorListSort({ name: sortBy.current, val: sortOrder.current }));
      getEducatorsSubFunc(pagenumber, stext);
    } catch (err) {
      //do nothing
      dispatch(updateActivityIndicator(true));
    }
  }

  function renderCancelButton() {
    if (searchText !== "" && !searchResultRef.current) {
      return <img src={TimesIcon} alt="" width="20px" height="20px" />;
    } else if (searchText !== "" && searchResultRef.current) {
      return (
        <div className="searchIconText">
          <span> Clear Results </span>
        </div>
      );
    }
  }

  function clearButtonClick() {
    if (searchText !== "" && !searchResultRef.current) {
      setSearchText("");
    } else if (searchText !== "" && searchResultRef.current) {
      setSearchText("");
      searchResultRef.current = false;
      setSearchText("");
      getEducators(1, "");
      setPageNo(1);
    }
  }

  const scrollableETableData = () => {
    if (data.values.length > 0 && isfrom === "school") {
      return (
        <UxMUITable
          MUITableClassName={data.values.length === 0 ? "emptyTable1" : "scrollableETable"}
          columnNames={columnNames}
          rowMapper={rowMapper}
          rowData={(data as IEducators).values}
          showColumnCheckbox={false}
          viewChild={handleClick}
          emptyTableText={emptyTableText}
          checkboxRef={"userGuid"}
          selected={[]}
          mtableHeight={tableHgt}
          setSelected={() => {}}
          showSortList={[true, true, false, true, true]}
          hideCheckboxOverValue="isSynced"
          sortListMapper={{
            "Educator Name": [
              educatorNameBool,
              setEducatorNameBool,
              (args: string) => {
                sortChange(args);
              }
            ],
            ...(getFlagrKeys["ReadinessFeature_Educator_Email_Loginname"]
              ? {
                  "Username/Email": [
                    usernameBool,
                    setUsernameBool,
                    (args: string) => {
                      sortChange(args);
                    }
                  ]
                }
              : {
                  Username: [
                    usernameBool,
                    setUsernameBool,
                    (_args: string) => {
                      sortChange("Username/Email");
                    }
                  ]
                }),
            "Educator Type": [
              roleBool,
              setRoleBool,
              (args: string) => {
                sortChange(args);
              }
            ],
            Status: [
              statusBool,
              setStatusBool,
              (args: string) => {
                sortChange(args);
              }
            ]
          }}
        />
      );
    } else if (!icon && data.values.length === 0) {
      return (
        <UxMUITable
          MUITableClassName={data.values.length === 0 ? "emptyTable1" : "scrollableETable"}
          columnNames={columnNames}
          rowMapper={rowMapper}
          rowData={(data as IEducators).values}
          showColumnCheckbox={false}
          viewChild={handleClick}
          emptyTableText={emptyTableText}
          checkboxRef={"userGuid"}
          selected={[]}
          mtableHeight={tableHgt}
          setSelected={() => {}}
          removeEditabilityFlag={true}
        />
      );
    } else {
      return (
        <UxMUITable
          MUITableClassName={data.values.length === 0 ? "emptyTable1" : "scrollableETable"}
          columnNames={columnNames}
          rowMapper={rowMapper}
          rowData={(data as IEducators).values}
          showColumnCheckbox={false}
          viewChild={handleClick}
          emptyTableText={emptyTableText}
          checkboxRef={"userGuid"}
          selected={[]}
          mtableHeight={tableHgt}
          showHelpIconFlag={true}
          setSelected={() => {}}
          removeEditabilityFlag={false}
        />
      );
    }
  };

  return (
    <>
      <div>
        <UxFilterSlider
          opacity={opacity}
          backgroundVisibility={contentVisibility}
          left={sliderLeft}
          sliderHeader="Filters"
          activeFilters={activeFilters.current}
          setActiveFilters={activeFilters}
          resetText="Reset Filters"
          onResetClick={() => {
            restoreData();
          }}
          activeFilterText="Active Filters"
          types={types}
          typesHeader={typesHeader}
          typesList={typesList}
          filterStates={filterStates.current}
          setFilterState={filterStates}
          setSliderLeft={setSliderLeft}
          setBackgroundVisibility={setContentVisibility}
          setOpacity={setOpacity}
          onChecked={() => {
            setPageNo(1);
            getEducators(1, searchText);
          }}
          textMessage={textMessage}
          setMultiFilterDropDown={setFilterCountClick}
          screen="educators"
          stateName={stateName}
          setStateName={setStateName}
        />
        <div className="elInputTextHolder">
          <div
            style={{
              marginTop: "25px",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row"
            }}
          >
            <UxSliderAction
              filterText="Filters"
              activeFilters={activeFilters.current}
              minCount={1}
              multiFilterDropDown={filterCountClick}
              filterStates={filterStates.current}
              setFilterState={filterStates}
              setActiveFilters={activeFilters}
              setMultiFilterDropDown={setFilterCountClick}
              setSliderLeft={setSliderLeft}
              left={sliderLeft}
              setBackgroundVisibility={setContentVisibility}
              setOpacity={setOpacity}
              types={types}
              onChecked={() => {
                setPageNo(1);
                getEducators(1, searchText);
              }}
              textMessage={textMessage}
              stateName={stateName}
              setStateName={setStateName}
            />
          </div>

          <div
            className={isfrom === "school" ? "esearchCreateButtonHolder" : "edsearchCreateButtonHolder"}
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              marginTop: "25px",
              justifyContent: "center"
            }}
          >
            <UxSearchInput
              flexClassName={isfrom === "school" ? "educatorFlexHolder" : "edistrictFlexHolder"}
              inputClassName={
                getFlagrKeys["ReadinessFeature_Educator_Email_Loginname"] ? "eInputTextEducators" : "eInputText"
              }
              placeHolder={
                getFlagrKeys["ReadinessFeature_Educator_Email_Loginname"]
                  ? "Search Educator Name, Username/Email, External ID"
                  : "Search Educator Name, Username, External ID"
              }
              value={searchText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchTextChange(e)}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                handleKeyPress(e);
              }}
              searchButtonClassName="searchButton"
              searchButtonContent={renderSearchButton()}
              onSearchClick={() => {
                handleSearchButtonClick();
              }}
              clearButtonClassName="clearButton"
              clearButtonContent={renderCancelButton()}
              onClearClick={() => {
                clearButtonClick();
              }}
              restoreCheckBoxState={() => {}}
            />
            <div style={{ marginTop: "-24px", marginLeft: isfrom !== "school" && "15px" }}>
              <UXCSVExport
                data={csvData}
                headers={headersForCsv}
                filename={
                  "Educators_" +
                  getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name.replace(/[.]/g, " ") +
                  "_" +
                  (new Date(`${new Date()}`).getUTCMonth() + 1) +
                  "_" +
                  new Date(`${new Date()}`).getUTCDate() +
                  "_" +
                  new Date(`${new Date()}`).getUTCFullYear() +
                  "_" +
                  Date.now()
                }
              />
            </div>
          </div>
        </div>
      </div>
      {<div className="nobulkOptions"></div>}
      <div ref={elementRef}>
        {data.values.length > 0 && isfrom === "institutions" ? (
          <UxMUITable
            MUITableClassName={data.values.length === 0 ? "emptyTable1" : "scrollableETable"}
            columnNames={columnNames}
            rowMapper={rowMapper}
            rowData={(data as IEducators).values}
            showColumnCheckbox={false}
            viewChild={handleClick}
            emptyTableText={emptyTableText}
            checkboxRef={"userGuid"}
            selected={[]}
            mtableHeight={tableHgt}
            setSelected={() => {}}
            hideCheckboxOverValue="isSynced"
            showSortList={[true, true, true, false, true, true]}
            sortListMapper={{
              "Educator Name": [
                educatorNameBool,
                setEducatorNameBool,
                (args: string) => {
                  sortChange(args);
                }
              ],
              ...(getFlagrKeys["ReadinessFeature_Educator_Email_Loginname"]
                ? {
                    "Username/Email": [
                      usernameBool,
                      setUsernameBool,
                      (args: string) => {
                        sortChange(args);
                      }
                    ]
                  }
                : {
                    Username: [
                      usernameBool,
                      setUsernameBool,
                      (_args: string) => {
                        sortChange("Username/Email");
                      }
                    ]
                  }),
              Institution: [
                schoolBool,
                setSchoolBool,
                (args: string) => {
                  sortChange(args);
                }
              ],
              "Educator Type": [
                roleBool,
                setRoleBool,
                (args: string) => {
                  sortChange(args);
                }
              ],
              Status: [
                statusBool,
                setStatusBool,
                (args: string) => {
                  sortChange(args);
                }
              ]
            }}
          />
        ) : (
          scrollableETableData()
        )}
      </div>
      {loading ? null : (
        <div className="fixedpagination">
          <UxPaginate
            data={data}
            page={pageNo}
            paginationSize={getPaginationControllerState[1].paginationCount}
            className={classes.root}
            handleChange={handleChange}
          />
          {data.size > 50 && (
            <UxPaginationController
              rowsList={paginationControllers}
              setRowsCount={getPaginationControllerState[1].paginationCount}
              callback={callback}
            />
          )}
        </div>
      )}
    </>
  );
}

export default connect(mapStateToProps)(EducatorsList);
