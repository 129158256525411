import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  updateActivityIndicator,
  updateReportCard,
  updateTabName,
  updateClassDetails,
  updateAssessmentTab,
  updateLevel,
  updatePreviousURLForClassProgress,
  updateSchoolGuids,
  updateStudentsSubtab,
  updateClassViewTab
} from "../../Redux/Action";
import "./ReportsTab.scss";
import { UxTabs } from "@wne-spa/ux-component-library";
import { LMSService } from "../../services/helpers/lms.services";
import { AdminService } from "../../services/helpers/api.services";
import ReduxProps from "../../Redux/Redux.props";
import { ReportsProps, ItemProps, SchoolGuidsResponseProps, GuidResponseProps } from "./Reports.props";
import { RootComponentProps } from "../../root-component.props";
import StudentDropdownTab from "../HeaderTabs/StudentDropdownTab";
import AssessmentDropdownTab from "../HeaderTabs/AssessmentDropdownTab";

function mapStateToProps(state: ReduxProps) {
  return {
    getActivityIndicator: state.getActivityIndicator,
    getClassDetails: state.getClassDetails,
    getClassGUID: state.getClassGUID,
    getBreadCrumbsData: state.getBreadCrumbsData,
    getAssessmentTab: state.getAssessmentTab,
    getUpdateLevel: state.getUpdateLevel,
    getPreviousMyPathURL: state.getPreviousMyPathURL,
    getLSBReport: state.getLSBReport,
    getUserRoleId: state.getUserRoleId,
    getSchoolGuids: state.getSchoolGuids,
    getFlagrKeys: state.getFlagrKeys,
    getDistrictLevelFirstTab: state.getDistrictLevelFirstTab,
    getClassViewTab: state.getClassViewTab,
    getIsMyPathOnly: state.getIsMyPathOnly
  };
}
interface Props extends ReportsProps, ReduxProps, RootComponentProps {}

function MyPathAssessmentReports(props: Props) {
  const navigate = useNavigate();
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const [tabKey] = useState("reports");

  const {
    dispatch,
    getActivityIndicator,
    MyPathLevelSubject,
    getClassDetails,
    getClassGUID,
    getBreadCrumbsData,
    getAssessmentTab,
    getUpdateLevel,
    getPreviousMyPathURL,
    getLSBReport,
    getUserRoleId,
    getSchoolGuids,
    getDistrictLevelFirstTab,
    MyPathLoaderSubject,
    MyPathLoaderSubjectObservable,
    getFlagrKeys,
    mypathReportActiveFlagsSubject
  } = props;

  if (getFlagrKeys) {
    setActiveFlagForMyPathReport("MPAReport-AllowCSVExportAllData");
    setActiveFlagForMyPathReport("MPAReport-StudentIDCSVColumn");
    setActiveFlagForMyPathReport("MPAReport-DomainReporting");
  }

  function setActiveFlagForMyPathReport(flag: string) {
    if (getFlagrKeys[flag] && mypathReportActiveFlagsSubject.getValue().indexOf(flag) < 0) {
      mypathReportActiveFlagsSubject.next([...mypathReportActiveFlagsSubject.getValue(), flag]);
    }
  }

  const [selectedStudentView, setSelectedStudentView] = useState("home");
  const [getMFELoader, setMFELoader] = useState(MyPathLoaderSubject.getValue());

  useEffect(() => {
    dispatch(updateAssessmentTab("home"));
  }, []);

  useEffect(() => {
    MyPathLoaderSubjectObservable.subscribe((data: boolean) => {
      setMFELoader(data);
    });
    let level = checkLevel();
    dispatch(updateLevel(level));
    dispatch(updateActivityIndicator(false));
    if (!getLSBReport) {
      MyPathLevelSubject.next({
        name: getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name
      });
    }
    const fetchData = async () => {
      try {
        LMSService.getClassGUIDDetails(urlClassGuid).then(({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            classDetails(data);
          }
        });
      } catch (err) {
        //do nothing
      }
    };
    let urlClassGuid = window.location.pathname.split("/")[3];
    if (urlClassGuid != getClassGUID && level === "class") {
      fetchData();
    }
  }, [dispatch, getActivityIndicator, id]);

  async function classDetails(detail: Response) {
    //@ts-ignore
    let details = JSON.parse(await detail.text());
    dispatch(updateReportCard("mypathassessmentreport"));
    dispatch(
      updateClassDetails({
        classGUID: details.classGUID,
        classId: details.classID,
        className: details.classTitle
      })
    );
    MyPathLevelSubject.next({ name: details.classTitle });
  }

  let schoolLevel = ["school", "daschool", "saschool", "saclasslist"];
  let districtLevel = ["dainstitutions", "institutions", "district"];
  let classLevel = ["class", "classview"];
  let studentLevel = ["student"];

  function checkLevel() {
    let urlParts = window.location.pathname.split("/");
    for (let str in urlParts) {
      if (urlParts[str] != "") {
        if (schoolLevel.includes(urlParts[str])) {
          return "school";
        } else if (classLevel.includes(urlParts[str])) {
          return "class";
        } else if (districtLevel.includes(urlParts[str])) {
          return "district";
        } else if (studentLevel.includes(urlParts[str])) {
          return "student";
        }
      }
    }
  }

  function isReportsTabClicked(tab: string, _pagename: string = "") {
    if (tab === "students" || tab === "Assessments") {
      return;
    }
    dispatch(updateReportCard("home"));
    dispatch(updateAssessmentTab("home"));
    dispatch(updateStudentsSubtab("home"));

    if (tab === "sudo-students") {
      dispatch(updateTabName("students"));
      dispatch(updateStudentsSubtab(_pagename));
    } else if (tab === "sudo-Assessments") {
      dispatch(updateTabName("Assessments"));
      dispatch(updateAssessmentTab(_pagename));
    } else if (tab === "institutions" || tab === "school") {
      dispatch(updateTabName("schools"));
    } else if (tab !== "classprogress" && tab !== "classsummary") {
      dispatch(updateTabName(tab));
    }

    if (getUpdateLevel === "school") {
      if (getUserRoleId != 4 && getUserRoleId != 5) {
        fetchIds();
      } else {
        navigate(`${getPreviousMyPathURL}`);
      }
    } else if (getUpdateLevel === "district") {
      navigate(`${getPreviousMyPathURL}`);
    } else if (getUpdateLevel === "class" || getUpdateLevel === "student") {
      if (tab === "classprogress") {
        dispatch(updatePreviousURLForClassProgress(`/classview/${getClassDetails.classGUID}`));
        navigate("/classprogress");
      } else if (tab === "classsummary") {
        navigate(`/classsummary/${getClassDetails.classGUID}`);
      } else {
        dispatch(updateTabName(tab));
        dispatch(updateClassViewTab(tab));
        navigate(`/classview/${getClassDetails.classGUID}`);
      }
    }
  }
  
  const fetchIds = async () => {
    try {
      let ids = window.location.pathname.split("/");
      let guids: string[] = [];
      guids = [...guids, `${ids[ids.length - 1]}`];
      let orgguids = { guids };
      AdminService.getIds(orgguids).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          guidDetails(data, guids);
        }
      });
    } catch (err) {
      //do nothing
    }
  };

  async function guidDetails(repsonseData: Response, guids: string[]) {
    let details = JSON.parse(await repsonseData.text());
    let filteredData = details.filter((item: GuidResponseProps) => item.organizationGuid === guids[0]);
    let exist = getSchoolGuids.filter((item: SchoolGuidsResponseProps) => item.id === filteredData[0].organizationId);
    if (exist.length === 0) {
      let guids = [...getSchoolGuids, filteredData[0]];
      dispatch(updateSchoolGuids(guids));
    }

    if (getUserRoleId === 3) {
      navigate(`/daschool/${filteredData[0].organizationGuid}`);
    } else {
      navigate(`/school/${filteredData[0].organizationGuid}`);
    }
  }

  let classTabs = [
    {
      eventKey: "classsummary",
      title: "Class Summary"
    },
    {
      eventKey: "classprogress",
      title: "Class Progress"
    },
    {
      eventKey: "placement",
      title: "Student Placement"
    },
    {
      eventKey: "roster",
      title: "Student Roster"
    },

    {
      eventKey: "reports",
      title: "Reports"
    },

    {
      eventKey: "settings",
      title: "Settings"
    }
  ];

  function setAssessmentTab(tabName: string) {
    isReportsTabClicked("sudo-Assessments", tabName);
  }

  const setPage = (pageName: string) => {
    dispatch(updateAssessmentTab("home"));
    setSelectedStudentView(pageName);
    isReportsTabClicked("sudo-students", pageName);
  };

  let schoolTabs = [
    {
      eventKey: "classes",
      tabClassName: "",
      title: "Classes"
    },
    {
      eventKey: "students",
      tabClassName: "student-tab",
      title: <StudentDropdownTab onNavClick={setPage} currentView={selectedStudentView} />
    },
    {
      eventKey: "educators",
      tabClassName: "",
      title: "Educators"
    },
    ...(props.getIsMyPathOnly === false ? [{
      eventKey: "Assessments",
      tabClassName: "assessments-tab",
      title: <AssessmentDropdownTab onNavClick={setAssessmentTab} />
    }] : []),
    {
      eventKey: "reports",
      tabClassName: "",
      title: "Reports"
    },
    {
      eventKey: "AcademicSessions",
      tabClassName: "",
      title: "Academic Sessions"
    },
    {
      eventKey: "settings",
      tabClassName: "",
      title: "Settings"
    }
  ];

  let districtLeveltabs = [
    {
      eventKey: "institutions",
      tabClassName: "",
      title: getDistrictLevelFirstTab
    },
    {
      eventKey: "students",
      tabClassName: "student-tab",
      title: <StudentDropdownTab onNavClick={setPage} currentView={selectedStudentView} />
    },
    {
      eventKey: "educators",
      title: "Educators"
    },
    ...(props.getIsMyPathOnly === false ? [{
      eventKey: "Assessments",
      tabClassName: "assessments-tab",
      title: <AssessmentDropdownTab onNavClick={setAssessmentTab} />
    }] : []),
    {
      eventKey: "reports",
      title: "Reports"
    },
    {
      eventKey: "licensing",
      title: "Licensing"
    },
    {
      eventKey: "AcademicSessions",
      title: "Academic Sessions"
    },
    {
      eventKey: "settings",
      title: "Settings"
    }
  ];

  districtLeveltabs =
    getUserRoleId === 3
      ? districtLeveltabs.filter((item: ItemProps) => item.eventKey != "licensing")
      : districtLeveltabs;

  const updateChildrenLevel = () => {
    if (getUpdateLevel === "district") {
      return districtLeveltabs;
    } else {
      return classTabs;
    }
  };
  return (
    <>
      {!getLSBReport && (
        <UxTabs
          tabsId="class-tabs"
          activeKey={tabKey}
          onTabSelect={(tab: string) => {
            isReportsTabClicked(tab);
            localStorage.setItem("page", tab);
          }}
          children={getUpdateLevel === "school" ? schoolTabs : updateChildrenLevel()}
          className="pt-2"
          currentKey={tabKey}
        />
      )}
      <div className={getLSBReport ? "bar" : "barmargin"} style={{ width: "calc(100vw)" }}>
        {!getFlagrKeys["MPAReport-DomainReporting"] && (
          <div className="reportsBar">
            <label className="reportsBarTextSchedule">MyPath Assessment Report</label>
          </div>
        )}
        <div style={{ position: "relative" }}>
          <div id="mypath-reports"></div>
        </div>
      </div>
    </>
  );
}
export default connect(mapStateToProps)(MyPathAssessmentReports);
