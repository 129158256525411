// @ts-nocheck
import Settings from "../helpers/constants";
import jwt_decode from "jwt-decode";
import { renewToken, logout } from "./AuthService";
import moment from "moment";
import { store } from "../Redux/Store";

export default async function SessionChecker() {
  let userDetails = store.getState();
  let userData;
  if (
    sessionStorage.getItem(
      `oidc.user:${Settings().authority}:${
        localStorage.getItem("ala:MppLaunch") === "true" ? Settings().mpp_user_client_id : Settings().client_id
      }`
    )
  ) {
    let getUser = sessionStorage.getItem(
      `oidc.user:${Settings().authority}:${
        localStorage.getItem("ala:MppLaunch") === "true" ? Settings().mpp_user_client_id : Settings().client_id
      }`
    );
    //@ts-ignore
    let gotUser = JSON.parse(getUser);
    //@ts-ignore
    let exp = jwt_decode(gotUser.access_token).exp;
    //@ts-ignore
    exp = new Date(exp * 1000) < Date.now();
    if (exp) {
      if (localStorage.getItem("lms:isRenew") === "false") {
        localStorage.setItem("lms:isRenew", "true");
        try {
          await renewToken().then(async (user) => {
            userData = user;
          });
          localStorage.setItem("lms:isRenew", "false");
        } catch (err) {
          const clientDateTime = moment().format();
          const userAgent = navigator.userAgent;
          const userId = userDetails.getUserId;
          const userRole = userDetails.getUserRole;
          const jsonObj = {
            userId: userId,
            clientDateTime: clientDateTime,
            userAgent: userAgent,
            userRole: userRole
          };

          AdminService.saveLogoutevent(jsonObj).then(({ status, data }: { status: number; data: Response }) => {
            //do nothing
          });
          localStorage.removeItem("lms:isRenew");
          localStorage.removeItem("persist:growth-reports");
          localStorage.removeItem("persist:mypath-reports");
          localStorage.removeItem("oidc:session");
          localStorage.removeItem("page");
          localStorage.removeItem("oidc:state");
          for (let i = 0; i < localStorage.length; i++) {
            if (localStorage.key(i).includes("oidc.")) {
              localStorage.removeItem(localStorage.key(i));
            }
          }
          alert("Session expired. Please log in again.");
          //@ts-ignore
          logout();
        }
      } else {
        userData = gotUser;
      }
    } else {
      userData = gotUser;
    }
  } else {
    userData = "NO DATA";
  }
  return userData;
}
