import React, { useEffect } from "react";
import { connect } from "react-redux";
import { updateActivityIndicator, updateBreadCrumbs, updatePageTitle } from "../../Redux/Action";
import ReduxProps from "../../Redux/Redux.props";
import { AppDispatch } from "../../Redux/Store";
import { BehaviorSubject } from "rxjs";
import { LMSService } from "../../services/helpers/lms.services";
import { useNavigate } from "react-router-dom";
import { removeAllCustomArrayMethods } from "../../helpers/arrays";

function mapStateToProps(state: ReduxProps) {
  return {
    getDaDetails: state.getDaDetails,
    getAssignmentBuilderOrg: state.getAssignmentBuilderOrg,
    getSchoolGuids: state.getSchoolGuids,
    getUserRoleId: state.getUserRoleId,
    getBreadCrumbsData: state.getBreadCrumbsData,
    getCurrentOrganizationDetails: state.getCurrentOrganizationDetails
  };
}

type AbldProps = {
  dispatch: AppDispatch;
  assignmentBuilderBreadcrumbSubject: BehaviorSubject<object>;
  assignmentBuilderCustomPropsSubject: BehaviorSubject<object>;
};

interface Props extends ReduxProps, AbldProps {}

function AssignmentBuilder({
  dispatch,
  getDaDetails,
  assignmentBuilderCustomPropsSubject,
  getAssignmentBuilderOrg,
  getSchoolGuids,
  getBreadCrumbsData,
  getUserRoleId,
  getCurrentOrganizationDetails
}: Props) {
  const navigate = useNavigate();

  useEffect(() => {
    //the Assignment Builder MFE uses a module that adds custom methods to the Array prototype.
    //To prevent these custom methods from polluting our IN loops, we remove them here.
    return () => {
      removeAllCustomArrayMethods();
    };
  }, []);

  useEffect(() => {
    dispatch(updatePageTitle(`- Assignment Builder`));
    let newCrumbs = [];
    if (getDaDetails.type != 2) {
      dispatch(updateActivityIndicator(true));
      let orgID =
        getSchoolGuids[0].id === null || getSchoolGuids[0].id === undefined
          ? getSchoolGuids[0].organizationID
          : getSchoolGuids[0].id;
      getLicensedSubjects(orgID);
      if (getUserRoleId === 3) {
        newCrumbs = getBreadCrumbsData.crumbs;
        assignmentBuilderCustomPropsSubject.next({
          schoolId: getAssignmentBuilderOrg.schoolId,
          schoolGuid: getAssignmentBuilderOrg.schoolGuid,
          crumbs: getBreadCrumbsData.crumbs,
          timeZone: getAssignmentBuilderOrg.timezone,
          isMyPathOnly: getAssignmentBuilderOrg.licensedProducts.includes("IPA") || getAssignmentBuilderOrg.schoolYear > 2025
        });
      } else {
        newCrumbs.push({
          path: `/assignmentbuilder/dashboard`,
          name: "Assignment Builder"
        });
        assignmentBuilderCustomPropsSubject.next({
          schoolId:
            getSchoolGuids[0].id === null || getSchoolGuids[0].id === undefined
              ? getSchoolGuids[0].organizationID
              : getSchoolGuids[0].id,
          schoolGuid:
            getSchoolGuids[0].orgGUID === null || getSchoolGuids[0].orgGUID === undefined
              ? getSchoolGuids[0].organizationGuid
              : getSchoolGuids[0].orgGUID,
          timeZone: getCurrentOrganizationDetails.timezone,
          isMyPathOnly: getCurrentOrganizationDetails.licensedProducts.includes("IPA") || getCurrentOrganizationDetails.academicSession.schoolYear > 2025
        });
      }
    } else {
      if (getUserRoleId === 3 && getDaDetails.type === 2) {
        newCrumbs = getBreadCrumbsData.crumbs;
      } else {
        newCrumbs.push(
          {
            path: `/assignmentbuilder/schools/${getDaDetails.id}`,
            name: "Assignment Builder"
          },
          {
            path: "/assignmentbuilder/dashboard",
            name: getAssignmentBuilderOrg.schoolName
          }
        );
      }
      assignmentBuilderCustomPropsSubject.next({
        schoolId: getAssignmentBuilderOrg.schoolId,
        schoolGuid: getAssignmentBuilderOrg.schoolGuid,
        crumbs: getBreadCrumbsData.crumbs,
        timeZone: getAssignmentBuilderOrg.timezone,
        isMyPathOnly: getAssignmentBuilderOrg.licensedProducts.includes("IPA") || getAssignmentBuilderOrg.schoolYear > 2025
      });
    }
    dispatch(updateBreadCrumbs({ crumbs: newCrumbs }));
    dispatch(updateActivityIndicator(false));
  }, []);

  async function getLicensedSubjects(schoolId: number) {
    try {
      await LMSService.getSubjects(schoolId).then(({ status }: { status: number; data: Response }) => {
        if (status === 200) {
          // do nothing
        } else {
          navigate(`/noLicenseProducts`, { replace: false });
        }
      });
    } catch (error) {
      //do nothing
    }
    dispatch(updateActivityIndicator(false));
  }

  return <div id="assignment-builder"></div>;
}
export default connect(mapStateToProps)(AssignmentBuilder);
