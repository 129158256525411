/* eslint-disable react-hooks/exhaustive-deps */
import Snackbar from "@material-ui/core/Snackbar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import {
  TimesIcon,
  UXCSVExport,
  UxFilterSlider,
  UxIcon,
  UxIconEnum,
  UxMUITable,
  UxPaginate,
  UxPaginationController,
  UxSearchInput,
  UxSizeEnum,
  UxSliderAction
} from "@wne-spa/ux-component-library";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import configMessages from "../../helpers/configMessages";
import { paginationControllers } from "../../helpers/configs";
import {
  updateActivityIndicator,
  updateBreadCrumbs,
  updateClassesListSort,
  updateClassFilter,
  updateClassViewTab,
  updatePaginationControllerState,
  updateSchoolID,
  updateSubjectName,
  updateTabName,
  updateCurrentOrganizationDetails
} from "../../Redux/Action";
import ReduxProps from "../../Redux/Redux.props";
import { AppDispatch } from "../../Redux/Store";
import { AdminService } from "../../services/helpers/api.services";
import { LMSService } from "../../services/helpers/lms.services";
import { UserService } from "../../services/helpers/user.services";
import "./vCreateClass.scss";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getUserRoleId: state.getUserRoleId,
    getDaDetails: state.getDaDetails,
    getClassFilter: state.getClassFilter,
    getClassListSort: state.getClassListSort,
    getUserId: state.getUserId,
    getTabChange: state.getTabChange,
    getPaginationControllerState: state.getPaginationControllerState,
    getWneUser: state.getWneUser,
    getStudentAndClassFilterURL: state.getStudentAndClassFilterURL,
    globalSubjectPurpose: state.globalSubjectPurpose,
    getCurrentUserDetails: state.getCurrentUserDetails
  };
}

type ClassesProps = {
  ismulti?: string;
  isfrom?: string;
  dispatch: AppDispatch;
  showcreate?: string;
};

interface Props extends ClassesProps, ReduxProps {}

function Classes(props: Props) {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();

  const navigate = useNavigate();
  const {
    isfrom,
    dispatch,
    getBreadCrumbsData,
    getUserRoleId,
    getDaDetails,
    showcreate,
    getClassFilter,
    getClassListSort,
    getUserId,
    getTabChange,
    getPaginationControllerState,
    getWneUser,
    getStudentAndClassFilterURL,
    globalSubjectPurpose,
    getCurrentUserDetails
  } = props;
  const columnNames = getWneUser
    ? ["Class Name", "External Id", "Teacher", "Subject", "Status"]
    : ["Class Name", "Teacher", "Subject", "Status"];
  const rowMapper = getWneUser
    ? [["classTitle"], "districtAssignedId", "educators", "subjects", "isActive"]
    : [["classTitle"], "educators", "subjects", "isActive"];
  const [emptyTableText, setEmptyTableText] = useState("");
  const [showToast, setShowToast] = useState({ val: false, msg: "", type: "" });
  const [data, setData] = useState({
    page: 0,
    size: 0,
    pageSize: 0,
    values: []
  });
  const [pageNo, setPageNo] = useState(1);
  const sortBy = useRef(getClassListSort.name);
  const sortOrder = useRef(getClassListSort.val);
  const [classNameBool, setClassNameBool] = useState<boolean | null>(null);
  const [educatorBool, setEducatorBool] = useState<boolean | null>(null);
  const [subjectBool, setSubjectBool] = useState<boolean | null>(null);
  const [searchText, setSearchText] = useState("");
  const searchResultRef = useRef(false);
  const elementRef = useRef(null);
  const [tableHgt, setTableHgt] = useState(0);
  const [opacity, setOpacity] = useState(0);
  const [contentVisibility, setContentVisibility] = useState(false);
  const activeFilters = React.useRef<Array<string>>([]);
  const [filterCountClick, setFilterCountClick] = useState(false);
  const [sliderLeft, setSliderLeft] = useState("-330px");
  const [stateName, setStateName] = useState({});
  const textMessage = React.useRef("none");
  const typesHeader = ["Subject"];
  const [csvData, setCSVData] = useState([]);
  const [types, setTypes] = useState({
    Math: "Subject",
    Reading: "Subject",
    [globalSubjectPurpose]: "Subject",
    Active: "Status",
    Inactive: "Status",
    Science: "Subject"
  });
  const headersForCsv = [
    {
      label: "School Id",
      key: "schoolID"
    },
    {
      label: "Class ID",
      key: "classID"
    },
    {
      label: "Class Name",
      key: "className"
    },
    {
      label: "Class External ID",
      key: "districtAssignedId"
    },
    {
      label: "Teacher Name",
      key: "teacherName"
    },
    {
      label: "Subject",
      key: "subject"
    },
    {
      label: "Status",
      key: "status"
    }
  ];
  const [typesList, setTypesList] = useState({ Subject: [] });
  const filterStates = useRef<{ [key: string]: boolean }>({
    Math: false,
    Reading: false,
    [globalSubjectPurpose]: false,
    Active: false,
    Inactive: false,
    Science: false
  });
  const [CSVFilename, setCSVFilename] = useState("");

  if (activeFilters.current.includes("Active")) {
    const index = activeFilters.current.indexOf("Active");
    if (index !== -1) {
      activeFilters.current.splice(index, 1);
    }
  }
  interface ISubjects {
    educatorId: number;
    sequence: number;
    firstName: string;
    lastName: string;
  }
  interface IClassList {
    classID: number;
    classGUID: string;
    classTitle: string;
    organizationID: number;
    educators: ISubjects[];
    subjects: ISubjects[];
    isActive: boolean;
    isSynced?: boolean;
  }
  interface IPage {
    size: number;
    page: number;
    pageSize: number;
    values: IClassList[];
  }

  async function callback(args: number) {
    let updateState = getPaginationControllerState;
    updateState[3] = { paginationPageName: "Classes", paginationCount: args };
    setPageNo(1);
    dispatch(updatePaginationControllerState(updateState));
    try {
      dispatch(updateActivityIndicator(true));
      UserService.updatePaginationCount(getUserId, 4, args).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            getClasses(1, searchText);
          } else {
            dispatch(updateActivityIndicator(false));
          }
        }
      );
    } catch (err) {
      dispatch(updateActivityIndicator(false));
    }
  }
  useEffect(() => {
    if (getUserRoleId === 5) {
      if (getDaDetails.type === 1) {
        setCSVFilename(getDaDetails.name);
      } else {
        if (window.location.pathname.includes("teacherclasses")) {
          setCSVFilename(getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name);
        } else {
          setCSVFilename(getDaDetails.name);
        }
      }
    } else if (getUserRoleId === 4 && getDaDetails.type === 1) {
      setCSVFilename(getDaDetails.name);
    } else {
      setCSVFilename(getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name);
    }
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight > 650) {
        //@ts-ignore
        setTableHgt(
          //@ts-ignore
          parseInt(window.innerHeight - (elementRef.current.offsetTop + 75))
        );
      } else {
        //@ts-ignore
        setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => {
    localStorage.setItem("page", "classes");
    getFilterSubjects();
  }, [id]);

  useEffect(() => {
    if (window.innerHeight > 650) {
      //@ts-ignore
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef.current.offsetTop + 75))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
    }
  }, [getTabChange]);
  useEffect(() => {
    if (window.innerHeight > 650) {
      //@ts-ignore
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef.current.offsetTop + 75))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
    }
    if (getClassListSort.val === "desc" || getClassListSort.val === "DESC") {
      if (getClassListSort.name === "classname") {
        setClassNameBool(true);
      } else if (getClassListSort.name === "teacher") {
        setEducatorBool(true);
      } else {
        setSubjectBool(true);
      }
    } else if (getClassListSort.val === "asc" || getClassListSort.val === "ASC") {
      if (getClassListSort.name === "classname") {
        setClassNameBool(false);
      } else if (getClassListSort.name === "teacher") {
        setEducatorBool(false);
      } else {
        setSubjectBool(false);
      }
    }

    activeFilters.current =
      getStudentAndClassFilterURL !== window.location.pathname || getStudentAndClassFilterURL === ""
        ? getClassFilter.filter(
            (data: string) =>
              data !== "Math" && data !== "Reading" && data !== globalSubjectPurpose && data !== "Science"
          )
        : getClassFilter;
    dispatch(updateClassFilter(activeFilters.current));
    let classSubs = [];
    let classStatus = [];
    let classTeacher = [];
    for (let x in activeFilters.current) {
      if (activeFilters.current[x] === "Active") {
        classStatus.push(["Active"]);
      } else if (activeFilters.current[x] === "Inactive") {
        classStatus.push(["Inactive"]);
      } else if (activeFilters.current[x] === "Math") {
        filterStates.current = { ...filterStates.current, Math: true };
        classSubs.push(activeFilters.current[x]);
      } else if (activeFilters.current[x] === "Reading") {
        filterStates.current = { ...filterStates.current, Reading: true };
        classSubs.push(activeFilters.current[x]);
      } else if (activeFilters.current[x] === globalSubjectPurpose) {
        filterStates.current = {
          ...filterStates.current,
          [globalSubjectPurpose]: true
        };
        classSubs.push(activeFilters.current[x]);
      } else if (activeFilters.current[x] === "Science") {
        filterStates.current = { ...filterStates.current, Science: true };
        classSubs.push(activeFilters.current[x]);
      }
    }

    setStateName({
      ...stateName,
      Subject: classSubs,
      Status: classStatus,
      Teacher: classTeacher
    });
    const getClassDetails = async () => {
      try {
        let filterSub = [];
        let filterTeach = [];
        let filterStatus = [];
        for (let i in activeFilters.current) {
          if (activeFilters.current[i] === "Math") {
            filterSub.push(1);
          } else if (activeFilters.current[i] === "Reading") {
            filterSub.push(2);
          } else if (activeFilters.current[i] === globalSubjectPurpose) {
            filterSub.push(5);
          } else if (activeFilters.current[i] === "Science") {
            filterSub.push(6);
          }
        }

        if (getUserRoleId === 5 && getDaDetails.type === 2 && showcreate !== "school") {
          let jsonObj1 = {
            Page: 1,
            PageSize: getPaginationControllerState[3].paginationCount,
            FilterStatus: "true",
            FilterSubject: filterSub.join()
          };
          await LMSService.getClassCSVDataforTeacher(jsonObj1).then(
            ({ status, data }: { status: number; data: Response }) => {
              gotCSVData(data);
            }
          );
          LMSService.getClassListForTeacherWithGuid(jsonObj1).then(
            ({ status, data }: { status: number; data: Response }) => {
              if (status === 200) {
                gotClasses(data);
              } else {
                setEmptyTableText(configMessages.noClassesNoEdit);
                setData({
                  page: 0,
                  size: 0,
                  pageSize: 0,
                  values: []
                });
              }
            }
          );
        } else {
          let jsonObj = {
            Page: 1,
            PageSize: getPaginationControllerState[3].paginationCount,
            SortName: sortBy.current,
            SortOrder: sortOrder.current,
            SearchText: "",
            FilterSubject: filterSub.join(),
            FilterStatus: "true",
            organizationGuid: id
          };
          await LMSService.getClassCSVData(jsonObj).then(({ status, data }: { status: number; data: Response }) => {
            gotCSVData(data);
          });
          LMSService.getClassListWithGuid(jsonObj).then(({ status, data }: { status: number; data: Response }) => {
            if (status === 200) {
              gotClasses(data);
            } else {
              setEmptyTableText(configMessages.noClassesNoEdit);
              setData({
                page: 0,
                size: 0,
                pageSize: 0,
                values: []
              });
            }
          });
        }
      } catch (err) {
        dispatch(updateActivityIndicator(false));
        setEmptyTableText(configMessages.noClassesNoEdit);
        setData({
          page: 0,
          size: 0,
          pageSize: 0,
          values: []
        });
        //do nothing
      }
    };
    dispatch(updateActivityIndicator(true));
    getClassDetails();
    // eslint-disable-next-line
  }, [showcreate]);

  function sortChange(type: string) {
    if (type === "Class Name") {
      if (!classNameBool) {
        sortOrder.current = "desc";
      } else {
        sortOrder.current = "asc";
      }
      sortBy.current = "classname";
    } else if (type === "Teacher") {
      if (!educatorBool) {
        sortOrder.current = "desc";
      } else {
        sortOrder.current = "asc";
      }
      sortBy.current = "teacher";
    } else {
      if (!subjectBool) {
        sortOrder.current = "desc";
      } else {
        sortOrder.current = "asc";
      }
      sortBy.current = "subject";
    }
    getClasses(pageNo, searchText);
    setPageNo(1);
  }

  function getOrgIds() {
    try {
      let placementOrgIds = [];
      if (id.includes("_")) {
        let org = id.split("_");
        placementOrgIds = org;
      } else {
        placementOrgIds.push(id);
      }
      return placementOrgIds;
    } catch {}
  }

  async function getFilterSubjects() {
    try {
      let placementOrgIds = [];
      //@ts-ignore
      placementOrgIds = getOrgIds();
      typesList["Subject"].splice(0, typesList["Subject"].length);
      let PlacementOrgIds = { subjectGuidObj: placementOrgIds };
      await AdminService.getOrgLicensedSubjectsWithGuid(PlacementOrgIds).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            setFilterSubjects(data);
          } else {
            //do nothing
          }
        }
      );
    } catch (err) {
      alert(err);
    }
  }
  async function setFilterSubjects(responsedata: Response) {
    try {
      let filterSubjects = await responsedata.json();

      //@ts-ignore
      filterSubjects.map((row: string) => {
        //@ts-ignore
        setTypes(Object.assign(types, { row: "Subject" }));
        //@ts-ignore
        setTypesList(
          Object.assign(
            typesList,
            //@ts-ignore
            typesList["Subject"].push(row)
          )
        );
      });
    } catch (err) {
      //do nothing
    }
  }
  async function getClasses(pagenumber: number, sText: string) {
    dispatch(updateActivityIndicator(true));
    try {
      if (activeFilters.current.length === 0) {
        dispatch(updateClassFilter([]));
      } else {
        dispatch(updateClassFilter(activeFilters.current));
      }
      dispatch(updateClassesListSort({ name: sortBy.current, val: sortOrder.current }));
      let filterSub = [];
      let classSubs = [];
      let classStatus = [];
      let classTeacher = [];
      let filterStatus = [];
      for (let i in activeFilters.current) {
        if (activeFilters.current[i] === "Active") {
          classStatus.push("Active");
        } else if (activeFilters.current[i] === "Inactive") {
          classStatus.push("Inactive");
        } else if (activeFilters.current[i] === "Math") {
          filterSub.push(1);
          classSubs.push("Math");
        } else if (activeFilters.current[i] === "Reading") {
          filterSub.push(2);
          classSubs.push("Reading");
        } else if (activeFilters.current[i] === globalSubjectPurpose) {
          filterSub.push(5);
          classSubs.push(globalSubjectPurpose);
        } else if (activeFilters.current[i] === "Science") {
          filterSub.push(6);
          classSubs.push("Science");
        }
      }
      setStateName({
        ...stateName,
        Subject: classSubs,
        Status: classStatus,
        Teacher: classTeacher
      });
      const jsonObj = {
        Page: pagenumber,
        PageSize: getPaginationControllerState[3].paginationCount,
        SortName: sortBy.current,
        SortOrder: sortOrder.current,
        SearchText: sText,
        FilterSubject: filterSub.join(),
        FilterStatus: "true",
        NoTeacher: "false",
        OrganizationGuid: id.split("_")[0]
      };
      await LMSService.getClassCSVData(jsonObj).then(({ status, data }: { status: number; data: Response }) => {
        gotCSVData(data);
      });
      if (getUserRoleId === 5 && getDaDetails.type === 2 && showcreate !== "school") {
        LMSService.getClassListForTeacherWithGuid(jsonObj).then(
          ({ status, data }: { status: number; data: Response }) => {
            if (status === 200) {
              gotClasses(data);
            } else {
              setEmptyTableText(configMessages.noClassesNoEdit);
              setData({
                page: 0,
                size: 0,
                pageSize: 0,
                values: []
              });
            }
          }
        );
      } else {
        let jsonObjs = {
          Page: pagenumber,
          PageSize: getPaginationControllerState[3].paginationCount,
          SortName: sortBy.current,
          SortOrder: sortOrder.current,
          SearchText: sText,
          FilterSubject: filterSub.join(),
          FilterStatus: "true",
          organizationGuid: id
        };
        LMSService.getClassListWithGuid(jsonObjs).then(({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            gotClasses(data);
          } else {
            setEmptyTableText(configMessages.noClassesNoEdit);
            setData({
              page: 0,
              size: 0,
              pageSize: 0,
              values: []
            });
          }
        });
      }
    } catch (err) {
      dispatch(updateActivityIndicator(false));
      setEmptyTableText("No Records found");
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });
      //do nothing
    }
  }
  async function gotCSVData(responsedata: Response) {
    const csvData = JSON.parse(await responsedata.text());
    setCSVData(csvData);
  }
  async function gotClasses(responsedata: Response) {
    let responseClasses = await responsedata.json();
    if (sortBy.current === "classname") {
      setEducatorBool(null);
      setSubjectBool(null);
    } else if (sortBy.current === "teacher") {
      setSubjectBool(null);
      setClassNameBool(null);
    } else {
      setClassNameBool(null);
      setEducatorBool(null);
    }
    if (responseClasses.size === 0) {
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });
      if ((activeFilters.current.includes("Active") || activeFilters.current.length > 0) && !searchResultRef.current) {
        setEmptyTableText(configMessages.noClasses);
      } else if (activeFilters.current.length > 0 && searchResultRef.current) {
        setEmptyTableText("No results matching for your applied filters and search parameter");
      } else if (activeFilters.current.length > 0) {
        setEmptyTableText("No results matching your applied filters");
      } else if (searchResultRef.current) {
        setEmptyTableText("No results containing your search were found");
      } else if (activeFilters.current.length === 0) {
        setEmptyTableText(configMessages.noClassesNoEdit);
      } else {
        //do nothing
      }
      localStorage.getItem("page") === "classes" && dispatch(updateActivityIndicator(false));
    } else {
      let classList = {
        page: responseClasses.page,
        size: responseClasses.size,
        pageSize: getPaginationControllerState[3].paginationCount,
        values: []
      };
      let cvalues = [];
      let allClassid = [];
      for (let x in responseClasses.values) {
        let subjects = "";
        for (let y in responseClasses.values[x].subjects) {
          if (y === "0") {
            subjects = responseClasses.values[x].subjects[y].subject;
          } else {
            subjects = subjects + ", " + responseClasses.values[x].subjects[y].subject;
          }
        }
        let educators = "";
        //@ts-ignore
        let elist = responseClasses.values[x].educators;
        //@ts-ignore
        elist.sort(function (a, b) {
          return a.sequence > b.sequence ? 1 : -1;
        });
        for (let z in elist) {
          if (z === "0") {
            educators = `${elist[z].lastName}, ${elist[z].firstName}`;
          } else {
            educators = `${educators}; ${elist[z].lastName}, ${elist[z].firstName}`;
          }
        }
        const classvalue = {
          classID: responseClasses.values[x].classID,
          classGUID: responseClasses.values[x].classGUID,
          classTitle: responseClasses.values[x].classTitle,
          organizationID: responseClasses.values[x].organizationID,
          isActive: responseClasses.values[x].isActive,
          educators: educators,
          subjects: subjects,
          districtAssignedId: responseClasses.values[x].districtAssignedId,
          isSynced: responseClasses?.values[x]?.isSynced
        };
        cvalues.push(classvalue);
        allClassid.push(responseClasses.values[x].classGUID);
      }
      //@ts-ignore
      classList.values = cvalues;
      setData(classList);
      localStorage.getItem("page") === "classes" && dispatch(updateActivityIndicator(false));
    }
  }

  function viewClass(row: { classGUID: string; classTitle: string; organizationID: number; subjects: string }) {
    let breadcrumb = getBreadCrumbsData.crumbs;
    breadcrumb.push({
      path: `/classview/${row.classGUID}`,
      name: row.classTitle
    });
    const currentOrg = getCurrentUserDetails?.organizations?.find(org => org.orgId === row.organizationID);
    if (currentOrg && getDaDetails.type === 2) {
      dispatch(updateCurrentOrganizationDetails(currentOrg));
    }
    dispatch(updateSchoolID(row.organizationID));
    dispatch(updateClassViewTab("classsummary"));
    dispatch(updateTabName("classsummary"));
    dispatch(updateBreadCrumbs({ crumbs: breadcrumb }));
    let schools = row.subjects.split(",");
    dispatch(updateSubjectName(schools[0]));
    navigate(`/classview/${row.classGUID}`);
  }

  function Alert(alertprops: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...alertprops} />;
  }

  const alertStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242"
      }
    }
  }));
  const alertclasses = alertStyles();

  const Styles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        "& > *": {
          marginTop: theme.spacing(2)
        }
      }
    })
  );
  let classes = Styles();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNo(value);
    getClasses(value, searchText);
  };

  function handleSearchTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.charAt(0) !== " ") {
      setSearchText(event.target.value);
    }
  }

  function handleKeyPress(event: React.KeyboardEvent<Element>): void {
    if (event.key === "Enter" && searchText !== "") {
      searchResultRef.current = true;
      textMessage.current = "Search";
      setPageNo(1);
      getClasses(1, searchText);
    }
  }

  function renderSearchButton() {
    return <UxIcon icon={UxIconEnum.SEARCH} size={UxSizeEnum.MEDIUM} className="classsearchIcon" />;
  }

  function handleSearchButtonClick() {
    if (searchText !== "") {
      searchResultRef.current = true;
      textMessage.current = "Search";
      setPageNo(1);
      getClasses(1, searchText);
    }
  }

  function renderCancelButton() {
    if (searchText !== "" && !searchResultRef.current) {
      return <img src={TimesIcon} alt="" width="20px" height="20px" />;
    } else if (searchText !== "" && searchResultRef.current) {
      return (
        <div className="classsearchIconText">
          <span> Clear Results </span>
        </div>
      );
    }
  }

  function clearButtonClick() {
    if (searchText !== "" && !searchResultRef.current) {
      setSearchText("");
    } else if (searchText !== "" && searchResultRef.current) {
      setSearchText("");
      searchResultRef.current = false;
      setSearchText("");
      setPageNo(1);
      getClasses(1, "");
    }
  }

  function restoreData() {
    activeFilters.current = [];
    filterStates.current = {
      Math: false,
      Reading: false,
      [globalSubjectPurpose]: false,
      Active: false,
      Inactive: false,
      Science: false
    };
    setFilterCountClick(false);
    setStateName({});
    setPageNo(1);
    getClasses(1, searchText);
  }
  return (
    <div>
      <UxFilterSlider
        opacity={opacity}
        backgroundVisibility={contentVisibility}
        left={sliderLeft}
        sliderHeader="Filters"
        activeFilters={activeFilters.current}
        setActiveFilters={activeFilters}
        resetText="Reset Filters"
        onResetClick={() => {
          restoreData();
        }}
        activeFilterText="Active Filters"
        types={types}
        typesHeader={typesHeader}
        typesList={typesList}
        filterStates={filterStates.current}
        setFilterState={filterStates}
        setSliderLeft={setSliderLeft}
        setBackgroundVisibility={setContentVisibility}
        setOpacity={setOpacity}
        onChecked={() => {
          setPageNo(1);
          getClasses(1, searchText);
        }}
        textMessage={textMessage}
        setMultiFilterDropDown={setFilterCountClick}
        screen="classes"
        stateName={stateName}
        setStateName={setStateName}
        stateCallback={() => {}}
      />
      <div className={alertclasses.root}>
        <Snackbar
          open={showToast.val}
          autoHideDuration={4000}
          onClose={() => {
            setShowToast({ val: false, msg: "", type: "" });
          }}
        >
          {showToast.type === "success" ? (
            <Alert
              severity="success"
              style={{ backgroundColor: "#36BBA4", color: "#fff" }}
              onClose={() => {
                setShowToast({ val: false, msg: "", type: "" });
              }}
            >
              {showToast.msg}
            </Alert>
          ) : (
            <Alert
              severity="error"
              style={{ backgroundColor: "#f4473c", color: "#fff" }}
              onClose={() => {
                setShowToast({ val: false, msg: "", type: "" });
              }}
            >
              {showToast.msg}
            </Alert>
          )}
        </Snackbar>
      </div>

      <div
        className={
          getUserRoleId === 5 || (getUserRoleId === 4 && getDaDetails.type === 1)
            ? "tclassActionHolder"
            : "classActionHolder"
        }
      >
        <div
          style={{
            marginTop: "25px",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row"
          }}
        >
          <UxSliderAction
            filterText="Filters"
            activeFilters={activeFilters.current}
            minCount={1}
            multiFilterDropDown={filterCountClick}
            filterStates={filterStates.current}
            setFilterState={filterStates}
            setActiveFilters={activeFilters}
            setMultiFilterDropDown={setFilterCountClick}
            setSliderLeft={setSliderLeft}
            left={sliderLeft}
            setBackgroundVisibility={setContentVisibility}
            setOpacity={setOpacity}
            types={types}
            onChecked={() => {
              setPageNo(1);
              getClasses(1, searchText);
            }}
            textMessage={textMessage}
            stateName={stateName}
            setStateName={setStateName}
            stateCallback={() => {}}
          />
        </div>
        <div
          className="ClassHolder"
          style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "end" }}
        >
          <UxSearchInput
            flexClassName="classFlexHolder"
            inputClassName="classsInputText"
            placeHolder="Search Class Name, Teacher, Subject"
            value={searchText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchTextChange(e)}
            onKeyPress={(e: React.KeyboardEvent) => {
              handleKeyPress(e);
            }}
            searchButtonClassName="classSearchButton"
            searchButtonContent={renderSearchButton()}
            onSearchClick={() => {
              handleSearchButtonClick();
            }}
            clearButtonClassName="classclearButton"
            clearButtonContent={renderCancelButton()}
            onClearClick={() => {
              clearButtonClick();
            }}
            restoreCheckBoxState={() => {}}
          />
          <div style={{ marginLeft: "20px", marginRight: "30px" }}>
            <UXCSVExport
              data={csvData}
              headers={headersForCsv}
              filename={
                "Classes_" +
                CSVFilename.replace(/[.]/g, " ") +
                "_" +
                (new Date(`${new Date()}`).getUTCMonth() + 1) +
                "_" +
                new Date(`${new Date()}`).getUTCDate() +
                "_" +
                new Date(`${new Date()}`).getUTCFullYear() +
                "_" +
                Date.now()
              }
            />
          </div>
        </div>
      </div>

      <div className="nobulkOptions"></div>
      <div ref={elementRef}>
        <UxMUITable
          MUITableClassName={isfrom === "teacher" || isfrom === "sa" ? "tcontentForClasses" : "contentForClasses"}
          columnNames={columnNames}
          rowMapper={rowMapper}
          rowData={(data as IPage).values}
          showColumnCheckbox={false}
          showKebabMenu={false}
          KebabMenuOption={["Deactivate", "Activate"]}
          showNameKebabMenu={false}
          viewChild={viewClass}
          emptyTableText={emptyTableText}
          checkboxRef="classGUID"
          selected={[]}
          setSelected={() => {}}
          mtableHeight={tableHgt}
          hideCheckboxOverValue="isSynced"
          disableKebabMenuOverValue="isSynced"
          showSortList={getWneUser ? [true, false, true, true, false] : [true, true, true, false]}
          sortListMapper={{
            "Class Name": [
              classNameBool,
              setClassNameBool,
              (args: string) => {
                sortChange(args);
              }
            ],
            Teacher: [
              educatorBool,
              setEducatorBool,
              (args: string) => {
                sortChange(args);
              }
            ],
            Subject: [
              subjectBool,
              setSubjectBool,
              (args: string) => {
                sortChange(args);
              }
            ]
          }}
        />
      </div>
      {data.values.length > 0 ? (
        <div className="classesfixedpagination">
          <UxPaginate
            data={data}
            page={pageNo}
            paginationSize={getPaginationControllerState[3].paginationCount}
            className={classes.root}
            handleChange={handleChange}
          />
          {data.size > 50 && (
            <UxPaginationController
              rowsList={paginationControllers}
              setRowsCount={getPaginationControllerState[3].paginationCount}
              callback={callback}
            />
          )}
        </div>
      ) : null}
    </div>
  );
}

export default connect(mapStateToProps)(Classes);
