/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from "react";
import {
  UxPaginate,
  UxPaginationController,
  UxSliderAction,
  UxFilterSlider,
  UxSearchInput,
  UxIcon,
  UxIconEnum,
  UxSizeEnum,
  UxAction,
  UxShadesEnum,
  TimesIcon,
  UxBanner,
  WarningRosterIcon
} from "@wne-spa/ux-component-library";
import {
  LabeledSelect,
  MpngExportReport,
  PathwayTypeBadge,
  PathwayTypeEnum,
  HoverToolTip,
  EditPlacementModal,
  DeactivateModal,
  HoverToast
} from "@wne/mpng-components";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  updateActivityIndicator,
  updateStudentPlacementListSort,
  updateStudentPlacementFilter,
  updatePaginationControllerState,
  updateStudentIds,
  updateCurrentSchoolGuid,
  updateSchoolGUID
} from "../../Redux/Action";
import ManipStudentPlacement from "./manipulateStudentPlacement";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import config from "../../helpers/configMessages";
import Snackbar from "@material-ui/core/Snackbar";
import ManipILPModal from "./manipILPModal";
import { paginationControllers, defaultGradeLevels, defaultGradeLevelIds } from "../../helpers/configs";
import { UserService } from "../../services/helpers/user.services";
import { AdminService } from "../../services/helpers/api.services";
import BulkImportsModal from "../modals/bulk-imports";
import HelpIcon from "../../images/help_search_icon.png";
import ReduxProps from "../../Redux/Redux.props";
import {
  StudentPlacementProps,
  StudentPlacementSearchRefProps,
  IStudentPlacementDetails,
  SubjectGradesProps,
  ApiPathwayTypeEnum
} from "./StudentPlacement.props";
import { StudentPlacementByOrgWithGuidProps, CreateManualPlacementProps } from "../../services/helpers/user.service.props";
import { ColumnDetailsProps, CustomKebabMenuDataProps } from "./StudentPlacementTable/student-placement-table.props";
import StudentPlacementTable from "./StudentPlacementTable/StudentPlacementTable";
import "./StudentPlacement.scss";

interface Props extends ReduxProps, StudentPlacementProps {}

function mapStateToProps(state: ReduxProps) {
  return {
    getActivityIndicator: state.getActivityIndicator,
    getRefreshSP: state.getRefreshSP,
    getUserId: state.getUserId,
    getTabChange: state.getTabChange,
    getStudentPlacementListSort: state.getStudentPlacementListSort,
    getStudentPlacementFilter: state.getStudentPlacementFilter,
    getPaginationControllerState: state.getPaginationControllerState,
    getFlagrKeys: state.getFlagrKeys,
    studentIds: state.studentIds,
    getStudentAndClassFilterURL: state.getStudentAndClassFilterURL,
    globalSubjectPurpose: state.globalSubjectPurpose,
    getBreadCrumbsData: state.getBreadCrumbsData,
    getCurrentUserDetails: state.getCurrentUserDetails,
    getCurrentOrganizationDetails: state.getCurrentOrganizationDetails,
    getIsMyPathOnly: state.getIsMyPathOnly
  };
}

function StudentPlacement(props: Props): JSX.Element {
  const {
    dispatch,
    orgLevel,
    getRefreshSP,
    getUserId,
    getTabChange,
    getStudentPlacementListSort,
    getStudentPlacementFilter,
    getFlagrKeys,
    getPaginationControllerState,
    studentIds,
    globalSubjectPurpose,
    getBreadCrumbsData,
    getCurrentUserDetails,
    getCurrentOrganizationDetails
  } = props;
  const { id } = useParams<{ id: string | undefined }>();
  const [mathGrade, setMathGrade] = useState("");
  const [readingGrade, setReadingGrade] = useState("");
  const [showAddPlacementModal, setShowAddPlacementModal] = useState(false);
  const [showToggle, setShowToggle] = useState(false);
  const [icon, setIcon] = useState(false);
  const [showSubToggle, setShowSubToggle] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [toastState, setToastState] = useState({ activateToast: false, status: "" });
  const [hasIMPlusLicense, setHasIMPlusLicense] = useState(false);
  const pathwayTypeOptions = [
    {
      id: "All",
      name: "All" 
    },
    {
      id: ApiPathwayTypeEnum.PRACTICE,
      name: PathwayTypeEnum.PRACTICE
    },
    {
      id: ApiPathwayTypeEnum.INSTRUCTION,
      name: PathwayTypeEnum.INSTRUCTION
    }
  ];

  const practiceTypeInfo = `The personalized practice grade level pathway contains practice lessons aligned to the student’s rostered grade.`;
  const instructionTypeInfo = `The prioritized instruction pathway offers video-based direct instruction and scaffolded supports that are appropriate for a student who needs reteaching on skills in previous grades and students learning an above-grade level skill for the first time.`;
  const instructionTooltipInfo = (<div className="pathway-tooltip">{instructionTypeInfo}</div>);
  const practiceTooltipInfo = (<div className="pathway-tooltip">{practiceTypeInfo}</div>)
  const pathwayTypeTooltipInfo = 
    (<div className="pathway-tooltip">
        <strong>{`${PathwayTypeEnum.PRACTICE}- `}</strong>{practiceTypeInfo}
        <br />
        <br />
        <strong>{`${PathwayTypeEnum.INSTRUCTION}- `}</strong>{instructionTypeInfo}
    </div>);
  const sourceMyPath = "MyPath";
  const sourceImaginePlusDiagnostic = "Imagine+ Diagnostic";
  const [pathwayType, setPathwayType] = useState("All");
  const [showPathwayHeaderBadge, setShowPathwayHeaderBadge] = useState(false);
  const [showPathwayTypeDropdown, setShowPathwayTypeDropdown] = useState(false);
  
  const classColumnDetails: ColumnDetailsProps[] =[
    {
      columnName: "Student Name",
      alignment: "left",
      dataName: ["lastName", "firstName"],
      isSorted: true,
      displayColumnData: (data: IStudentPlacementDetails) => {
        return `${data.lastName}, ${data.firstName}`;
      }
    },
    {
      columnName: "Username",
      alignment: "left",
      dataName: ["userName"],
      isSorted: true
    },
    {
      columnName: "Grade",
      toolTipContent: "",
      alignment: "right",
      dataName: ["grade"],
      isSorted: true
    },
    {
      columnName: "Subject",
      alignment: "left",
      dataName: ["subject"],
      isSorted: true
    },
    {
      columnName: "Overall Placement",
      alignment: "right",
      dataName: ["placementGrade"],
      isSorted: true
    },
    {
      columnName: "Source",
      alignment: "right",
      dataName: ["source"],
      isSorted: true
    },
    {
      columnName: "Placement Date",
      alignment: "right",
      dataName: ["placementDate"],
      isSorted: true
    },
    {
      columnName: "",
      alignment: "left",
      dataName: ["isEnabled"], //this column is used as a placeholder for the kebab menu
      isSorted: false
    }
  ];

  const schoolColumnDetails: ColumnDetailsProps[] = [...classColumnDetails.slice(0, 7), 
    {
      columnName: "NWEA ID",
      alignment: "left",
      dataName: ["nweaId"],
      isSorted: true
    },
    {
      columnName: "Renaissance ID",
      alignment: "left",
      dataName: ["renaissanceId"],
      isSorted: true
    },
    classColumnDetails[7]
  ];

  const multiSchoolColumnDetails: ColumnDetailsProps[] = [...schoolColumnDetails.slice(0, 2),
    {
      columnName: "School",
      alignment: "left",
      dataName: ["organizationName"],
      isSorted: true
    },
    ...schoolColumnDetails.slice(2)
  ];

  const getColumnDetails = (includePathType: boolean) => {
    const pathTypeColumn: ColumnDetailsProps = {
      columnName: "Path Type",
      toolTipContent: pathwayTypeTooltipInfo,
      toolTipClass: "pathway-popover",
      alignment: "center",
      dataName: ["pathwayType"],
      isSorted: false,
      displayColumnData: (data: IStudentPlacementDetails) => {
        if (data.pathwayType) {
        return <PathwayTypeBadge pathwayType={data.pathwayType} />;
        } else if (data.subject === "Math") {
          return "-";
        } else {
          return <span className="no-pathway-type">N/A</span>;
        }
      }
    };
    if (orgLevel === "class") {
      return includePathType ? [...classColumnDetails.slice(0, 4), pathTypeColumn, ...classColumnDetails.slice(4)] : classColumnDetails;
    } else if (orgLevel === "school") {
      return includePathType ? [...schoolColumnDetails.slice(0, 4), pathTypeColumn, ...schoolColumnDetails.slice(4)] : schoolColumnDetails;
    } else if (orgLevel === "district" || orgLevel === "multi-school") {
      return includePathType ? [...multiSchoolColumnDetails.slice(0, 5), pathTypeColumn, ...multiSchoolColumnDetails.slice(5)] : multiSchoolColumnDetails;
    }
  }

  const [columnDetails, setColumnDetails] = useState<ColumnDetailsProps[]>(getColumnDetails(false));

  const initialSortStates = multiSchoolColumnDetails.reduce((acc, column) => {
    if (column.isSorted) {
      acc[column.columnName] = null;
    }
    return acc;
  }, {} as { [key: string]: boolean | null });
  

  const sortBy = useRef(getStudentPlacementListSort.name);
  const sortOrder = useRef(getStudentPlacementListSort.val);
  const [sortStates, setSortStates] = useState<{ [key: string]: boolean | null }>({...initialSortStates});

  const updateSortStates = (key: string, value: boolean) => {
    const details = columnDetails.filter(x => x.columnName === key);
    if (details.length === 1) {
      sortOrder.current = !value ? "desc" : "asc";
      sortBy.current = details[0].dataName[0];
      const newSortStates = {...initialSortStates, [key]: value };
      setSortStates(newSortStates);
    }
    getStudentPlacement(1);
    setPageNo(1);
  };

  const StudentPlacementSearchRef = useRef<StudentPlacementSearchRefProps>({ value: "" });
  const searchResultRef = useRef(false);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [CSVFilename, setCSVFilename] = useState("");
  const [emptyTableText, setEmptyTableText] = useState<string | JSX.Element>();
  const [pageNo, setPageNo] = useState(1);
  const [data, setData] = useState<IPage>({
    page: 0,
    size: 0,
    pageSize: 0,
    values: []
  });
  const navigate = useNavigate();
  const elementRef = useRef(null);
  const [tableHgt, setTableHgt] = useState(0);
  const [grades, setGrades] = useState<Array<string>>([]);
  const [show, setShow] = useState(false);
  const [showEditPlacementModal, setShowEditPlacementModal] = useState(false);
  const [showAssignPlacementModal, setShowAssignPlacementModal] = useState(false);

  interface IPage {
    size: number;
    page: number;
    pageSize: number;
    values: IStudentPlacementDetails[];
  }
  const [subjectlist, setsubjectlist] = useState([]);
  const [title, setTitle] = useState("");
  const [modalData, setModalData] = useState<IStudentPlacementDetails>({} as IStudentPlacementDetails);
  const [showToast, setShowToast] = useState({
    val: false,
    msg: "",
    color: "",
    type: ""
  });
  const [showManipModal, setShowManipModal] = useState(false);
  const [msg, setMsg] = useState<JSX.Element>();
  const [dropDownSelectedGrade, setDropdownSelectedGrade] = useState("");
  const [placementId, setPlacementId] = useState({});
  const [opacity, setOpacity] = useState(0);
  const textMessage = React.useRef("none");
  const [contentVisibility, setContentVisibility] = useState(false);
  const activeFilters = React.useRef<Array<string>>([]);
  const [filterCountClick, setFilterCountClick] = useState(false);
  const [sliderLeft, setSliderLeft] = useState("-330px");
  const [stateName, setStateName] = useState({});
  const conflictHeaders = ["Grade Level", "Overall Placement"];
  const conflictKeys = {
    "Grade Level": "gl-conf-",
    "Overall Placement": "pl-conf-"
  };

  const placementGrades: string[] = [...defaultGradeLevels];
  const subjectIdMap = {"Math": 1, "Reading": 2, [globalSubjectPurpose]: 5, "Science": 6};
  const subjectFilterValues = ["All Subjects", "Math", "Reading", globalSubjectPurpose, "Science"];
  const placementRangeCodesMap = {"Above Grade Level": 1, "On Grade Level": 2, "1 Grade Below": 3, "2+ Grades Below": 4, "Not Placed": 5, "Deactivated": 6};

  const [typesList, setTypesList] = useState({
    "Grade Level": ["All", ...defaultGradeLevels],
    "Overall Placement": [...defaultGradeLevels],
    Subject: [],
    Source: ["Manual", props.getIsMyPathOnly ? sourceImaginePlusDiagnostic : sourceMyPath, "NWEA", "Renaissance", "System"],
    Placement: [...Object.keys(placementRangeCodesMap)]
  });

  const typesHeader = ["Grade Level", "Overall Placement", "Placement", "Subject", "Source"];
  const getInitialTypesAndState = () => {
    let initialTypes = {
      "gl-conf-All": "Grade Level"
    }
    let initialFilterStates = {
      "gl-conf-All": false,
    };
    for (let x in subjectFilterValues) {
      initialTypes[subjectFilterValues[x]] = "Subject";
      initialFilterStates[subjectFilterValues[x]] = false;
    }
    for (let x in typesList["Source"]) {
      initialTypes[typesList["Source"][x]] = "Source";
      initialFilterStates[typesList["Source"][x]] = false;
    }
    for (let x in typesList["Placement"]) {
      initialTypes[typesList["Placement"][x]] = "Placement";
      initialFilterStates[typesList["Placement"][x]] = false;
    }
    for (let x in defaultGradeLevels) {
      initialTypes[`gl-conf-${defaultGradeLevels[x]}`] = "Grade Level";
      initialTypes[`pl-conf-${defaultGradeLevels[x]}`] = "Overall Placement";
      initialFilterStates[`gl-conf-${defaultGradeLevels[x]}`] = false;
      initialFilterStates[`pl-conf-${defaultGradeLevels[x]}`] = false;
    }
    return [initialTypes, initialFilterStates];
  }
  const [types, setTypes] = useState({...getInitialTypesAndState()[0]}); 
  const filterStates = useRef<object>({...getInitialTypesAndState()[1]});
  const filterStatesToReset = useRef<object>({...getInitialTypesAndState()[1]});

  const defaultColumnsForCsv = [
    {
      label: "Student Id",
      id: "userId"
    },
    {
      label: "Last Name",
      id: "lastName"
    },
    {
      label: "First Name",
      id: "firstName"
    },
    {
      label: "Username",
      id: "userName"
    },
    {
      label: "School",
      id: "organizationName"
    },
    {
      label: "Grade Level",
      id: "grade"
    },
    {
      label: "Subject",
      id: "subject"
    },
    {
      label: "Overall Placement",
      id: "placementGrade"
    },
    {
      label: "Placement Source",
      id: "source"
    },
    {
      label: "Placement Date",
      id: "placementDate"
    },
    {
      label: "Learning Path Status",
      id: "ilpStatus"
    },
    {
      label: "NWEA ID",
      id: "nweaId"
    },
    {
      label: "Renaissance ID",
      id: "renaissanceId"
    }
  ];
  const pathTypeColumnsForCsv = [...defaultColumnsForCsv.slice(0, 7), {label: "Path Type", id: "pathwayType"}, ...defaultColumnsForCsv.slice(7)];
  const [columnsForCsv, setColumnsForCsv] = useState([...defaultColumnsForCsv]);

  const addPathwayColumn = () => {
    setColumnDetails([...getColumnDetails(true)]);
    setColumnsForCsv([...pathTypeColumnsForCsv]);
  };

  const removePathwayColumn = () => {
    setColumnDetails([...getColumnDetails(false)]);
    setColumnsForCsv([...defaultColumnsForCsv]);
  };

  useEffect(() => {
    if (orgLevel === "multi-school" && getCurrentUserDetails) {
      setHasIMPlusLicense(getCurrentUserDetails.organizations.some((org) => org.licensedProducts.includes("ImaginePlusMath")));
    } else if (getCurrentOrganizationDetails) {
      setHasIMPlusLicense(getCurrentOrganizationDetails.licensedProducts.includes("ImaginePlusMath"));
    } else {
      setHasIMPlusLicense(false);
    }
  }, [orgLevel, getCurrentUserDetails, getCurrentOrganizationDetails?.orgGuid]);

  //Handle pathType column, filter, and header badge visibility based on license and filter state
  useEffect(() => {
    const mathProvisioned = typesList.Subject.includes("Math");
    const mathFilterActive = stateName["Subject"]?.includes("Math") || stateName["Subject"]?.includes("All Subjects") || !stateName["Subject"]?.length;
    if (hasIMPlusLicense && mathProvisioned) {
      if (mathFilterActive) {
        setShowPathwayTypeDropdown(true);
        if (pathwayType === "All") {
          //show column for all pathway types
          setShowPathwayHeaderBadge(false);
          addPathwayColumn();
        } else {
          //show header badge instead of column for filtered pathway type
          setShowPathwayHeaderBadge(true);
          removePathwayColumn();  
        }
      } else {
        setShowPathwayHeaderBadge(false);
        setShowPathwayTypeDropdown(false);
        removePathwayColumn();
      }
    } else {
      setShowPathwayHeaderBadge(false);
      setShowPathwayTypeDropdown(false);
      removePathwayColumn();
    }
  }, [hasIMPlusLicense, stateName, typesList.Subject.length, pathwayType, data.values]);

  const handlePathwayTypeChange = (value: string) => {
    setPathwayType(value);
    setPageNo(1);
    getStudentPlacement(1, value);
  }

  const helpSearch = (
    <div className="studentPlacementEmptyContentWrapper">
      <img src={HelpIcon} className="resultIcon" />
      <div className="helpMessage">No Results Found </div>
      <div className="helpcenter">
        Try modifying the search terms or use the{" "}
        <a href="https://help.edgenuitymypath.com/hc/en-us/articles/4412008995479" target="_blank">
          tips
        </a>{" "}
        in this Help Center article.
      </div>
    </div>
  );

  const customKebabMenuData: CustomKebabMenuDataProps[] = [
    {
      key: "edit-placement",
      showMenuItem: (args: IStudentPlacementDetails) => {
        return args.isEnabled && args.studentPlacementId && (args.subject === "Reading" || args.subject === "Math");
      },
      getDisplayValue: (args: IStudentPlacementDetails) => "Edit Placement",
      callback: (args: IStudentPlacementDetails) => {
        setModalData(args);
        setShowEditPlacementModal(true);
      }
    },
    {
      key: "assign-placement",
      showMenuItem: (args: IStudentPlacementDetails) => {
        return !args.studentPlacementId && (args.subject === "Reading" || (args.subject === "Math" && !hasIMPlusLicense));
      },
      getDisplayValue: (args: IStudentPlacementDetails) => "Assign Placement",
      callback: (args: IStudentPlacementDetails) => {
        setModalData(args);
        updatedGradeRangeFunc(args, [...defaultGradeLevels]);
        setShowAssignPlacementModal(true);
      }
    },
    {
      key: "assign-practice-placement",
      showMenuItem: (args: IStudentPlacementDetails) => {
        return (
          hasIMPlusLicense && 
          !data.values.some((item) => item.userGuid === args.userGuid && item.pathwayType === ApiPathwayTypeEnum.PRACTICE) &&
          args.subject === "Math"
        );
      },
      getDisplayValue: (args: IStudentPlacementDetails) => `Assign ${PathwayTypeEnum.PRACTICE}`,
      callback: (args: IStudentPlacementDetails) => {
        setModalData({ ...args, pathwayType: ApiPathwayTypeEnum.PRACTICE });
        updatedGradeRangeFunc({...args, placementGrade: ""}, [...defaultGradeLevels]);
        setShowAssignPlacementModal(true);
      }
    },
    {
      key: "assign-instruction-placement",
      showMenuItem: (args: IStudentPlacementDetails) => {
        return (
          hasIMPlusLicense && 
          !data.values.some((item) => item.userGuid === args.userGuid && item.pathwayType === ApiPathwayTypeEnum.INSTRUCTION) &&
          args.subject === "Math"
        );
      },
      getDisplayValue: (args: IStudentPlacementDetails) => `Assign ${PathwayTypeEnum.INSTRUCTION}`,
      callback: (args: IStudentPlacementDetails) => {
        setModalData({ ...args, pathwayType: ApiPathwayTypeEnum.INSTRUCTION });
        updatedGradeRangeFunc({...args, placementGrade: ""}, [...defaultGradeLevels]);
        setShowAssignPlacementModal(true);
      }
    },
    {
      key: "deactivate",
      showMenuItem: (args: IStudentPlacementDetails) => {
        return !!args.studentPlacementId;
      },
      getDisplayValue: (args: IStudentPlacementDetails) => {
        return args.isEnabled ? "Deactivate Learning Path" : "Restore Learning Path";
      },
      callback: (args: IStudentPlacementDetails) => {
        if (!args.isEnabled) {
          setPlacementId({
            type: "restore",
            id: args.studentPlacementId
          });
          setTitle(`Activate ${args.subject} Learning Path`);
          setMsg(
            <label>
              Are you sure you want to Activate the assigned {args.subject} Learning Path for: {args.firstName} {args.lastName}?{" "}
            </label>
          );
          setShowManipModal(true);
        } else {
          setPlacementId({
            type: "deactivate",
            id: args.studentPlacementId
          });
          setTitle(`Deactivate ${args.subject} Learning Path`);
          setMsg(
            <label>
              Are you sure you want to deactivate the assigned {args.subject} Learning Path for: {args.firstName} {args.lastName}?{" "}
            </label>
          );
          setShowManipModal(true);
        }
      }
    }
  ];

  const [subjectGrades, setSubjectGrades] = useState([
    { subject: "Math", grade: placementGrades },
    { subject: "Reading", grade: placementGrades }
  ]);

  function closeModal() {
    setShowAddPlacementModal(false);
    setShowAssignPlacementModal(false);
    localStorage.setItem("page", "studentsPlacement");
  }

  function closeManipModal() {
    setShowManipModal(false);
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key === "Enter" && StudentPlacementSearchRef.current.value !== "") {
      searchResultRef.current = true;
      textMessage.current = "Search";
      setPageNo(1);
      getStudentPlacement(1);
    }
  }

  function renderSearchButton() {
    return <UxIcon icon={UxIconEnum.SEARCH} size={UxSizeEnum.MEDIUM} className="searchIcon" />;
  }
  const viewStudent = (row: IStudentPlacementDetails) => {
    const studentSchoolGuid = row.organizationGuid ? row.organizationGuid : getCurrentOrganizationDetails?.orgGuid;
    dispatch(updateCurrentSchoolGuid(studentSchoolGuid));
    navigate(`/viewstudent/${studentSchoolGuid}_${row.userGuid}`);
  };
  function handleSearchButtonClick(): void {
    if (StudentPlacementSearchRef.current.value) {
      searchResultRef.current = true;
      textMessage.current = "Search";
      setPageNo(1);
      getStudentPlacement(1);
    }
  }

  function renderCancelButton() {
    setTimeout(() => {
      if (StudentPlacementSearchRef.current.value && !searchResultRef.current) {
        document.getElementById("StudentplacementClosesICon").style.display = "block";
        document.getElementById("StudentplacementClearICon").style.display = "none";
      } else if (StudentPlacementSearchRef.current.value && searchResultRef.current) {
        document.getElementById("StudentplacementClosesICon").style.display = "none";
        document.getElementById("StudentplacementClearICon").style.display = "block";
      } else {
        document.getElementById("StudentPlacementClosesICon").style.display = "none";
        document.getElementById("StudentPlacementClearICon").style.display = "none";
      }
    });
    return (
      <>
        <div id="StudentPlacementClosesICon">
          <img src={TimesIcon} alt="" width="20px" height="20px" />
        </div>
        <div id="StudentPlacementClearICon" className="searchIconText">
          <span> Clear Results </span>
        </div>
      </>
    );
  }

  function clearButtonClick() {
    if (StudentPlacementSearchRef.current.value !== "" && !searchResultRef.current) {
      StudentPlacementSearchRef.current.value = "";
    } else if (StudentPlacementSearchRef.current.value !== "" && searchResultRef.current) {
      StudentPlacementSearchRef.current.value = "";
      searchResultRef.current = false;
      setPageNo(1);
      getStudentPlacement(1);
    }
  }

  function restoreDefaultCheckState() {
    setSelected([]);
  }

  async function saveDeactivation() {
    //@ts-ignore
    if (placementId.type === "deactivate") {
      try {
        setShowManipModal(false);
        dispatch(updateActivityIndicator(true));
        //@ts-ignore
        UserService.deactivateStudentPlacementId(placementId.id).then(
          ({ status }: { status: number; data: Response }) => {
            if (status === 200) {
              setShowToast({
                val: true,
                msg: config.deactivateILP,
                color: "#2C9C98",
                type: "info"
              });
              getStudentPlacement(pageNo);
            } else {
              dispatch(updateActivityIndicator(false));
            }
          }
        );
      } catch (err) {
        dispatch(updateActivityIndicator(false));
      }
    } else {
      try {
        setShowManipModal(false);
        dispatch(updateActivityIndicator(true));
        //@ts-ignore
        UserService.restoreilpStudentPlacementId(placementId.id).then(
          ({ status }: { status: number; data: Response }) => {
            if (status === 200) {
              setShowToast({
                val: true,
                msg: config.restoreILP,
                color: "#2C9C98",
                type: "info"
              });
              getStudentPlacement(pageNo);
            } else {
              dispatch(updateActivityIndicator(false));
            }
          }
        );
      } catch (err) {
        dispatch(updateActivityIndicator(false));
      }
    }
  }

  async function callback(args: number) {
    let updateState = getPaginationControllerState;
    updateState[5] = {
      paginationPageName: "StudentPlacement",
      paginationCount: args
    };
    setPageNo(1);
    dispatch(updatePaginationControllerState(updateState));
    try {
      dispatch(updateActivityIndicator(true));
      UserService.updatePaginationCount(getUserId, 6, args).then(({ status }: { status: number; data: Response }) => {
        if (status === 200) {
          getStudentPlacement(1);
        } else {
          dispatch(updateActivityIndicator(false));
        }
      });
    } catch (err) {
      dispatch(updateActivityIndicator(false));
    }
  }

  function Alert(alertprops: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...alertprops} />;
  }

  const alertStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242"
      }
    }
  }));
  const alertclasses = alertStyles();

  async function onUpdate({ isLocked } : { isLocked: boolean }) {
    dispatch(updateActivityIndicator(true));
    setShowAssignPlacementModal(false);
    try {
      const jsonObj: CreateManualPlacementProps = {
        userGuid: modalData.userGuid,
        subjectId: modalData.subjectId,
        pathwayType: modalData.pathwayType ? Object.values(ApiPathwayTypeEnum).indexOf(modalData.pathwayType) : null,
        placementGrade: defaultGradeLevelIds[defaultGradeLevels.indexOf(dropDownSelectedGrade)],
        isLocked: isLocked,
        isBridged: false
      };

      await UserService.createManualPlacement(jsonObj).then(
        ({ status }: { status: number; data: Response }) => {
          if (status === 200) {
            getStudentPlacement(pageNo);
            setShowToast({
              val: true,
              msg: config.assignPlacement,
              color: "#2C9C98",
              type: "info"
            });
          } else {
            dispatch(updateActivityIndicator(false));
          }
        }
      );
    } catch (err) {}
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => { 
    if (orgLevel === "multi-school" && getCurrentUserDetails?.role === 3) {
      setCSVFilename(getCurrentOrganizationDetails?.name ?? "");
    } else if (orgLevel === "class") {
      setCSVFilename(getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 2].name + "_" + 
                     getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name); 
    } else {
      setCSVFilename(getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name);
    }
  }, [getCurrentOrganizationDetails?.name, getCurrentUserDetails?.role, getBreadCrumbsData]);

  const handleResize = () => {
    if (window.innerHeight > 650) {
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef.current.offsetTop + 90))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
    }
  };

  useEffect(() => {
    handleResize();
  }, [studentIds]);

  useEffect(() => {
    dispatch(updateStudentIds([]));
    setSelected([]);
    if (window.innerHeight > 650) {
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef.current.offsetTop + 90))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
    }
    // eslint-disable-next-line
  }, [getTabChange]);

  useEffect(() => {
    if (window.innerHeight > 650) {
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef.current.offsetTop + 90))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
    }
    localStorage.setItem("page", "studentsPlacement");
    // eslint-disable-next-line
    return () => {
      dispatch(updateStudentIds([]));
    };
  }, []);

  useEffect(() => {
    getFilterSubjects();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let selectedStudents = [...studentIds];
    let unchkedData: Array<string> = [];
    let updatedData: Array<string> = [];
    for (let data in selected) {
      if (!selectedStudents.includes(selected[data])) {
        selectedStudents = [...selectedStudents, selected[data]];
      }
    }
    selectedStudents = selectedStudents.filter((item: string) => item != localStorage.getItem("stu:unchecked"));
    if (localStorage.getItem("stu:unCheckAll") != null && localStorage.getItem("stu:unCheckAll") != "") {
      unchkedData = localStorage.getItem("stu:unCheckAll").split(",");
    }

    for (let studata in selectedStudents) {
      if (!unchkedData.includes(selectedStudents[studata])) {
        updatedData = [...updatedData, selectedStudents[studata]];
      }
    }

    localStorage.setItem("stu:unCheckAll", "");
    localStorage.setItem("stu:unchecked", "");
    dispatch(updateStudentIds([...updatedData]));
    if (studentIds.length === 0) {
      setShow(false);
    } else {
      //do nothing
    }
  }, [selected]);

  useEffect(() => {
    dispatch(updateStudentIds([]));
    setSelected([]);
  }, [window.location.pathname]);

  const StudentPlacementListSortFunc = () => {
    const sortColumn = columnDetails.filter(x => x.dataName.includes(getStudentPlacementListSort.name?.toLowerCase()));
    if (sortColumn.length === 1) {
      setSortStates({...initialSortStates, [sortColumn[0].columnName]: getStudentPlacementListSort.val?.toLowerCase() === "asc" });
    } else {
      setSortStates({...initialSortStates, [columnDetails[0].columnName]: true })
    }
  };
  const StudentPlacementFilterFunc = (Source: string[], Placement: string[], Subject: string[], gLevel: string[], pGrade: string[]) => {
    for (let x in getStudentPlacementFilter) {
      filterStates.current = {
        ...filterStates.current,
        [getStudentPlacementFilter[x]]: true
      };
      if (activeFilters.current[x].includes("gl-conf-")) {
        gLevel.push(activeFilters.current[x]);
      }
      if (activeFilters.current[x].includes("pl-conf-")) {
        pGrade.push(activeFilters.current[x]);
      }
      if (typesList.Source.includes(getStudentPlacementFilter[x])) {
        Source.push(getStudentPlacementFilter[x]);
      }
      if (typesList.Placement.includes(getStudentPlacementFilter[x])) {
        Placement.push(getStudentPlacementFilter[x]);
      }
      if (subjectFilterValues.includes(getStudentPlacementFilter[x])) {
        Subject.push(getStudentPlacementFilter[x]);
      }
    }
  };

  useEffect(() => {
    StudentPlacementListSortFunc();
    activeFilters.current = getStudentPlacementFilter;
    let gLevel: string[] = [];
    let pGrade: string[] = [];
    let Subject: string[] = [];
    let Source: string[] = [];
    let Placement: string[] = [];
    StudentPlacementFilterFunc(Source, Placement, Subject, gLevel, pGrade);

    setStateName((stateName) => ({
      ...stateName,
      Subject: Subject,
      "Grade Level": gLevel,
      "Overall Placement": pGrade,
      Source: Source,
      Placement: Placement
    }));
    dispatch(updateActivityIndicator(true));

    setPageNo(1);
    getStudentPlacement(1);
  }, [getRefreshSP, hasIMPlusLicense]);

  async function getFilterSubjects() {
    try {
      typesList["Subject"].splice(0, typesList["Subject"].length);
      setTypes(Object.assign(types, { "All Subjects": "Subject" }));
      setTypesList(     
        Object.assign(typesList, typesList["Subject"].push(`All Subjects`))
      );
      let orgGuids = { subjectGuidObj: getStudentPlacementOrgGuids(false) };
      await AdminService.getOrgLicensedSubjectsWithGuid(orgGuids).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            setFilterSubjects(data);
          }
        }
      );
    } catch (err) {
      // do nothing
    }
  }
  const filterSubjectGrades = (filterSubjects: string[]) => {
    let subGrades = [];

    subGrades = !filterSubjects.includes("Math")
      ? subjectGrades.filter((data: SubjectGradesProps) => data.subject !== "Math")
      : subjectGrades;

    subGrades = !filterSubjects.includes("Reading")
      ? subGrades.filter((data) => data.subject !== "Reading")
      : subGrades;
    setSubjectGrades(subGrades);
  };
  async function setFilterSubjects(responsedata: Response) {
    try {
      let filterSubjects = JSON.parse(await responsedata.text());
      setsubjectlist(filterSubjects);
      filterSubjectGrades(filterSubjects);
      filterSubjects.map((row: string) => {
        setTypes(Object.assign(types, { row: "Subject" }));
        setTypesList(
          Object.assign(
            typesList,
            typesList["Subject"].push(row)
          )
        );
      });
      let validatedActiveFilters = [];
      let validatedSubjectFilters = [];
      for (let x in activeFilters.current) {
        //Remove filters on subjects not provisioned for this organization
        //Use case: filter subject on student placements on a district, then go to school student placements where that subject is not provisioned
        if (subjectFilterValues.includes(activeFilters.current[x])) {
          if (!filterSubjects.includes(activeFilters.current[x]) && activeFilters.current[x] !== "All Subjects") {
            filterStates.current = {...filterStates.current, [activeFilters.current[x]]: false };
          } else {
            validatedSubjectFilters.push(activeFilters.current[x]);
            validatedActiveFilters.push(activeFilters.current[x]);
          }
        } else {
          validatedActiveFilters.push(activeFilters.current[x]);
        }
      }
      activeFilters.current = validatedActiveFilters;
      setStateName((stateName) => ({...stateName, Subject: validatedSubjectFilters}));
    } catch (err) {
      //do nothing
    }
  }

  const setEmptyTableTextMessage = (allData, selectedPathwayType) => {
    setEmptyTableText(
      "There are no students enrolled in the class yet. Once you have students enrolled, they will show here."
    );
    if (activeFilters.current.length === 0 && selectedPathwayType === "All") {
      setEmptyTableText(
        "There are no students enrolled in the class yet. Once you have students enrolled, they will show here."
      );
    } else {
      setIcon(true);
      setEmptyTableText(helpSearch);
    }
    setData({
      page: 0,
      size: 0,
      pageSize: 0,
      values: []
    });
    if (localStorage.getItem("confirmClicked") === "true") {
      dispatch(updateStudentIds([]));
      localStorage.setItem("confirmClicked", "false");
    } else {
      let pageData: Array<string> = [];
      for (let data in allData.values) {
        if (studentIds.includes(allData.values[data].userSubId)) {
          pageData.push(allData.values[data].userSubId);
        }
      }
      setSelected([...pageData]);
    }
  };

  const updateApiData = (allData: IPage) => {
    for (let x in allData.values) {
      const subjectId = subjectIdMap[allData.values[x].subject];
      let userSubId = allData.values[x].userId.toString() + "_" + subjectId.toString();
      if (allData.values[x].pathwayType) {
        userSubId += "_" + allData.values[x].pathwayType;
      }
      allData.values[x] = {
        ...allData.values[x],
        userSubId: userSubId,
        subjectId: subjectId
      };
      if (allData.values[x].studentPlacementId && !allData.values[x].isEnabled) {
        allData.values[x].placementGrade = "Deactivated";
      }
      if (allData.values[x].placementDate) {
        let date = new Date(allData.values[x].placementDate);
        allData.values[x].placementDate = `${date.getMonth() + 1 < 10 ? 0 : ""}${date.getMonth() + 1}/${
          date.getDate() < 10 ? 0 : ""
        }${date.getDate()}/${date.getFullYear()}`;
      }
      if (allData.values[x].source && allData.values[x].source === sourceMyPath && props.getIsMyPathOnly) {
        allData.values[x].source = sourceImaginePlusDiagnostic;
      }
    }
  };

  async function gotResponse(responsedata: Response, selectedPathwayType: string) {
    setSelected([]);
    let allData = JSON.parse(await responsedata.text());
    dispatch(updateActivityIndicator(false));
    if (allData.size === 0) {
      setEmptyTableTextMessage(allData, selectedPathwayType);
    } else {
      updateApiData(allData);
      if (allData.values[0].organizationGuid) {
        dispatch(updateSchoolGUID(allData.values[0].organizationGuid));
      }
      setData(allData);
      if (localStorage.getItem("confirmClicked") === "true") {
        dispatch(updateStudentIds([]));
        localStorage.setItem("confirmClicked", "false");
      } else {
        let pageData: Array<string> = [];
        for (let data in allData.values) {
          if (studentIds.includes(allData.values[data].userSubId)) {
            pageData.push(allData.values[data].userSubId);
          }
        }
        setSelected([...pageData]);
      }
    }
  }

  const updatedGradeRangeFunc = (args: IStudentPlacementDetails, getMapGrade: Array<string>) => {
    let updatedGradeRange = [];
    if (args.subject === "Math" || args.subject === "Reading") {
      let chronologicalGradeIndex = getMapGrade.indexOf(args.grade);
      let updatedIndex = chronologicalGradeIndex != -1 ? chronologicalGradeIndex + 3 : getMapGrade.length - 1;

      for (let grd in getMapGrade) {
        if (parseInt(grd) <= updatedIndex) {
          updatedGradeRange.push(getMapGrade[grd]);
        }
      }
    } else {
      updatedGradeRange = [...getMapGrade];
    }
    const updatedGrades = updatedGradeRange.filter((grade: string) => {
      return grade !== args.placementGrade;
    });
    setGrades([...updatedGrades]);
  };

  const updateStudentPlacementFilterFunc = () => {
    if (activeFilters.current.length === 0) {
      dispatch(updateStudentPlacementFilter([]));
    } else {
      dispatch(updateStudentPlacementFilter(activeFilters.current));
    }
    dispatch(
      updateStudentPlacementListSort({
        name: sortBy.current,
        val: sortOrder.current
      })
    );
  };

  const getPlacementRequestObjects = (selectedPathwayType: string) => {
    let grades: string[] = [];
    let subjects: string[] = [];
    let placementSources: string[] = [];
    let placementGrades: string[] = [];
    let placementRangeCodes: number[] = [];

    const filterPathwayType = selectedPathwayType === "All" ? "" : selectedPathwayType

    //add selected grades, subjects, placement grades, sources and range codes
    for (let i in activeFilters.current) {
      if (activeFilters.current[i].includes("gl-conf-") && activeFilters.current[i] !== "gl-conf-All") {
        grades.push(activeFilters.current[i].substring(8));
      }
      if (subjectFilterValues.includes(activeFilters.current[i]) && activeFilters.current[i] !== "All Subjects") {
        subjects.push(activeFilters.current[i]);
      }
      if (typesList.Source.includes(activeFilters.current[i])) {
        if (props.getIsMyPathOnly && activeFilters.current[i] === sourceImaginePlusDiagnostic) {
          placementSources.push(sourceMyPath);
        } else {
          placementSources.push(activeFilters.current[i]);
        }
      }
      if (activeFilters.current[i].includes("pl-conf-")) {
        placementGrades.push(activeFilters.current[i].substring(8));
      }
      if (Object.keys(placementRangeCodesMap).includes(activeFilters.current[i])) {
        placementRangeCodes.push(placementRangeCodesMap[activeFilters.current[i]]);
      }
    }
    
    const placementObj = {
      searchText: StudentPlacementSearchRef.current.value,
      grades,
      subjects,
      placementSources,
      placementGrades,
      placementRangeCodes,
      pathwayType: filterPathwayType,
      sortBy: sortBy.current,
      sortOrder: sortOrder.current
    }

    return placementObj;
  }

  type StudentPlacementApiCall = (args: StudentPlacementByOrgWithGuidProps) => Promise<{ status: number; data: Response; } | { status: boolean; data: any; }>;

  //get the appropriate api function and guid based on the user's role and organization level
  const getStudentPlacementApiFunction = (isCSV: boolean): StudentPlacementApiCall => {
    if (orgLevel === "class") {
      return isCSV ? UserService.studentPlacementByClassCSV : UserService.studentPlacementByClass;
    } else if (orgLevel === "school" || (orgLevel === "multi-school" && getCurrentUserDetails?.role === 4)) {
      return isCSV ? UserService.studentPlacementBySchoolCSV : UserService.studentPlacementBySchool;
    } else if (orgLevel === "district" || (orgLevel === "multi-school" && getCurrentUserDetails?.role === 3)) {
      return isCSV ? UserService.studentPlacementByDistrictCSV : UserService.studentPlacementByDistrict;
    }
  }

  //get the appropriate guid for the org, parameter to control getting the school or class guid for a class
  const getStudentPlacementOrgGuids = (includeClass: boolean): string[] => {
    if (orgLevel === "class" && includeClass) {
      return [id];
    } else if (orgLevel === "multi-school") {
      //get the org guids for the user's organizations since there are multiple
      return getCurrentUserDetails?.organizations.map((org) => org.orgGuid);
    } else {
      return [getCurrentOrganizationDetails?.orgGuid];
    }
  }

  async function getStudentPlacement(page: number, selectedPathwayType: string = pathwayType) {
    try {
      dispatch(updateActivityIndicator(true));
      updateStudentPlacementFilterFunc();

      const placementObj = getPlacementRequestObjects(selectedPathwayType);
      const apiCall = getStudentPlacementApiFunction(false);
      const orgGuids = getStudentPlacementOrgGuids(true);

      apiCall({...placementObj, 
               page,
               pageSize: getPaginationControllerState[5].paginationCount,
               orgGuids
              }).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            gotResponse(data, selectedPathwayType);
          } else {
            setData({
              page: 0,
              size: 0,
              pageSize: 0,
              values: []
            });
            setEmptyTableText(
              "There are no students enrolled in the class yet. Once you have students enrolled, they will show here."
            );
            dispatch(updateActivityIndicator(false));
          }
        }
      );
    } catch (err) {
      dispatch(updateActivityIndicator(false));
    }
  }

  async function getCSVData(csvCallback: (data: any) => void) {
    const placementObj = getPlacementRequestObjects(pathwayType);
    const apiCall = getStudentPlacementApiFunction(true);
    const orgGuids = getStudentPlacementOrgGuids(true);

    apiCall({...placementObj, orgGuids}).then(
      async ({ status, data }: { status: number; data: Response }) => {
        const csvData = JSON.parse(await data.text());
        for (let i in csvData) {
          if (csvData[i].studentPlacementId) {
            csvData[i].ilpStatus = csvData[i].isEnabled ? "Active" : "Deactivated";
          } else {
            csvData[i].ilpStatus = "No Placement";
          }
          if (csvData[i].placementDate) {
            let date = new Date(csvData[i].placementDate);
            csvData[i].placementDate = `${date.getMonth() + 1 < 10 ? 0 : ""}${date.getMonth() + 1}/${
              date.getDate() < 10 ? 0 : ""
            }${date.getDate()}/${date.getFullYear()}`;
          }
          if (subjectIdMap[csvData[i].subject] === 1) {
            if (csvData[i].pathwayType) {
              csvData[i].pathwayType = csvData[i].pathwayType === ApiPathwayTypeEnum.PRACTICE ? PathwayTypeEnum.PRACTICE : PathwayTypeEnum.INSTRUCTION;
            } else {
              csvData[i].pathwayType = "";
            }
          } else {
            csvData[i].pathwayType = "N/A";
          }
          if (!csvData[i].organizationName && getCurrentOrganizationDetails) {
            csvData[i].organizationName = getCurrentOrganizationDetails.name;
          }
          if (csvData[i].source && csvData[i].source === sourceMyPath && props.getIsMyPathOnly) {
            csvData[i].source = sourceImaginePlusDiagnostic;
          }
        }
        csvCallback({rows: csvData, columns: columnsForCsv});
      }
    );
  }

  function restoreData() {
    activeFilters.current = [];
    filterStates.current = filterStatesToReset.current;
    setFilterCountClick(false);
    setStateName({});
    setPageNo(1);
    getStudentPlacement(1);
  }

  const Styles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        "& > *": {
          marginTop: theme.spacing(2)
        }
      }
    })
  );
  let classes = Styles();

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPageNo(value);
    getStudentPlacement(value);
  };

  function selectAllSubjects(gstatus: Array<string>, gstatusLength: number) {
    if (subjectlist.length === gstatusLength) {
      gstatus = [];
      addAllSubjectsFilterStatesAndActiveFilters();
      for (let sub in subjectlist) {
        activeFilters.current.splice(activeFilters.current.indexOf(subjectlist[sub]), 1);
        filterStates.current = { ...filterStates.current, subjectname: false };
      }
      gstatus.push("All Subjects");
    }
    return gstatus;
  }

  function setFilterStateAndActiveFilter() {
    filterStates.current = {
      ...filterStates.current,
      Math: false,
      Reading: false,
      [globalSubjectPurpose]: false,
      Science: false
    };

    for (let sub in subjectlist) {
      if (activeFilters.current.indexOf(subjectlist[sub]) !== -1) {
        activeFilters.current.splice(activeFilters.current.indexOf(subjectlist[sub]), 1);
      }
    }
  }

  function removeAllSubjectsFilterStatesAndActiveFilters() {
    filterStates.current = {
      ...filterStates.current,
      "All Subjects": false
    };
    if (activeFilters.current.indexOf(`All Subjects`) !== -1) {
      activeFilters.current.splice(activeFilters.current.indexOf(`All Subjects`), 1);
    }
  }

  function addAllSubjectsFilterStatesAndActiveFilters() {
    activeFilters.current.push("All Subjects");
    filterStates.current = {
      ...filterStates.current,
      "All Subjects": true
    };
  }
  function filterCheckedData(subjectName, gstatus) {
    if (subjectName === "All Subjects") {
      filterStates.current = {
        ...filterStates.current,
        "All Subjects": true
      };
      gstatus.push("All Subjects");
      setFilterStateAndActiveFilter();
    } else {
      let filterlist = subjectlist.filter((x) => x !== subjectName);
      if (filterlist.filter((x) => filterStates.current[x] === false).length === 0) {
        addAllSubjectsFilterStatesAndActiveFilters();
        setFilterStateAndActiveFilter();
        gstatus.push("All Subjects");
      } else {
        removeAllSubjectsFilterStatesAndActiveFilters();
        for (let sub in subjectlist) {
          if (activeFilters.current.indexOf(subjectlist[sub]) !== -1) {
            gstatus.push(subjectlist[sub]);
          }
        }
        gstatus = selectAllSubjects(gstatus, gstatus.length);
      }
    }
    return gstatus;
  }
  function filterUncheckedData(subjectName, gstatus) {
    if (subjectName === "All Subjects") {
      filterStates.current = {
        ...filterStates.current,
        "All Subjects": false
      };
      setFilterStateAndActiveFilter();
    } else {
      removeAllSubjectsFilterStatesAndActiveFilters();
      //if subject is present push to gstatus
      for (let sub in subjectlist) {
        if (activeFilters.current.indexOf(subjectlist[sub]) !== -1) {
          gstatus.push(subjectlist[sub]);
        }
      }
    }
    return gstatus;
  }

  function assignSubjectStatus(subjectName: string, isSubject: boolean, gstatus: Array<string>) {
    let subjectStatus = [];
    if (isSubject) {
      subjectStatus = filterCheckedData(subjectName, gstatus);
    } else {
      subjectStatus = filterUncheckedData(subjectName, gstatus);
    }

    return subjectStatus;
  }

  const assignGradeLevelStatus = (changedFilter: string, isFilterChecked: boolean, gradeLevels: string[]) => {
    let allGradeLevelsSelected = true;
    for (let x in filterStates.current) {
      if (x.includes("gl-conf-") && x !== "gl-conf-All" && !filterStates.current[x]) {
        allGradeLevelsSelected = false;
      }
    }
    //Clear all grade level filters and set "All" to true/false
    if (changedFilter === "gl-conf-All" || allGradeLevelsSelected) {
      for (let x in filterStates.current) {
        if (x.includes("gl-conf-")) {
          filterStates.current = { ...filterStates.current, [x]: false };
        }
      }

      let allGradesRemoved = activeFilters.current.filter((x) => !x.includes("gl-conf-"));
      activeFilters.current = allGradesRemoved;
      if (isFilterChecked || allGradeLevelsSelected) {
        filterStates.current = { ...filterStates.current, "gl-conf-All": true };
        gradeLevels.push("gl-conf-All");
        activeFilters.current = [...activeFilters.current, "gl-conf-All"];
      }
    } else {
      //if a grade level is selected or unselected and not all grade levels are selected, remove "all" filter
      filterStates.current = {
        ...filterStates.current,
        "gl-conf-All": false
      };
      if (activeFilters.current.indexOf(`gl-conf-All`) !== -1) {
        activeFilters.current.splice(activeFilters.current.indexOf(`gl-conf-All`), 1);
      }
      for (let x in activeFilters.current) {
        if (activeFilters.current[x].includes("gl-conf-")) {
          gradeLevels.push(activeFilters.current[x]);
          filterStates.current = { ...filterStates.current, [activeFilters.current[x]]: true };
        }
      }
    }
    return gradeLevels;
  };

  function getState(dataArgs: Array<string | boolean>) {
    const changedFilter = dataArgs[0] as string;
    const isFilterChecked = dataArgs[1] as boolean;

    let gradeLevels: string[] = [];
    let Subject: string[] = [];
    let placementGrades: string[] = [];


    if (subjectFilterValues.includes(changedFilter)) {
      //handle filter updates for all subjects selected/unselected
      let updatedSubject = assignSubjectStatus(changedFilter, isFilterChecked, Subject);
      setStateName({ ...stateName, Subject: updatedSubject });
    } else if (changedFilter.includes("gl-conf-")) {
      //handle filter updates for all grades selected/unselected
      let updatedGradeLevels = assignGradeLevelStatus(changedFilter, isFilterChecked, gradeLevels);
      setStateName({
        Subject: stateName["Subject"] ? stateName["Subject"] : [],
        Source: stateName["Source"] ? stateName["Source"] : [],
        Placement: stateName["Placement"] ? stateName["Placement"] : [],
        "Overall Placement": stateName["Overall Placement"] ? stateName["Overall Placement"] : [],
        "Grade Level": updatedGradeLevels 
      });
    } else if (changedFilter.includes("pl-conf-")) {
      for (let x in activeFilters.current) {
        if (activeFilters.current[x].includes("pl-conf-")) {
          placementGrades.push(activeFilters.current[x]); 
        }
      }
      setStateName({
        "Grade Level": stateName["Grade Level"] ? stateName["Grade Level"] : [],
        Subject: stateName["Subject"] ? stateName["Subject"] : [],
        Source: stateName["Source"] ? stateName["Source"] : [],
        Placement: stateName["Placement"] ? stateName["Placement"] : [],
        "Overall Placement": placementGrades 
      });
    } 

    setPageNo(1);
    getStudentPlacement(1);
  }

  function subGrades(value: string) {
    setMathGrade(value);
  }

  function displayMathGrade() {
    if (mathGrade === "") {
      return "Select Grade";
    } else {
      return `Grade ${mathGrade}`;
    }
  }

  function displayReadingGrade() {
    if (mathGrade === "") {
      return "Select Grade";
    } else {
      return `Grade ${readingGrade}`;
    }
  }

  const displaySubjectGrades = (row: SubjectGradesProps, index: number) => {
    return (
      <div className="subjectgrades">
        <div className="subnamechild">{row.subject}</div>
        <div className="gradenamechild">
          <div
            className={
              studentIds.length === 0 || studentIds.length > 500
                ? "studentbulkaction buttondisabled"
                : "studentbulkaction"
            }
            onClick={() => {
              setShowSubToggle(row.subject);
              setShowToggle(!showToggle);
            }}
          >
            {showToggle && showSubToggle === subjectGrades[index].subject ? (
              <div
                className="gradeoptionsdropdown"
                onClick={() => {
                  setShowAddPlacementModal(true);
                  setShowToggle(!showToggle);
                }}
              >
                <div
                  style={{
                    height: "200px",
                    overflow: "auto",
                    width: "100%"
                  }}
                >
                  {row.grade.length > 0 &&
                    row.grade.map((value: string, _index: number) => {
                      return (
                        <div
                          className="dropdownoption"
                          onClick={() => {
                            showSubToggle.toLowerCase() === "math" ? subGrades(value) : setReadingGrade(value);
                          }}
                        >
                          Grade {value}
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : null}

            <div style={{ color: "#3d4450" }}>
              {subjectGrades[index].subject.toLowerCase() === "math" ? displayMathGrade() : displayReadingGrade()}
            </div>

            <div
              style={{ color: "#3d4450" }}
              onClick={() => {
                setShowSubToggle(row.subject);
                setShowToggle(!showToggle);
              }}
            >
              <i className="fa fa-chevron-down"></i>
            </div>
          </div>
        </div>
      </div>
    );
  };

  function renderBulkAddplacementModal() {
    return (
      <div className="bulk-add-placement-layout">
        <div>
          <img
            className="cancelimage"
            src={TimesIcon}
            alt="close add placement popup"
            style={{ width: "14px" }}
            onClick={() => {
              setShowToggle(false);
              closeModal();
              setMathGrade("");
              setReadingGrade("");
            }}
          />
        </div>
        <div className="heading">
          <div className="headingone">Bulk Action - Add Placement</div>
        </div>

        <div className="setname">Set the placement grade for the {studentIds.length} placements you selected.</div>
        <div className="subjectgradesheading">
          <div className="subname">Subject</div>
          <div className="gradename">New Placement</div>
        </div>

        <div>
          {subjectGrades.map((row: SubjectGradesProps, index: number) => {
            return displaySubjectGrades(row, index);
          })}
        </div>
        <div className="actionbuttons">
          <UxAction
            key={0}
            size="sm"
            enabled={true}
            text={"Cancel"}
            className="px-2 py-1 stucancelbtn"
            priority={UxShadesEnum.LIGHT}
            callback={() => {
              setShowToggle(false);
              closeModal();
              setMathGrade("");
              setReadingGrade("");
            }}
          />
          <UxAction
            key={1}
            size="sm"
            enabled={mathGrade === "" && readingGrade === "" ? false : true}
            text={"Save"}
            className="px-4 py-1 studentsavebtn"
            priority={UxShadesEnum.DARK}
            callback={() => {
              setShowToggle(false);
              setShowConfirmModal(true);
              setShowAddPlacementModal(false);
            }}
          />
        </div>
      </div>
    );
  }

  function selectedStudentsSubGradesData() {
    try {
      dispatch(updateActivityIndicator(true));
      let studentsSubGradesData = {
        mathGrade: "",
        readingGrade: "",
        organizationId: [0],
        placementDetails: [
          {
            userId: 0,
            subjectId: 0
          }
        ]
      };
      let studentsData = [];
      studentIds.forEach((value: string, _index: number) => {
        let splittedData: string[] = value.split("_");
        studentsData.push({
          userId: Number(splittedData[0]),
          subjectId: Number(splittedData[1])
        });
      });

      setSelected([]);

      studentsSubGradesData = {
        ...studentsSubGradesData,
        mathGrade: mathGrade,
        readingGrade: readingGrade,
        placementDetails: studentsData
      };
      UserService.bulkStudentsPlacementsDataWithGuid(studentsSubGradesData).then(
        ({ status }: { status: number; data: Response }) => {
          if (status === 200) {
            getStudentPlacement(1);
            setPageNo(1);
            dispatch(updateStudentIds([]));
            setShowConfirmModal(false);
            setShowAddPlacementModal(false);
            setMathGrade("");
            setReadingGrade("");
            setTimeout(() => {
              setShowToast({
                val: true,
                msg: "Placements added",
                color: "#37A492",
                type: "success"
              });
            }, 1000);
          } else {
            setShowToast({
              val: true,
              msg: "Something went wrong, Placements are not added,please try again later",
              color: "red",
              type: "error"
            });
            dispatch(updateActivityIndicator(false));
          }
        }
      );
    } catch (exception) {
      dispatch(updateActivityIndicator(false));
    }
  }

  function confirmAddPlacementModal() {
    return (
      <div className="bulk-add-placement-layout">
        <div>
          <img
            className="cancelimage"
            src={TimesIcon}
            alt="close add placement popup"
            style={{ marginRight: "18px", width: "14px" }}
            onClick={() => {
              setShowConfirmModal(false);
              setShowAddPlacementModal(false);
              setMathGrade("");
              setReadingGrade("");
            }}
          />
        </div>
        <div className="heading">
          <div className="headingone confirmheadingone">Bulk Action - Add Placement</div>
        </div>

        <div className="setname  confirmsetname">
          The {studentIds.length} placements you selected will be manually placed according to <br />
          the grades(s) and subjects(s) you selected.
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: " flex-end",
            marginRight: "-29px",
            paddingTop: "15px"
          }}
        >
          <UxAction
            key={0}
            size="sm"
            enabled={true}
            text={"Cancel"}
            className="px-2 py-1 previousBut bulkcancelbtn confirmbulkbtn"
            priority={UxShadesEnum.LIGHT}
            callback={() => {
              setShowConfirmModal(false);
              setShowAddPlacementModal(false);
              setMathGrade("");
              setReadingGrade("");
            }}
          />
          <UxAction
            key={1}
            size="sm"
            text="Confirm"
            className="px-4 py-1 nextBut saveBtn confirmbulkbtn"
            priority={UxShadesEnum.DARK}
            callback={() => {
              localStorage.setItem("confirmClicked", "true");
              selectedStudentsSubGradesData();
            }}
          />
        </div>
      </div>
    );
  }

  const clearAllStudents = () => {
    setSelected([]);
    dispatch(updateStudentIds([]));
  };

  function selectAllStudents(values: IStudentPlacementDetails[]) {
    let allData = [...studentIds];
    let selectedPageData = [...selected];
    for (let data in values) {
      if (!studentIds.includes(values[data].userSubId)) {
        allData.push(values[data].userSubId);
      }

      if (!selected.includes(values[data].userSubId)) {
        selectedPageData.push(values[data].userSubId);
      }
    }

    setSelected([...selectedPageData]);
    dispatch(updateStudentIds([...allData]));
  }

  function checkForSubjectList() {
    let arr: string[] = [];
    for (let studata in selected) {
      let studetails = data.values.filter((item) => item.userSubId === selected[studata]);
      arr = [...arr, studetails[0].subject];
    }
    if (arr.includes("Math") && arr.includes("Reading")) {
      setSubjectGrades([
        { subject: "Math", grade: placementGrades },
        { subject: "Reading", grade: placementGrades }
      ]);
    } else if (arr.includes("Math")) {
      setSubjectGrades([{ subject: "Math", grade: placementGrades }]);
    } else {
      setSubjectGrades([{ subject: "Reading", grade: placementGrades }]);
    }
    setShowAddPlacementModal(true);
    setShow(!show);
  }

  function showAlert() {
    if (showToast.type === "warning") {
      return (
        <Alert
          severity="warning"
          style={{ backgroundColor: showToast.color, color: "#fff" }}
          onClose={() => {
            setShowToast({ val: false, msg: "", color: "", type: "" });
          }}
        >
          {showToast.msg}
        </Alert>
      );
    } else if (showToast.type === "info") {
      return (
        <Alert
          severity="info"
          style={{ backgroundColor: showToast.color, color: "#fff" }}
          onClose={() => {
            setShowToast({ val: false, msg: "", color: "", type: "" });
          }}
        >
          {showToast.msg}
        </Alert>
      );
    } else if (showToast.type === "error") {
      return (
        <Alert
          severity="error"
          style={{ backgroundColor: showToast.color, color: "#fff" }}
          onClose={() => {
            setShowToast({ val: false, msg: "", color: "", type: "" });
          }}
        >
          {showToast.msg}
        </Alert>
      );
    } else {
      return (
        <Alert
          severity="success"
          style={{ backgroundColor: showToast.color, color: "#fff" }}
          onClose={() => {
            setShowToast({ val: false, msg: "", color: "", type: "" });
          }}
        >
          {showToast.msg}
        </Alert>
      );
    }
  }
  const handlePlacementClose = () => {
    setShowEditPlacementModal(false);
  };
  const handleDeactivate = () => {
    setShowDeactivateModal(true);
    handlePlacementClose();
  };
  const handleDeactivateClose = () => {
    setShowDeactivateModal(false);
  };
  const handleToastClose = () => {
    setToastState({ activateToast: false, status: "" });
  };

  const updatePlacementGrade = async ({ placementValue, isLocked }: { placementValue: number, isLocked: boolean }) => {
    handlePlacementClose();
    dispatch(updateActivityIndicator(true));
    await UserService.updatePlacement(modalData.studentPlacementId, placementValue, isLocked).then(
      ({ status }: { status: number; data: Response }) => {
        if (status === 200) {
          getStudentPlacement(pageNo);
          setToastState({ activateToast: true, status: "Overall Placement Updated Successfully" });
        }
      }
    );
    dispatch(updateActivityIndicator(false));
  };

  const onDeactivatePlacement = async () => {
    handleDeactivateClose();
    dispatch(updateActivityIndicator(true));
    await UserService.deactivateStudentPlacementId(modalData.studentPlacementId).then(
      ({ status }: { status: number; data: Response }) => {
        if (status === 200) {
          getStudentPlacement(pageNo);
          setToastState({ activateToast: true, status: "Overall Placement Deactivated Successfully" });
        }
      }
    );
    dispatch(updateActivityIndicator(false));
  };

  return (
    <>
      {showAssignPlacementModal && (
        <ManipStudentPlacement
          showModal={showAssignPlacementModal}
          setGrade={setDropdownSelectedGrade}
          updatePlacement={onUpdate}
          closeModal={closeModal}
          data={modalData}
          gradesData={grades}
        />
      )}
      <div className="toast-container">
        <HoverToast
          toastBody={toastState.status}
          toastLinkText="View"
          toastDismiss={true}
          toastDelay={3000}
          show={toastState.activateToast}
          onClose={() => handleToastClose()}
          autohide
        />
      </div>
      {showEditPlacementModal && (
        <EditPlacementModal
          isVisible={showEditPlacementModal}
          onClose={handlePlacementClose}
          placementData={modalData}
          //fetchDomainPlacements={UserService.getDomainPlacements} TODO remove test data once API is available
          fetchDomainPlacements={() => (Array.from({ length: 7 }, () => ({ domainName: "Test", placementGrade: "K" })))}
          updatePlacement={updatePlacementGrade}
          onDeactivate={handleDeactivate}
          isDomainPlacementEnabled={getFlagrKeys["enable-domain-placement"]}
          isContentBridgeEnabled={getFlagrKeys["enable-content-bridge"]}
          hasIPlusMLicense={hasIMPlusLicense}
        />
      )}
      {showDeactivateModal && (
        <DeactivateModal
          subject={modalData.subject}
          isVisible={showDeactivateModal}
          onClose={handleDeactivateClose}
          onDeactivatePlacement={onDeactivatePlacement}
          hasIPlusMLicense={hasIMPlusLicense}
          pathwayType={modalData.pathwayType}
        />
      )}
      <ManipILPModal
        showModal={showManipModal}
        title={title}
        message={msg}
        closeModal={closeManipModal}
        save={saveDeactivation}
      />
      <div className="class-nav-tabs px-2">
        <UxFilterSlider
          opacity={opacity}
          backgroundVisibility={contentVisibility}
          left={sliderLeft}
          sliderHeader="Filters"
          activeFilters={activeFilters.current}
          setActiveFilters={activeFilters}
          resetText="Reset Filters"
          onResetClick={() => {
            restoreData();
          }}
          activeFilterText="Active Filters"
          types={types}
          typesHeader={typesHeader}
          typesList={typesList}
          filterStates={filterStates.current}
          setFilterState={filterStates}
          setSliderLeft={setSliderLeft}
          setBackgroundVisibility={setContentVisibility}
          setOpacity={setOpacity}
          onChecked={() => {
            setPageNo(1);
          }}
          textMessage={textMessage}
          setMultiFilterDropDown={setFilterCountClick}
          screen="studentplacement"
          stateName={stateName}
          setStateName={setStateName}
          stateCallback={getState}
          conflictHeaders={conflictHeaders}
          conflictKeys={conflictKeys}
        />
        <div className="StudentPlacementFieldsHolder">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row"
            }}
          >
            <UxSliderAction
              filterText="Filters"
              activeFilters={activeFilters.current}
              minCount={1}
              multiFilterDropDown={filterCountClick}
              filterStates={filterStates.current}
              setFilterState={filterStates}
              setActiveFilters={activeFilters}
              setMultiFilterDropDown={setFilterCountClick}
              setSliderLeft={setSliderLeft}
              left={sliderLeft}
              setBackgroundVisibility={setContentVisibility}
              setOpacity={setOpacity}
              types={types}
              onChecked={() => {
                setPageNo(1);
              }}
              textMessage={textMessage}
              stateName={stateName}
              setStateName={setStateName}
              stateCallback={getState}
            />
            {showPathwayTypeDropdown && (
              <div className="pathway-type-dropdown">
                <LabeledSelect
                  label="Path Type"
                  selected={pathwayType}
                  options={pathwayTypeOptions}
                  onOptionSelected={handlePathwayTypeChange}
                />
              </div>
            )}
            {getCurrentUserDetails?.role != 5 && (
              <div
                className={
                  studentIds.length == 0 || studentIds.length > 500
                    ? "studentbulkaction buttondisabled"
                    : "studentbulkaction"
                }
                onClick={
                  studentIds.length == 0 || studentIds.length > 500
                    ? () => {
                        //do nothing
                      }
                    : () => {
                        setShow(!show);
                      }
                }
              >
                {studentIds.length > 0 && show ? (
                  <div
                    className="addplacementdropdown"
                    onClick={() => {
                      checkForSubjectList();
                    }}
                  >
                    <span className="placementoptionhover"> Add Placement</span>
                  </div>
                ) : null}

                <div>Bulk Actions</div>

                <div
                  onClick={
                    studentIds.length == 0 || studentIds.length > 500
                      ? () => {
                          //do nothing
                        }
                      : () => {
                          setShow(!show);
                        }
                  }
                >
                  <i className="fa fa-chevron-down"></i>
                </div>
              </div>
            )}
            <BulkImportsModal
              showBulkModal={studentIds.length > 0 && (showConfirmModal || showAddPlacementModal)}
              children={showConfirmModal ? confirmAddPlacementModal() : renderBulkAddplacementModal()}
              width={showConfirmModal ? "533px" : "693px"}
              height={showConfirmModal ? "209px" : "551px"}
            />
          </div>
          <div className="studentPlacementInputHolder">
            <UxSearchInput
              ref={StudentPlacementSearchRef}
              flexClassName="studentPlacementFlexHolder"
              inputClassName="studentPlacementInputText"
              placeHolder="Search Student Name, Username, Assessment ID"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                StudentPlacementSearchRef.current.value = e.target.value;
                if (
                  StudentPlacementSearchRef.current.value !== "" &&
                  StudentPlacementSearchRef.current.value !== undefined &&
                  !searchResultRef.current
                ) {
                  document.getElementById("StudentPlacementClosesICon").style.display = "block";
                  document.getElementById("StudentPlacementClearICon").style.display = "none";
                } else if (
                  StudentPlacementSearchRef.current.value !== "" &&
                  StudentPlacementSearchRef.current.value !== undefined &&
                  searchResultRef.current
                ) {
                  document.getElementById("StudentPlacementClosesICon").style.display = "none";
                  document.getElementById("StudentPlacementClearICon").style.display = "block";
                } else {
                  document.getElementById("StudentPlacementClosesICon").style.display = "none";
                  document.getElementById("StudentPlacementClearICon").style.display = "none";
                }
              }}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                handleKeyPress(e);
              }}
              searchButtonClassName="studentPlacementSearchButton"
              searchButtonContent={renderSearchButton()}
              onSearchClick={() => {
                handleSearchButtonClick();
              }}
              clearButtonClassName="studentPlacementClearButton"
              clearButtonContent={renderCancelButton()}
              onClearClick={() => {
                clearButtonClick();
              }}
              restoreCheckBoxState={() => {
                restoreDefaultCheckState();
              }}
            />
            <MpngExportReport
              reportName={"Student Placement_" +
                    CSVFilename.replace(/[.]/g, " ") +
                    "_" +
                    (new Date(`${new Date()}`).getUTCMonth() + 1) +
                    "_" +
                    new Date(`${new Date()}`).getUTCDate() +
                    "_" +
                    new Date(`${new Date()}`).getUTCFullYear() +
                    "_" +
                    Date.now()}
              includePDF={false}
              csvCallback={getCSVData}
            />
          </div>
        </div>

        {studentIds.length >= 490 && (
          <div style={{ marginTop: "10px" }}>
            <UxBanner
              leftIcon={
                studentIds.length > 500 ? (
                  <i className="fa fa-exclamation-triangle" style={{ fontSize: "20px", color: "red" }}></i>
                ) : (
                  <img src={WarningRosterIcon} alt="" />
                )
              }
              text={
                <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                  {" "}
                  {studentIds.length > 500 ? config.BulkPlacementExceeds : config.BulkPlacementMax}
                </div>
              }
              color="#454545"
              backgroundColor={studentIds.length > 500 ? "#FDECEC" : "#FDF7EC"}
              borderColor={studentIds.length > 500 ? "#EB4949" : "#EBB549"}
            />
          </div>
        )}
        {studentIds.length > 0 && (
          <div className="placementBulkOptions">
            <p className="selectcount">
              {" "}
              {studentIds.length} {studentIds.length > 1 ? "Placements have " : "Placement has "} been selected.{" "}
            </p>
            {selected.length !==
            data.values.filter(
              (item: IStudentPlacementDetails) =>
                item.subject.toLowerCase() != globalSubjectPurpose.toLowerCase() &&
                item.subject.toLowerCase() != "science"
            ).length ? (
              <p
                className="selectall"
                onClick={() => {
                  selectAllStudents(
                    data.values.filter(
                      (item: IStudentPlacementDetails) =>
                        item.subject.toLowerCase() != globalSubjectPurpose.toLowerCase() &&
                        item.subject.toLowerCase() != "science"
                    )
                  );
                }}
              >
                {" "}
                Select All{" "}
                {
                  data.values.filter(
                    (item: IStudentPlacementDetails) =>
                      item.subject.toLowerCase() != globalSubjectPurpose.toLowerCase() &&
                      item.subject.toLowerCase() != "science"
                  ).length
                }{" "}
                Placements{" "}
              </p>
            ) : null}

            <p
              className="clearall"
              onClick={() => {
                clearAllStudents();
              }}
            >
              {" "}
              Clear Selections{" "}
            </p>
          </div>
        )}
        <div className="student-placement-table-container">
          <div className="student-placement-table-header">
            <span className="student-placement-title">Student Placement</span>
            {showPathwayHeaderBadge && ( 
              <>
                <PathwayTypeBadge pathwayType={pathwayType} />
                <HoverToolTip help={pathwayType === ApiPathwayTypeEnum.PRACTICE ? practiceTooltipInfo : instructionTooltipInfo} className="pathway-popover">
                  <UxIcon icon={UxIconEnum.INFO_CIRCLE} size={UxSizeEnum.MEDIUM} className="pathway-type-info-icon" />
                </HoverToolTip>
              </>
            )}
          </div>
          <div ref={elementRef}>
            <StudentPlacementTable
              columnDetails={columnDetails}
              rowData={data.values}
              showColumnCheckbox={
                !!((subjectlist.includes("Math") || subjectlist.includes("Reading")) &&
                getCurrentUserDetails?.role != 5)
              }
              emptyTableText={emptyTableText}
              checkboxRef={"userSubId"}
              selected={selected}
              setSelected={setSelected}
              viewChild={viewStudent}
              mtableHeight={tableHgt}
              customKebabMenuData={customKebabMenuData}
              showHelpIconFlag={icon}
              hasRetestIcon={data.values.some(x => x.isReset && x.source === "MyPath")}
              sortStates={sortStates}
              updateSortStates={updateSortStates}
            />
          </div>
          {data.values.length === 0 ? null : (
            <div className="fixedpagination">
              <UxPaginate
                data={data}
                page={pageNo}
                paginationSize={getPaginationControllerState[5].paginationCount}
                className={classes.root}
                handleChange={handleChange}
              />
              {data.size > 50 && (
                <UxPaginationController
                  rowsList={paginationControllers}
                  setRowsCount={getPaginationControllerState[5].paginationCount}
                  callback={callback}
                />
              )}
            </div>
          )}
        </div>
      </div>

      <div className={alertclasses.root}>
        <Snackbar
          open={showToast.val}
          autoHideDuration={4000}
          onClose={() => {
            setShowToast({ val: false, msg: "", color: "", type: "" });
          }}
        >
          {showAlert()}
        </Snackbar>
      </div>
    </>
  );
}

export default connect(mapStateToProps)(StudentPlacement);
