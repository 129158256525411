/* eslint-disable react-hooks/exhaustive-deps */
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  TimesIcon,
  UxIcon,
  UxIconEnum,
  UxMUITable,
  UxPaginate,
  UxPaginationController,
  UxSearchInput,
  UxSizeEnum
} from "@wne-spa/ux-component-library";
import { MpngExportReport } from "@wne/mpng-components";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { paginationControllers } from "../../helpers/configs";
import HelpIcon from "../../images/help_search_icon.png";
import {
  updateActivityIndicator,
  updatePaginationControllerState,
  updateSearchEducatorListSort
} from "../../Redux/Action";
import ReduxProps from "../../Redux/Redux.props";
import { UserService } from "../../services/helpers/user.services";
import "./EducatorsList.scss";
import { EducatorProps, IEducators, RowProps } from "./IEducatorList";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getUserRoleId: state.getUserRoleId,
    getDaDetails: state.getDaDetails,
    getUserId: state.getUserId,
    getUserGuId: state.getUserGuId,
    getSearchEducatorListSort: state.getSearchEducatorListSort,
    getPaginationControllerState: state.getPaginationControllerState,
    getFlagrKeys: state.getFlagrKeys,
    getUserRole: state.getUserRole,
    getIsOrgHasRegistrationOwned: state.getIsOrgHasRegistrationOwned
  };
}
interface Props extends EducatorProps, ReduxProps {}

function EducatorList(props: Props): JSX.Element {
  const navigate = useNavigate();
  const [showTable, setShowTable] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState(false);
  const {
    dispatch,
    getUserRoleId,
    getDaDetails,
    getUserId,
    getSearchEducatorListSort,
    getPaginationControllerState,
    getFlagrKeys,
    getUserGuId
  } = props;
  const [pageNo, setPageNo] = useState(1);
  const [data, setData] = useState({
    page: 0,
    size: 0,
    pageSize: 0,
    values: []
  });
  const [emptyTableText, setEmptyTableText] = useState<JSX.Element>();
  const [selected, setSelected] = React.useState<string[]>([]);
  const [columnNames, setColumnNames] = useState([
    "Educator Name",
    "Username/Email",
    "Institution",
    "External ID",
    "Educator Type",
    "Status"
  ]);
  const [rowMapper, setrowMapper] = useState([
    ["lastName", "firstName"],
    "loginName",
    "organizations",
    "districtAssignedId",
    "roleName",
    "isEnabled"
  ]);
  const elementRef = useRef(null);
  const [tableHgt, setTableHgt] = useState(0);
  const sortBy = useRef(getSearchEducatorListSort.name);
  const sortOrder = useRef(getSearchEducatorListSort.val);
  const [educatorNameBool, setEducatorNameBool] = useState<boolean | null>(null);
  const [usernameBool, setUsernameBool] = useState<boolean | null>(null);
  const [institutionBool, setInstitutionBool] = useState<boolean | null>(null);
  const [roleBool, setRoleBool] = useState<boolean | null>(null);
  const [statusBool, setStatusBool] = useState<boolean | null>(null);
  const [emailBool, setEmailBool] = useState<boolean | null>(null);
  const headersForCsv = [
    {
      label: "School ID",
      id: "schoolID"
    },
    {
      label: "Educator ID",
      id: "educatorID"
    },
    {
      label: "Educator Name",
      id: "educatorName"
    },
    {
      label: getFlagrKeys["ReadinessFeature_Educator_Email_Loginname"] ? "Username/Email" : "Username",
      id: "userName"
    },
    {
      label: "Institution",
      id: "institution"
    },
    {
      label: "External ID",
      id: "externalID"
    },
    {
      label: "Educator Type",
      id: "educatorType"
    },
    {
      label: "Status",
      id: "status"
    }
  ];

  const helpSearch = (
    <div className="emptyContentWrapper">
      <img src={HelpIcon} className="resultIcon" />
      <div className="helpMessage">No Results Found </div>
      <div className="helpcenter">
        Try modifying the search terms or use the{" "}
        <a href="https://help.edgenuitymypath.com/hc/en-us/articles/4412008995479" target="_blank">
          tips
        </a>{" "}
        in this Help Center article.
      </div>
    </div>
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight > 650) {
        //@ts-ignore
        setTableHgt(
          //@ts-ignore
          parseInt(window.innerHeight - (elementRef.current.offsetTop + 90))
        );
      } else {
        //@ts-ignore
        setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const searchEducatorListSortFunc = () => {
    if (getSearchEducatorListSort.val === "desc" || getSearchEducatorListSort.val === "DESC") {
      switch (getSearchEducatorListSort.name) {
        case "lastname":
          setEducatorNameBool(true);
          break;
        case "loginname":
          setUsernameBool(true);
          break;
        case "organizations":
          setInstitutionBool(true);
          break;
        case "email":
          setEmailBool(true);
          break;
        case "rolename":
          setRoleBool(true);
          break;
        default:
          setStatusBool(true);
      }
    } else if (getSearchEducatorListSort.val === "asc" || getSearchEducatorListSort.val === "ASC") {
      switch (getSearchEducatorListSort.name) {
        case "lastname":
          setEducatorNameBool(false);
          break;
        case "loginname":
          setUsernameBool(false);
          break;
        case "organizations":
          setInstitutionBool(false);
          break;
        case "email":
          setEmailBool(false);
          break;
        case "rolename":
          setRoleBool(false);
          break;
        default:
          setStatusBool(false);
      }
    }
  };

  useEffect(() => {
    if (
      (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) &&
      !getFlagrKeys["ReadinessFeature_Educator_Email_Loginname"]
    ) {
      setColumnNames([
        "Educator Name",
        "Username",
        "Institution",
        "Email Address",
        "External ID",
        "Educator Type",
        "Status"
      ]);
      setrowMapper([
        ["lastName", "firstName"],
        "loginName",
        "organizations",
        "email",
        "districtAssignedId",
        "roleName",
        "isEnabled"
      ]);
    }
    if (window.innerHeight > 650) {
      //@ts-ignore
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef.current.offsetTop + 90))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
    }
    searchEducatorListSortFunc();
  }, []);

  function handleTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.charAt(0) !== " ") {
      setSearchText(event.target.value);
    }
  }

  function handleSearchButtonClick(): void {
    if (searchText !== "") {
      searchSchools(1);
      setSearchResult(true);
      setShowTable(true);
      setPageNo(1);
    }
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key === "Enter" && searchText !== "") {
      searchSchools(1);
      setSearchResult(true);
      setShowTable(true);
      setPageNo(1);
    }
  }

  function viewEducator(row: RowProps) {
    if (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) {
      navigate(`/cvieweducator/${row.orgGuid}_${row.userGuid}`);
    } else if (getUserRoleId === 3) {
      navigate(`/dvieweducator/${row.orgGuid}_${row.userGuid}`);
    } else if (getUserRoleId === 4 && getDaDetails.type === 2) {
      navigate(`/svieweducator/${row.orgGuid}_${row.userGuid}`);
    } else if (getUserRoleId === 4 && getDaDetails.type === 1) {
      navigate(`/suvieweducator/${row.orgGuid}_${row.userGuid}`);
    }
  }

  function getSearchRequest(pagenumber: number) {
    let jsonObj;
    if (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) {
      jsonObj = {
        Page: pagenumber,
        PageSize: getPaginationControllerState[1].paginationCount,
        SearchText: searchText,
        SortBy: sortBy.current,
        SortOrder: sortOrder.current
      };
    } else if (getUserRoleId === 3) {
      jsonObj = {
        Page: pagenumber,
        PageSize: getPaginationControllerState[1].paginationCount,
        SearchText: searchText,
        OrgGuid: getDaDetails.id,
        UserGuid: getUserGuId,
        SortBy: sortBy.current,
        SortOrder: sortOrder.current
      };
    } else {
      jsonObj = {
        Page: pagenumber,
        PageSize: getPaginationControllerState[1].paginationCount,
        SearchText: searchText,
        UserGuid: getUserGuId,
        SortBy: sortBy.current,
        SortOrder: sortOrder.current
      };
    }
    return jsonObj;
  };

  async function searchSchools(pagenumber: number) {
    try {
      dispatch(updateActivityIndicator(true));
      setSearchResult(true);
      dispatch(
        updateSearchEducatorListSort({
          name: sortBy.current,
          val: sortOrder.current
        })
      );

      const request = getSearchRequest(pagenumber);

      UserService.searchEducatorsWithGuid(request).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          gotResponse(data);
        } else {
          dispatch(updateActivityIndicator(false));
          setEmptyTableText(helpSearch);
          setData({
            page: 0,
            size: 0,
            pageSize: 0,
            values: []
          });
        }
      });
    } catch (err) {
      //do nothing
    }
  }

  async function getCSVData(csvCallback: (data: any) => void) {
    dispatch(updateActivityIndicator(true));
    const request = getSearchRequest(1);
    try {
      const { status, data } = await UserService.getEducatorsCSVForSADA(request);
      if (status === 200) {
        const csvData = JSON.parse(await data.text());
        csvCallback({ rows: csvData, columns: headersForCsv });
      }
      dispatch(updateActivityIndicator(false));
    } catch (err) {
      dispatch(updateActivityIndicator(false));
    }
  }

  function sortChange(type: string) {
    switch (type) {
      case "Educator Name":
        sortOrder.current = !educatorNameBool ? "desc" : "asc";
        sortBy.current = "lastname";
        break;
      case "Username/Email":
        sortOrder.current = !usernameBool ? "desc" : "asc";
        sortBy.current = "loginname";
        break;
      case "Institution":
        sortOrder.current = !institutionBool ? "desc" : "asc";
        sortBy.current = "organizations";
        break;
      case "Email Address":
        sortOrder.current = !emailBool ? "desc" : "asc";
        sortBy.current = "email";
        break;
      case "Educator Type":
        sortOrder.current = !roleBool ? "desc" : "asc";
        sortBy.current = "rolename";
        break;
      default:
        sortOrder.current = !statusBool ? "desc" : "asc";
        sortBy.current = "isenabled";
    }
    searchSchools(1);
    setPageNo(1);
  }

  async function gotResponse(responsedata: Response) {
    let allData = JSON.parse(await responsedata.text());
    if (sortBy.current === "lastname") {
      setUsernameBool(null);
      setRoleBool(null);
      setInstitutionBool(null);
      setStatusBool(null);
      setEmailBool(null);
    } else if (sortBy.current === "loginname") {
      setEducatorNameBool(null);
      setRoleBool(null);
      setStatusBool(null);
      setInstitutionBool(null);
      setEmailBool(null);
    } else if (sortBy.current === "organizations") {
      setEducatorNameBool(null);
      setUsernameBool(null);
      setStatusBool(null);
      setRoleBool(null);
      setEmailBool(null);
    } else if (sortBy.current === "email") {
      setEducatorNameBool(null);
      setUsernameBool(null);
      setStatusBool(null);
      setInstitutionBool(null);
      setRoleBool(null);
    } else if (sortBy.current === "rolename") {
      setEducatorNameBool(null);
      setUsernameBool(null);
      setStatusBool(null);
      setInstitutionBool(null);
      setEmailBool(null);
    } else {
      setEducatorNameBool(null);
      setUsernameBool(null);
      setRoleBool(null);
      setInstitutionBool(null);
      setEmailBool(null);
    }
    if (window.innerHeight > 650) {
      //@ts-ignore
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef.current.offsetTop + 90))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
    }
    if (allData.size === 0) {
      setEmptyTableText(helpSearch);
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });
    } else {
      setData(allData);
    }
    dispatch(updateActivityIndicator(false));
  }

  function renderCancelButton() {
    if (searchText !== "" && !searchResult) {
      return <img src={TimesIcon} alt="" width="20px" height="20px" />;
    } else if (searchText !== "" && searchResult) {
      return (
        <div className="searchIconText">
          <span> Clear Results </span>
        </div>
      );
    }
  }

  function clearButtonClick() {
    if (searchText !== "" && !searchResult) {
      setSearchText("");
    } else if (searchText !== "" && searchResult) {
      setSearchText("");
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });
      setSearchResult(false);
      setShowTable(false);
      setPageNo(1);
    }
  }

  const Styles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        "& > *": {
          marginTop: theme.spacing(2)
        }
      }
    })
  );
  let classes = Styles();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    searchSchools(value);
    setPageNo(value);
  };

  function renderSearchButton() {
    return <UxIcon icon={UxIconEnum.SEARCH} size={UxSizeEnum.MEDIUM} className="searchIcon" />;
  }

  async function callback(args: number) {
    let updateState = getPaginationControllerState;
    updateState[1] = { paginationPageName: "Educators", paginationCount: args };
    setPageNo(1);
    dispatch(updatePaginationControllerState(updateState));
    try {
      dispatch(updateActivityIndicator(true));
      UserService.updatePaginationCount(getUserId, 2, args).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            searchSchools(1);
          } else {
            dispatch(updateActivityIndicator(false));
          }
        }
      );
    } catch (err) {
      dispatch(updateActivityIndicator(false));
    }
  }

  const searchResultInstitutionInputClass = () => {
    if (!searchResult) {
      return "institutionFullInputTextMiddle";
    } else {
      return "schoolInputTextMiddle";
    }
  };
  const searchTextInstitutionInputClass = () => {
    if (searchText) {
      return "institutionFullInputTextTop";
    } else {
      return "schoolInputTextTop";
    }
  };
  const searchResultSchoolInputClass = () => {
    if (!searchResult) {
      return "schoolFullInputTextMiddle";
    } else {
      return "schoolInputTextMiddle";
    }
  };
  const searchTextSchoolInputClass = () => {
    if (searchText) {
      return "schoolFullInputTextTop";
    } else {
      return "schoolInputTextTop";
    }
  };
  const showMuiTable = () => {
    if (showTable) {
      return (
        <UxMUITable
          MUITableClassName={data.values.length === 0 ? "emptyTable1" : "scrollableTable"}
          columnNames={columnNames}
          rowMapper={rowMapper}
          rowData={(data as IEducators).values}
          showColumnCheckbox={false}
          viewChild={viewEducator}
          emptyTableText={emptyTableText}
          checkboxRef="loginName"
          selected={selected}
          setSelected={setSelected}
          mtableHeight={tableHgt}
          showHelpIconFlag={true}
          showSortList={[true, true, true, false, true, true]}
          sortListMapper={{
            "Educator Name": [
              educatorNameBool,
              setEducatorNameBool,
              (args: string) => {
                sortChange(args);
              }
            ],
            ...(getFlagrKeys["ReadinessFeature_Educator_Email_Loginname"]
              ? {
                  "Username/Email": [
                    usernameBool,
                    setUsernameBool,
                    (args: string) => {
                      sortChange(args);
                    }
                  ]
                }
              : {
                  Username: [
                    usernameBool,
                    setUsernameBool,
                    (_args: string) => {
                      sortChange("Username/Email");
                    }
                  ]
                }),
            Institution: [
              institutionBool,
              setInstitutionBool,
              (args: string) => {
                sortChange(args);
              }
            ],
            "Educator Type": [
              roleBool,
              setRoleBool,
              (args: string) => {
                sortChange(args);
              }
            ],
            Status: [
              statusBool,
              setStatusBool,
              (args: string) => {
                sortChange(args);
              }
            ]
          }}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <div className="schools">
      {!showTable && (getUserRoleId === 3 || getUserRoleId === 4) ? (
        <div className="search-csv-export-container">
          <MpngExportReport
            reportName={
              "Educators_" +
              getDaDetails.name.replace(/[.]/g, " ") +
              "_" +
              (new Date(`${new Date()}`).getUTCMonth() + 1) +
              "_" +
              new Date(`${new Date()}`).getUTCDate() +
              "_" +
              new Date(`${new Date()}`).getUTCFullYear() +
              "_" +
              Date.now()
            }
            includePDF={false}
            csvCallback={getCSVData}
          />
        </div>
      ) : null}
      <div className={!showTable ? "searchMiddle" : "searchTop"}>
        {!showTable ? <span className="searchHeading">Search For Educators</span> : null}
        {getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7 ? (
          <UxSearchInput
            inputClassName={
              showTable === false ? searchResultInstitutionInputClass() : searchTextInstitutionInputClass()
            }
            flexClassName={showTable === false ? "institutionFlexHolderMiddle" : "institutionFlexHolderTop"}
            placeHolder={
              getFlagrKeys["ReadinessFeature_Educator_Email_Loginname"]
                ? "Search Educator Name, Username/Email, External ID"
                : "Search Educator Name, Username, External ID, Email Address"
            }
            value={searchText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e)}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
              handleKeyPress(e);
            }}
            searchButtonClassName="searchButton"
            searchButtonContent={renderSearchButton()}
            onSearchClick={() => {
              handleSearchButtonClick();
            }}
            clearButtonClassName="clearButton"
            clearButtonContent={renderCancelButton()}
            onClearClick={() => {
              clearButtonClick();
            }}
          />
        ) : (
          <>
            <UxSearchInput
              inputClassName={showTable === false ? searchResultSchoolInputClass() : searchTextSchoolInputClass()}
              flexClassName={showTable === false ? "schoolFlexHolderMiddle" : "schoolFlexHolderTop"}
              placeHolder={
                getFlagrKeys["ReadinessFeature_Educator_Email_Loginname"]
                  ? "Search Educator Name, Username/Email, External ID"
                  : "Search Educator Name, Username, External ID"
              }
              value={searchText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e)}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                handleKeyPress(e);
              }}
              searchButtonClassName="searchButton"
              searchButtonContent={renderSearchButton()}
              onSearchClick={() => {
                handleSearchButtonClick();
              }}
              clearButtonClassName="clearButton"
              clearButtonContent={renderCancelButton()}
              onClearClick={() => {
                clearButtonClick();
              }}
            />
            {showTable && (getUserRoleId === 3 || getUserRoleId === 4) && (
              <div className="search-csv-export-container">
                <MpngExportReport
                  reportName={
                    "Educators_" +
                    getDaDetails.name.replace(/[.]/g, " ") +
                    "_" +
                    (new Date(`${new Date()}`).getUTCMonth() + 1) +
                    "_" +
                    new Date(`${new Date()}`).getUTCDate() +
                    "_" +
                    new Date(`${new Date()}`).getUTCFullYear() +
                    "_" +
                    Date.now()
                  }
                  includePDF={false}
                  csvCallback={getCSVData}
                />
              </div>
            )}
          </>
        )}
      </div>
      <div ref={elementRef}>
        {showTable && (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) ? (
          <UxMUITable
            MUITableClassName={data.values.length === 0 ? "emptyTable1" : "scrollableTable"}
            columnNames={columnNames}
            rowMapper={rowMapper}
            rowData={(data as IEducators).values}
            showColumnCheckbox={false}
            viewChild={viewEducator}
            emptyTableText={emptyTableText}
            checkboxRef="loginName"
            selected={selected}
            setSelected={setSelected}
            mtableHeight={tableHgt}
            showHelpIconFlag={true}
            showSortList={
              !getFlagrKeys["ReadinessFeature_Educator_Email_Loginname"]
                ? [true, true, true, true, false, true, true]
                : [true, true, true, false, true, true]
            }
            sortListMapper={{
              "Educator Name": [
                educatorNameBool,
                setEducatorNameBool,
                (args: string) => {
                  sortChange(args);
                }
              ],
              ...(getFlagrKeys["ReadinessFeature_Educator_Email_Loginname"]
                ? {
                    "Username/Email": [
                      usernameBool,
                      setUsernameBool,
                      (args: string) => {
                        sortChange(args);
                      }
                    ]
                  }
                : {
                    Username: [
                      usernameBool,
                      setUsernameBool,
                      (_args: string) => {
                        sortChange("Username/Email");
                      }
                    ]
                  }),
              Institution: [
                institutionBool,
                setInstitutionBool,
                (args: string) => {
                  sortChange(args);
                }
              ],
              ...(!getFlagrKeys["ReadinessFeature_Educator_Email_Loginname"] && {
                "Email Address": [
                  emailBool,
                  setEmailBool,
                  (args: string) => {
                    sortChange(args);
                  }
                ]
              }),
              "Educator Type": [
                roleBool,
                setRoleBool,
                (args: string) => {
                  sortChange(args);
                }
              ],
              Status: [
                statusBool,
                setStatusBool,
                (args: string) => {
                  sortChange(args);
                }
              ]
            }}
          />
        ) : (
          showMuiTable()
        )}
      </div>

      {showTable ? (
        <div className="fixedpagination">
          <UxPaginate
            data={data}
            page={pageNo}
            paginationSize={getPaginationControllerState[1].paginationCount}
            className={classes.root}
            handleChange={handleChange}
          />
          {data.size > 50 && (
            <UxPaginationController
              rowsList={paginationControllers}
              setRowsCount={getPaginationControllerState[1].paginationCount}
              callback={callback}
            />
          )}
        </div>
      ) : null}
    </div>
  );
}

export default connect(mapStateToProps)(EducatorList);
