import React, { useState, useEffect } from "react";
import { getUser, login, logout, signinCallback } from "../../authService/AuthService";
import { useNavigate } from "react-router-dom";
import {
  updateAssessmentDetails,
  updatePageTitle,
  updatePendoEducatorDetails,
  updateSiteCodeDetails,
  updateStudentsSubtab,
  updateTabName,
  updateUseId,
  updateUserGuId,
  updateFlagrKeys,
  updateDaDetails,
  updateUserRole,
  updateUserName,
  updateUserEmail,
  updateUserId,
  updateUserRoleId,
  updateUserFirstName,
  updateUserLastName,
  updateUserRoleDetails,
  updateShowComponent,
  updateLevel,
  updateCurrentUserDetails,
  updateCurrentOrganizationDetails
} from "../../Redux/Action";
import { connect } from "react-redux";
import { persistor } from "../../Redux/Store";
import "./authenticator.scss";
import moment from "moment";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import configMessages from "../../helpers/configMessages";
import { UserService } from "../../services/helpers/user.services";
import { AdminService } from "../../services/helpers/api.services";
import ReduxProps, { GetFlagrKeysProps, UserDetails } from "../../Redux/Redux.props";
import { RootComponentProps } from "../../root-component.props";
import { User } from "oidc-client";
import { AuthenticatorProps } from "./Authenticator.props";
import { flagrKeys } from "../../helpers/configs";
import { clearLocalStorage } from "../../helpers/sessionHelper";

interface Props extends ReduxProps, AuthenticatorProps, RootComponentProps {}

function mapStateToProps(state: ReduxProps) {
  return {
    getUserId: state.getUserId,
    getUseId: state.getUseId,
    getDaDetails: state.getDaDetails,
    getUserGuId: state.getUserGuId,
    getUserRoleId: state.getUserRoleId,
    getAcademicCalendar: state.getAcademicCalendar,
    getFlagrKeys: state.getFlagrKeys
  };
}
interface OrganizationDetail {
  institutionName: string;
  institutionId: number;
  institutionState: string;
  institutionTimeZone: string;
  institutionSiteId?: number;
  institutionSiteName?: string;
  pathToInstitution: string;
  institutionAlaType: string;
  institutionOrgType: string;
  parentInstitutionName: string;
  parentInstitutionId: number;
  parentInstitutionState: string;
  parentInstitutionOrgType: string;
  parentInstitutionAlaType: string;
  institutionGuid: string;
  parentInstitutionGuid: string;
}

interface InstitutionSettingsDetail {
  scheduledSISImports: boolean;
  singleSignOnSettings: boolean;
  spanishLanguageMath: boolean;
  spanishLanguageScience: boolean;
}

interface AcademicSessionsResponse {
  organizationAcademicSessionId: number;
  title: string;
  startDate: Date;
  endDate: Date;
  isCurrentAcademicYear: boolean;
  startDateFormat: string;
  endDateFormat: string;
  schoolYear: string;
  organizationId: number;
  createdDate: Date;
  updatedDate: Date;
  organizationAcademicSessionGuid: string;
}

function Authenticator(props: Props) {
  const navigate = useNavigate();
  const { dispatch, tokenSubject, getUseId, getUserGuId, subjectMFE, getDaDetails, getFlagrKeys } = props;
  const [showToast, setShowToast] = useState({ val: false, msg: "" });
  const [, setTeacherOrgGuid] = useState("");
  function Alert(alertprops: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...alertprops} />;
  }

  const alertStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242"
      }
    }
  }));
  const alertclasses = alertStyles();

  const fetchDetails = (userId: string, ids: string[], userRoleId: number) => {
    addCurrentUserOrganizationDetails(userId);

    if (getUserGuId !== parseInt(getUseId)) {
      dispatch(updateUseId(userId));
      try {
        UserService.pendoEducatorDetailsWithGuid(userId).then(
          ({ status, data }: { status: number; data: Response }) => {
            if (status === 200) {
              setEducatorDetailsData(data, userRoleId);
            } else {
              // do nothing
            }
          }
        );
      } catch (err) {
        //do nothing
      }
      try {
        let orgIds = ids;
        let OrgIds = { assessmentSettingsObj: orgIds };

        if (orgIds != null) {
          AdminService.assessmentSettingsdetailWithGuid(OrgIds).then(
            ({ status, data }: { status: number; data: Response }) => {
              if (status === 200) {
                AssessmentSetting(data);
              } else {
                dispatch(updateAssessmentDetails("null"));
              }
            }
          );
        }
      } catch (error) {}
    }
  };
  async function AssessmentSetting(responsedata: Response) {
    let newData = await responsedata.json();
    let Assessment = [];
    newData.map(
      (detail: {
        nweaEnabled: boolean;
        renaissanceEnabled: boolean;
        myPathMathEnabled: boolean;
        myPathELAEnabled: boolean;
      }) => {
        if (detail.nweaEnabled) {
          Assessment.push("NWEA");
        } else if (detail.renaissanceEnabled) {
          Assessment.push("Renaissance");
        } else if (detail.myPathMathEnabled) {
          Assessment.push("MyPathMath");
        } else if (detail.myPathELAEnabled) {
          Assessment.push("MyPathReading");
        } else {
          //do nothing
        }
      }
    );
    dispatch(updateAssessmentDetails(Assessment.length !== 0 ? Assessment.join(",") : "null"));
  }
  async function setEducatorDetailsData(responsedata: Response, userRoleId: number) {
    let institutionName = [];
    let institutionId = [];
    let institutionState = [];
    let pathToInstitution = [];
    let parentInstitutionId = [];
    let parentInstitutionName = [];
    let parentInstitutionState = [];
    let parentInstitutionOrgType = [];
    let parentInstitutionALAType = [];
    let institutionAlaType = [];
    let institutionOrgType = [];
    let institutionGUID = [];

    let scheduledSISImports = [];
    let singleSignOnSettings = [];
    let spanishLanguageMath = [];
    let spanishLanguageScience = [];

    let details = await responsedata.json();
    let siteDetails = details.siteOrganizationDetails;
    let orgDetails = details.organizationDetails;
    let institutionDetails = details.institutionSettingsDetails;
    let academicSessions = details.academicSessionsResponse;
    let pendoSessions = [];

    if (academicSessions !== null) {
      academicSessions.map((school: AcademicSessionsResponse[]) => {
        let schoolSession = [];
        school.forEach((sessions, index: number) => {
          school[index].startDateFormat = sessions.startDateFormat.replaceAll("/", "-");
          school[index].endDateFormat = sessions.endDateFormat.replaceAll("/", "-");
          schoolSession.push(
            `${sessions.title} startDate ${sessions.startDateFormat} endDate ${sessions.endDateFormat}`
          );
        });
        pendoSessions.push(schoolSession);
      });
    } else {
      //do nothing
    }

    if (institutionDetails !== null) {
      institutionDetails.map((setting: InstitutionSettingsDetail) => {
        scheduledSISImports.push(setting.scheduledSISImports ? "Enabled" : "Disabled");
        singleSignOnSettings.push(setting.singleSignOnSettings ? "Enabled" : "Disabled");
        spanishLanguageMath.push(setting.spanishLanguageMath ? "Enabled" : "Disabled");
        spanishLanguageScience.push(setting.spanishLanguageScience ? "Enabled" : "Disabled");
      });
    } else {
      //do nothing
    }

    if (orgDetails !== null) {
      details.organizationDetails.map((detail: OrganizationDetail) => {
        institutionGUID.push(detail.institutionGuid);
        institutionName.push(detail.institutionName);
        institutionId.push(detail.institutionId);
        institutionState.push(detail.institutionState);
        pathToInstitution.push(detail.pathToInstitution);
        parentInstitutionId.push(detail.parentInstitutionId);
        institutionAlaType.push(detail.institutionAlaType);
        institutionOrgType.push(detail.institutionOrgType);
        parentInstitutionName.push(detail.parentInstitutionName);
        parentInstitutionState.push(detail.parentInstitutionState);
        parentInstitutionOrgType.push(detail.parentInstitutionOrgType);
        parentInstitutionALAType.push(detail.parentInstitutionAlaType);
      });
    } else {
      //do nothing
    }
    dispatch(
      updatePendoEducatorDetails({
        userID: institutionId[0],
        institutionGuid: institutionGUID[0],
        roleName: details.roleName,
        firstName: details.firstName,
        lastName: details.lastName,
        email: details.email,
        userGuid: details.userGuid,
        activeClasses: details.activeClasses,
        parentInstitutionName: parentInstitutionName[0],
        parentInstitutionId: parentInstitutionId[0],
        parentInstitutionState: parentInstitutionState[0],
        parentInstitutionCategory: parentInstitutionOrgType[0],
        parentInstitutionALAType: parentInstitutionALAType[0],
        userinstitutionname: institutionName.join(),
        userInstitutionid: institutionId.join(),
        userInstitutionGUID: institutionGUID.join(),
        userInstituionALAType: institutionAlaType[0],
        userInstitutionState: institutionState.join(),
        institutionCategory: institutionOrgType.join(),
        subjectsLicensed: details.subjectsLicensed,
        productsLicensed: details.productsLicensed !== null ? details.productsLicensed : "none",
        userParentInstitutionName: pathToInstitution,
        userParentInstitutionId: parentInstitutionId.join(),
        InstitutionGuid: details.organizationDetails[0].parentInstitutionGuid,
        ScheduledSISImports: scheduledSISImports.join(),
        SingleSignOnSettings: singleSignOnSettings.join(),
        SpanishLanguageMathAvailability: spanishLanguageMath.join(),
        SpanishLanguageScienceAvailability: spanishLanguageScience.join(),
        academicSessions: pendoSessions[0].join()
      })
    );
    if (userRoleId > 2) {
      if (userRoleId !== 7) {
        dispatch(
          updateSiteCodeDetails({
            siteCode: siteDetails.siteCode,
            siteInstitutionName: siteDetails.siteInstitutionName,
            siteInstitutionId: siteDetails.siteInstitutionId,
            siteInstitutionState: siteDetails.siteInstitutionState,
            siteInstitutionAlaType: siteDetails.siteInstitutionAlaType,
            siteInstitutionCategory: siteDetails.siteInstitutionOrgType
          })
        );
      } else {
        //do nothing
      }
    }
  }

  async function addCurrentUserOrganizationDetails(userId: string) {
    try {
      const { status, data } = await UserService.getCurrentUserDetails(userId);
      if (status === 200) {
        const details: UserDetails = await data.json();
        dispatch(updateCurrentUserDetails(details));
        //If none or multiple orgs for the user do not set initial current org, current org will update during search and navigation
        if (details.organizations.length === 1) {
          dispatch(updateCurrentOrganizationDetails(details.organizations[0]));
        }
      }
    } catch (error) {
      console.error("Failed to fetch user details", error);
    }
  };

  async function gotResponse(responsedata: Response) {
    const detailsText = await responsedata.text();
    dispatch(updateUserRoleDetails(detailsText));
    const details = JSON.parse(detailsText);

    // If any students make it to mypath already authenticated, then redirect them to the PSS page
    // This should only happen if students use old urls that redirect from the ULP directly to mypath
    // Most students will be redirected to the PSS after logging in at the ULP
    if (details.roleID === 6) {
      await persistor.purge();
      clearLocalStorage();
      sessionStorage.clear();
      window.open(window.APP_ENV.pssBaseUrl, "_self");
    }

    try {
      const { status, data } = await AdminService.getFlagerStatus({
        entities: [
          {
            entityContext: {
              orgGuid: details.orgGuidList !== null ? details.orgGuidList[0] : "",
              userGuid: details.userGuid,
              siteCode: details.loginCode,
              userRole: details.userType,
              userName: details.loginName
            }
          }
        ],
        enableDebug: true,
        flagKeys: flagrKeys
      });
      if (status === 200) {
        handleFlagrResponse(data);
      }
    } catch (err) {
      //do nothing
    }

    subjectMFE.next({
      orgGuids: details.orgGuidList,
      userGuid: details.userGuid,
      siteCode: details.loginCode,
      userRole: details.userType,
      userName: details.loginName
    });
    let state = localStorage.getItem("oidc:state");
    if (state !== null) {
      localStorage.setItem("oidc:persistState", state);
    }
    localStorage.removeItem("oidc:session");
    localStorage.removeItem("oidc:state");
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).includes("oidc.")) {
        localStorage.removeItem(localStorage.key(i));
      }
    }
    //@ts-ignore
    const roleId = details.roleID;
    let wneRedirectUrl = "/customers";
    localStorage.setItem("ala:createStudent", "false");
    dispatch(updateUserId(details.userID));
    dispatch(updateUserGuId(details.userGuid));
    //@ts-ignore
    dispatch(updateUserRoleId(details.roleID));

    //@ts-ignore
    if (details.roleID !== 1 && details.roleID !== 2 && details.roleID !== 7 && !details.isOrganizationEnabled) {
      //@ts-ignore
      details.isEnabled = false;
    }

    // If mpp landing page is cpr then generate the dsds report token and store it in redux
    let orgId: string;
    let isCPREnabled: boolean = false;
    if (localStorage.getItem("ala:MppLaunch") === "true" && sessionStorage.getItem("ala:MPPLandingPage") === "cpr") {
      isCPREnabled = true;
      orgId = sessionStorage.getItem("ala:MPPorgId");
    }

    //@ts-ignore
    if (details.isEnabled) {
      if (roleId === 2) {
        dispatch(updateUserRole("Customer Support"));
        navigate(wneRedirectUrl);
        callShowComponent("", 1);
      } else if (roleId === 1) {
        dispatch(updateUserRole("WNE Admin"));
        navigate(wneRedirectUrl);
        callShowComponent("", 1);
      } else if (roleId === 7) {
        dispatch(updateUserRole("WNE HelpDesk"));
        navigate(wneRedirectUrl);
        callShowComponent("", 1);
      } else if (roleId === 3) {
        dispatch(updateUserRole("District Admin"));
        //@ts-ignore
        dispatch(updateDaDetails({ name: "", id: details.parentOrgGuid }));
        callShowComponent(details.parentOrgGuid, 1);
        //If this is direct launch from Product portal to open CPR Report, then redirect there, instead of default landing page, for District Admin
        if (isCPREnabled) {
          dispatch(updateLevel("district"));
          navigate(`/dsds-report-launch/${orgId}`);
        } else {
          //@ts-ignore
          navigate(`/dainstitutions/${details.parentOrgGuid}`);
        }
      } else if (roleId === 4) {
        dispatch(updateUserRole("School Admin"));
        //@ts-ignore
        if (details.orgGuidList.length > 1) {
          //@ts-ignore
          dispatch(
            updateDaDetails({
              name: "",
              id: details.orgGuidList.join("_"),
              type: 2
            })
          );
          callShowComponent(details.orgGuidList.join("_"), 2);
          //If this is direct launch from Product portal to open CPR Report, then redirect there, instead of default landing page, for School Admin
          if (isCPREnabled) {
            dispatch(updateLevel("school"));
            navigate(`/dsds-report-launch/${orgId}`);
          } else {
            //@ts-ignore
            navigate(`/saschools/${details.orgGuidList.join("_")}`);
          }
          setTeacherOrgGuid(details.orgGuidList[0]);
        } else {
          //@ts-ignore
          dispatch(updateDaDetails({ name: "", id: details.orgGuidList[0], type: 1 }));
          callShowComponent(details.orgGuidList.join("_"), 1);
          //If this is direct launch from Product portal to open CPR Report, then redirect there, instead of default landing page, for School Admin
          if (isCPREnabled) {
            dispatch(updateLevel("school"));
            navigate(`/dsds-report-launch/${orgId}`);
          } else {
            //@ts-ignore
            navigate(`/saclasses/${details.orgGuidList[0]}`);
          }
          setTeacherOrgGuid(details.orgGuidList[0]);
        }
      } else if (roleId === 5) {
        dispatch(updateUserRole("Teacher"));
        //@ts-ignore
        if (details.orgGuidList.length > 1) {
          //@ts-ignore
          dispatch(
            updateDaDetails({
              name: "",
              id: details.orgGuidList.join("_"),
              type: 2
            })
          );
          callShowComponent(details.orgGuidList.join("_"), 2);
          //@ts-ignore
          navigate(`/teacherclass/${details.orgGuidList.join("_")}`);
          setTeacherOrgGuid(details.orgGuidList[0]);
        } else {
          //@ts-ignore
          dispatch(updateDaDetails({ name: "", id: details.orgGuidList[0], type: 1 }));
          callShowComponent(details.orgGuidList.join("_"), 1);
          //@ts-ignore
          navigate(`/teacherclasses/${details.orgGuidList[0]}`);
          setTeacherOrgGuid(details.orgGuidList[0]);
        }
      }
      //@ts-ignore
      dispatch(updateUserName(details.loginName));
      //@ts-ignore
      dispatch(updateUserEmail(details.email));
      //@ts-ignore
      dispatch(updateUserId(details.userID));
      dispatch(updateUserGuId(details.userGuid));
      //@ts-ignore
      dispatch(updateUserFirstName(details.firstName));
      //@ts-ignore
      dispatch(updateUserLastName(details.lastName));
      fetchDetails(details.userGuid, details.orgGuidList, details.roleID);
    } else {
      if (details.roleID === 1 || details.roleID === 2 || details.roleID === 7) {
        setShowToast({
          val: true,
          msg: configMessages.WNEUserAccountDisabled
        });
      } else {
        //@ts-ignore
        if (!details.isEnabled || !details.isOrganizationEnabled) {
          setShowToast({ val: true, msg: configMessages.accountDisabled });
        } else {
          setShowToast({
            val: true,
            msg: configMessages.organizationDisabled
          });
        }
      }
    }
  }

  useEffect(() => {
    dispatch(updatePageTitle("- Authenticator"));
    getUser()
      .then(async (user: User) => {
        const useragent = navigator.userAgent;
        const eventURL = window.location.origin;
        const clientDateTime = moment().format();
        if (user) {
          tokenSubject.next(user.access_token);
          const apiObj = {
            Username: user.profile.username,
            ClientDateTime: clientDateTime,
            JWTToken: user.access_token,
            UserAgent: useragent,
            EventSourceURL: eventURL,
            loginCode: localStorage.getItem("ala:loginCode")
          };
          try {
            let authenticateStudent =
              localStorage.getItem("ala:redirectedFrom") === "wneuser" ? "authenticate" : "authenticateuser";
            await fetch(`${window.APP_ENV.userApiUrl}/api/${authenticateStudent}`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.access_token}`
              },
              body: JSON.stringify(apiObj)
            }).then(async (response: Response) => {
              if (response.status === 200) {
                gotResponse(response);
              } else {
                setShowToast({
                  val: true,
                  msg: configMessages.AuthenticatorErrorMessage
                });
              }
            });
          } catch (error) {
            //
          }
        } else {
          signinCallback()
            .then(async (users: User) => {
              if (users) {
                tokenSubject.next(users.access_token);
                const apiObj = {
                  Username: users.profile.username,
                  ClientDateTime: clientDateTime,
                  JWTToken: users.access_token,
                  UserAgent: useragent,
                  EventSourceURL: eventURL,
                  loginCode: localStorage.getItem("ala:loginCode")
                };
                try {
                  let authenticateStudent =
                    localStorage.getItem("ala:redirectedFrom") === "wneuser" ? "authenticate" : "authenticateuser";

                  await fetch(`${window.APP_ENV.userApiUrl}/api/${authenticateStudent}`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${users.access_token}`
                    },
                    body: JSON.stringify(apiObj)
                  }).then((response: Response) => {
                    if (response.status === 200) {
                      gotResponse(response);
                    } else {
                      setShowToast({
                        val: true,
                        msg: configMessages.AuthenticatorErrorMessage
                      });
                    }
                  });
                } catch (error) {
                  // do nothing
                }
              } else {
                // do nothing
              }
            })
            .catch((error) => {
              if (error.message === "No matching state found in storage") {
                localStorage.removeItem("oidc:state");
                localStorage.removeItem("oidc:session");
                localStorage.removeItem("page");
                login();
              }
            });
        }
      })
      .catch((error) => {
        if (error.message === "No matching state found in storage") {
          localStorage.removeItem("oidc:state");
          localStorage.removeItem("oidc:session");
          localStorage.removeItem("page");
          login();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, dispatch]);

  async function handleFlagrResponse(response: Response) {
    const details = await response.json();
    const flagrStatusByFlagKey: Partial<GetFlagrKeysProps> = {};

    for (const flag of details.evaluationResults) {
      if (!flag.variantKey || flag.variantKey.toLowerCase() === "off") {
        flagrStatusByFlagKey[flag.flagKey] = false;
        continue;
      }

      // Special snowflake calculations
      if (flag.flagKey === "StandardsProficiencyReport-Access") {
        try {
          const flagValues = flag.variantAttachment.values;
          flagrStatusByFlagKey[flag.flagKey] = {
            on: true,
            flagValues
          };
        } catch (error) {
          // If we forgot to change this block and yanked out the variant attachment
          // or somehow this fails catastrophically due to data shapes... don't break
          // anything else, just turn the flag on. We know the flag value is "on", otherwise
          // we wouldn't have come this far.
          flagrStatusByFlagKey[flag.flagKey] = {
            on: true
          };
        }

        continue;
      }

      flagrStatusByFlagKey[flag.flagKey] = true;
    }

    dispatch(updateFlagrKeys(flagrStatusByFlagKey));
  }

  function callShowComponent(daDetailsID: string, daDetailsType: number) {
    let componentGuids = window.APP_ENV.REACT_APP_GUIDS.split(",");
    let guid = daDetailsType === 2 ? daDetailsID.split("_")[0] : daDetailsID;
    if (componentGuids.filter((x) => x.toString() === guid.toString()).length === 1) {
      dispatch(updateShowComponent(true));
    } else {
      dispatch(updateShowComponent(false));
    }
  }

  return (
    <>
      <p
        style={{ position: "absolute", opacity: "0" }}
        role="status"
        aria-label="Authenticating. After successful authentication, you will be directed to your home page."
      >
        " "
      </p>
      <div className="signinDialog">
        <div className="signinHolder">
          <div className="spinner-border text-light loadingSpinner" role="status" />
          <span style={{ fontSize: "20px", fontFamily: "Roboto", color: "#FFFFFF" }}>Authenticating</span>
          <div
            role="alert"
            aria-label={"Authenticating. After successful authentication, you will be directed to your home page."}
          ></div>
        </div>
        <div className={alertclasses.root}>
          <Snackbar
            open={showToast.val}
            autoHideDuration={5000}
            onClose={() => {
              setShowToast({ val: false, msg: "" });
              localStorage.removeItem("lms:isRenew");
              localStorage.removeItem("persist:growth-reports");
              localStorage.removeItem("persist:mypath-reports");
              localStorage.removeItem("oidc:session");
              localStorage.removeItem("page");
              localStorage.removeItem("oidc:state");
              for (let i = 0; i < localStorage.length; i++) {
                if (localStorage.key(i).includes("oidc.")) {
                  localStorage.removeItem(localStorage.key(i));
                }
              }
              //@ts-ignore
              logout();
            }}
          >
            <Alert
              severity="warning"
              style={{ backgroundColor: "#f4473c", color: "#fff" }}
              onClose={() => {
                setShowToast({ val: false, msg: "" });
                localStorage.removeItem("lms:isRenew");
                localStorage.removeItem("persist:growth-reports");
                localStorage.removeItem("persist:mypath-reports");
                localStorage.removeItem("oidc:session");
                localStorage.removeItem("page");
                localStorage.removeItem("oidc:state");
                for (let i = 0; i < localStorage.length; i++) {
                  if (localStorage.key(i).includes("oidc.")) {
                    localStorage.removeItem(localStorage.key(i));
                  }
                }
                //@ts-ignore
                logout();
              }}
            >
              {showToast.msg}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </>
  );
}

export default connect(mapStateToProps)(Authenticator);
