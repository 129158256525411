import React, { useEffect, useState, useRef } from "react";

import { connect } from "react-redux";
import {
  UxTabs,
  UxMUITable,
  UxSearchInput,
  UxIcon,
  UxIconEnum,
  UxSizeEnum,
  TimesIcon,
  UxPaginate,
  UxPaginationController
} from "@wne-spa/ux-component-library";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import StudentsTab from "../Students/StudentTab";
import Educators from "../EducatorsList/EducatorsList";
import Settings from "../Settings/Settings";
import Licensing from "../Licensing/Licensing";
import {
  updateActivityIndicator,
  updateBreadCrumbs,
  updatePageTitle,
  updateTabChange,
  updatePaginationControllerState,
  updateTabName,
  updateDistrictLevelFirstTab,
  updateAssessmentTab,
  updateFirstTab,
  updatePreviousURL,
  updateFromSideBarAssessment,
  updateReportCard,
  updateSchoolGuids,
  updateLevel,
  updateStudentIds,
  updateStudentsSubtab,
  updateStudentAndClassFilterURL,
  updateOrgLoginCodeAvailable,
  updateIsOrganizationActive,
  updateIsOrgRegistrationOwned,
  updateCurrentOrganizationDetails
} from "../../Redux/Action";
import { paginationControllers } from "../../helpers/configs";
import "./institutions.scss";
import CustomerModal from "../Customer/OrgStatus";
import configMessages from "../../helpers/configMessages";
import { UserService } from "../../services/helpers/user.services";
import { AdminService } from "../../services/helpers/api.services";
import AssessmentCards from "../ViewCustomer/AssessmentCards";
import ReportsTab from "../reports/ReportsTab";
import GetToken from "../../authService/GetToken";
import Sessions from "../AcademicSessions/AcademicSessions";
import ReduxProps, { SchoolGuidsResponseProps, OrganizationDetails } from "../../Redux/Redux.props";
import { InstitutionProps, UsersProps, User } from "./Institution.props";
import { getIsOrgGuidPresent, hiddenTabsForOrgGuidList } from "../../helpers/orgGuidList";
import StudentDropdownTab from "../HeaderTabs/StudentDropdownTab";
import AssessmentDropdownTab from "../HeaderTabs/AssessmentDropdownTab";

function mapStateToProps(state: ReduxProps) {
  return {
    getActivityIndicator: state.getActivityIndicator,
    getBreadCrumbsData: state.getBreadCrumbsData,
    getUserId: state.getUserId,
    getTabChange: state.getTabChange,
    getCustomerListSort: state.getCustomerListSort,
    getPaginationControllerState: state.getPaginationControllerState,
    getFlagrKeys: state.getFlagrKeys,
    getTabName: state.getTabName,
    getStudentSubtab: state.getStudentSubtab,
    getUserRoleId: state.getUserRoleId,
    getClassFilter: state.getClassFilter,
    getSchoolGuids: state.getSchoolGuids,
    getOrgLoginCodeAvailable: state.getOrgLoginCodeAvailable,
    getIsInherited: state.getIsInherited,
    getChildDepth: state.getChildDepth,
    getIsMyPathOnly: state.getIsMyPathOnly
  };
}
interface Props extends ReduxProps, InstitutionProps { }

function Institutions(props: Props): JSX.Element {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const {
    getBreadCrumbsData,
    getUserId,
    getTabChange,
    getCustomerListSort,
    getPaginationControllerState,
    getTabName,
    getStudentSubtab,
    getUserRoleId,
    schedulerAssessmentSubject,
    MyPathLevelSubject,
    getSchoolGuids,
    BenchmarkGrowthReportBreadcrumbSubject,
    ActivityIndicatorSubject,
    getOrgLoginCodeAvailable,
    getIsInherited,
    getChildDepth,
    getFlagrKeys
  } = props;

  const navigate = useNavigate();
  const [tabKey, setTabKey] = useState("schools");
  const [tabName, setTabName] = useState("Schools");
  const [loading, setLoading] = useState(true);

  const [selectedStudentView, setSelectedStudentView] = useState("home");

  const { dispatch } = props;
  const [data, setData] = useState({
    page: 0,
    size: 0,
    pageSize: 0,
    values: []
  });
  const rowMapper = [["name", "rootPath"], "state", "orgType", "id", "isActive"];
  const [columnName, setcolumnName] = useState(["Institutions Name", "State", "Category", "Institution ID", "Status"]);
  const [emptyTableText, setEmptyTableText] = useState("");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [custBool, setCustBool] = useState<boolean | null>(null);
  const [categoryBool, setCategoryBool] = useState<boolean | null>(null);
  const [institutionIdBool, setInstitutionIdBool] = useState<boolean | null>(null);
  const [statusBool, setStatusBool] = useState<boolean | null>(null);
  const [stateBool, setStateBool] = useState<boolean | null>(null);
  const [searchText, setSearchText] = useState("");
  const [pageNum, setpageNum] = useState(1);
  const sortBy = useRef(getCustomerListSort.name);
  const sortOrder = useRef(getCustomerListSort.val);
  const searchResultRef = useRef(false);
  const textMessage = useRef("none");
  const [, setCid] = useState(0);
  const [cguid, setCGuid] = useState("");
  const [status, setStatus] = useState(false);
  const [index, setIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [customerData, setCustomerData] = useState<User>();
  const [showToast, setShowToast] = useState({ val: false, msg: "" });
  const elementRef = useRef(null);
  const [tableHgt, setTableHgt] = useState(0);

  async function callback(args: number) {
    let updateState = getPaginationControllerState;
    updateState[6] = {
      paginationPageName: "Institutions",
      paginationCount: args
    };
    setpageNum(1);
    dispatch(updatePaginationControllerState(updateState));
    try {
      dispatch(updateActivityIndicator(true));
      UserService.updatePaginationCount(getUserId, 7, args).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            searchSchools(1, searchText);
          } else {
            dispatch(updateActivityIndicator(false));
          }
        }
      );
    } catch (err) {
      dispatch(updateActivityIndicator(false));
    }
  }

  useEffect(() => {
    setTabKey(hiddenTabsForOrgGuidList.includes(tabKey) && getIsOrgGuidPresent(id) ? "schools" : tabKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    dispatch(updateActivityIndicator(true));
    dispatch(updateFromSideBarAssessment(false));
    setTabKey(getTabName || "schools");
    if (getTabName === "reports") {
      isAssessmentTabClicked(getTabName);
    }
    setSelectedStudentView(getStudentSubtab);
    if (getTabName != "reports") {
      dispatch(updateTabName(null));
    }
    dispatch(updateLevel("district"));
    dispatch(updateStudentAndClassFilterURL(window.location.pathname));
  }, []);

  useEffect(() => {
    getOrganizationGuidDetails();
  }, [id]);

  const getOrganizationGuidDetails = () => {
    let apiObj = { guid: id.toString() };
    AdminService.getGUIDSWithGuid(apiObj).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        responseGUIDS(data);
      } else {
        // do nothing
      }
    });
  };

  useEffect(() => {
    let orgids = { guid: id.toString() };
    AdminService.getLogincodeWithGuid(orgids).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        gotLogincodeResponse(data);
      } else {
        // do nothing
      }
    });
  }, []);

  async function gotLogincodeResponse(responsedata: Response) {
    let details = await responsedata.json();
    if (details[0].loginCode === "") {
      dispatch(updateOrgLoginCodeAvailable(false));
    } else {
      dispatch(updateOrgLoginCodeAvailable(true));
    }
  }

  async function responseGUIDS(responsedata: Response) {
    let allData = JSON.parse(await responsedata.text());
    schedulerAssessmentSubject.next({
      token: GetToken(),
      guid: allData[0].orgGUID
    });
    dispatch(updateIsOrganizationActive(allData[0].isActive));
    dispatch(updateIsOrgRegistrationOwned(allData[0]?.isRegistrationOwned));
  }

  if (tabKey === "schools") {
    localStorage.setItem("page", tabName);
  } else {
    localStorage.setItem("page", tabKey);
  }

  useEffect(() => {
    dispatch(updateTabChange(!getTabChange));

    // eslint-disable-next-line
  }, [tabKey]);
  useEffect(() => {
    if (elementRef.current !== null) {
      if (window.innerHeight > 650) {
        //@ts-ignore
        setTableHgt(
          //@ts-ignore
          parseInt(window.innerHeight - (elementRef.current.offsetTop + 75))
        );
      } else {
        //@ts-ignore
        setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
      }
    }
  }, [getTabChange]);

  useEffect(() => {
    const handleResize = () => {
      if (elementRef.current !== null) {
        if (window.innerHeight > 650) {
          //@ts-ignore
          setTableHgt(
            //@ts-ignore
            parseInt(window.innerHeight - (elementRef.current.offsetTop + 75))
          );
        } else {
          //@ts-ignore
          setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
        }
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => {
    dispatch(updateStudentIds([]));
    dispatch(updateStudentsSubtab("home"));
  }, []);
  useEffect(() => {
    if (!loading) {
      if (elementRef.current !== null) {
        if (window.innerHeight > 650) {
          //@ts-ignore
          setTableHgt(
            //@ts-ignore
            parseInt(window.innerHeight - (elementRef.current.offsetTop + 75))
          );
        } else {
          //@ts-ignore
          setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
        }
      }
    }
  }, [loading]);

  const customerListSortFunc = () => {
    if (getCustomerListSort.val === "DESC" || getCustomerListSort.val === "desc") {
      switch (getCustomerListSort.name) {
        case "organizationtitle":
          setCustBool(true);
          break;
        case "state":
          setStateBool(true);
          break;
        case "Category":
          setCategoryBool(true);
          break;
        case "institutionId":
          setInstitutionIdBool(true);
          break;
        default:
          setStatusBool(true);
      }
    } else if (getCustomerListSort.val === "ASC" || getCustomerListSort.val === "asc") {
      switch (getCustomerListSort.name) {
        case "organizationtitle":
          setCustBool(false);
          break;
        case "state":
          setStateBool(false);
          break;
        case "Category":
          setCategoryBool(false);
          break;
        case "institutionId":
          setInstitutionIdBool(false);
          break;
        default:
          setStatusBool(false);
      }
    }
  };

  useEffect(() => {
    customerListSortFunc();
    const fetchData = async () => {
      try {
        setTabName("Schools");
        dispatch(updateDistrictLevelFirstTab("Schools"));
        dispatch(updateFirstTab("Schools"));
        setLoading(true);
        setSearchText("");
        const jsonObj = {
          Page: 1,
          PageSize: getPaginationControllerState[6].paginationCount,
          OrgGuid: id,
          SearchText: "",
          FilterBy: 1,
          SortBy: sortBy.current,
          SortOrder: sortOrder.current
        };
        AdminService.searchChildOrganizationsWithGuid(jsonObj).then(
          ({ status, data }: { status: number; data: Response }) => {
            if (status === 200) {
              gotResponse(data, false);
            } else {
              // do nothing
            }
            dispatch(updateActivityIndicator(false));
          }
        );
      } catch (err) {
        //do nothing
      }
    };
    const fetchBreadCrumbs = async () => {
      try {
        let apiObj = { guid: id };
        await AdminService.getParentsWithGuid(apiObj).then(({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            setBreadCrumbs(data);
          } else {
            dispatch(updateActivityIndicator(false));
          }
        });
      } catch (err) {
        //do nothing
      }
    };

    const fetchOrgDetails = async () => {
      try {
        const { status, data } = await AdminService.getCurrentOrganizationDetails(id);
        if (status === 200) {
          const details: OrganizationDetails = await data.json();
          dispatch(updateCurrentOrganizationDetails(details));
        }
      } catch (err) {
        console.error("Failed to fetch organization details", err);
      }
    };

    dispatch(updateActivityIndicator(true));
    fetchBreadCrumbs();
    fetchData();
    fetchOrgDetails();
    // eslint-disable-next-line
  }, [dispatch, id]);

  const getSearchChildOrganizationsWithGuid = () => {
    const jsonObj = {
      Page: 1,
      PageSize: getPaginationControllerState[6].paginationCount,
      OrgGuid: id,
      SearchText: "",
      FilterBy: 1,
      SortBy: sortBy.current,
      SortOrder: sortOrder.current
    };
    AdminService.searchChildOrganizationsWithGuid(jsonObj).then(
      ({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          gotResponse(data, false);
        } else {
          // do nothing
        }
        dispatch(updateActivityIndicator(false));
      }
    );
  };

  async function setBreadCrumbs(responsedata: Response) {
    let details = JSON.parse(await responsedata.text());

    let exist = getSchoolGuids.filter((item: SchoolGuidsResponseProps) => item.id === details.id);
    if (exist.length === 0) {
      let updatedSchoolGuids: SchoolGuidsResponseProps[] = [];
      updatedSchoolGuids = [...updatedSchoolGuids, details];
      dispatch(updateSchoolGuids(updatedSchoolGuids));
    }

    //@ts-ignore
    if (details.parents === null) {
      //@ts-ignore
      const breadcrumb = {
        crumbs: [
          { path: "/customers", name: "Customers" },
          //@ts-ignore
          { path: `/institutions/${id}`, name: details.name }
        ]
      };
      dispatch(updateBreadCrumbs(breadcrumb));
    } else {
      let breadcrumb = { crumbs: [{ path: "/customers", name: "Customers" }] };
      //@ts-ignore
      const crumbs = details.parents.reverse();
      for (let x of crumbs) {
        //@ts-ignore
        breadcrumb.crumbs.push({
          path: `/institutions/${x.orgGUID}`,
          name: x.name
        });
      }
      //@ts-ignore
      breadcrumb.crumbs.push({
        path: `/school/${details.orgGUID}`,
        name: details.name
      });
      dispatch(updateBreadCrumbs(breadcrumb));
    }
    //@ts-ignore
    dispatch(updatePageTitle(`- Customers - ${details.name}`));
  }
  const sortByFunc = () => {
    switch (sortBy.current) {
      case "organizationtitle":
        setStatusBool(null);
        setCategoryBool(null);
        setInstitutionIdBool(null);
        setStateBool(null);
        break;
      case "Category":
        setCustBool(null);
        setStatusBool(null);
        setInstitutionIdBool(null);
        setStateBool(null);
        break;
      case "Id":
        setCustBool(null);
        setStatusBool(null);
        setCategoryBool(null);
        setStateBool(null);
        break;
      case "state":
        setCustBool(null);
        setStatusBool(null);
        setCategoryBool(null);
        setInstitutionIdBool(null);
        break;
      default:
        setCustBool(null);
        setCategoryBool(null);
        setInstitutionIdBool(null);
        setStateBool(null);
    }
  };

  async function gotResponse(responsedata: Response, from: boolean) {
    let allData = JSON.parse(await responsedata.text());
    sortByFunc();
    if (allData.size === 0) {
      setEmptyTableText("No Records found");
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });
    } else {
      setData(allData);

      for (const x of allData.values) {
        if (x.childDepth > 0) {
          setTabName("Institutions");
          dispatch(updateDistrictLevelFirstTab("Institutions"));
          dispatch(updateFirstTab("Institutions"));
          localStorage.setItem("page", "Institutions");

          setcolumnName(["Institutions Name", "State", "Category", "Institution ID", "Status"]);
          break;
        }
      }

      setLoading(false);
      if (elementRef.current !== null) {
        if (window.innerHeight > 650) {
          //@ts-ignore
          setTableHgt(
            //@ts-ignore
            parseInt(window.innerHeight - (elementRef.current.offsetTop + 75))
          );
        } else {
          //@ts-ignore
          setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
        }
      }
    }
    if (from) {
      dispatch(updateActivityIndicator(false));
    }
  }
  async function getProductionSchoolName(responsedata: Response, row: SchoolGuidsResponseProps) {
    let details = JSON.parse(await responsedata.text());
    let exist = getSchoolGuids.filter((item: SchoolGuidsResponseProps) => item.id === row.id);
    if (exist.length === 0) {
      let updatedSchoolGuids: SchoolGuidsResponseProps[] = [];
      updatedSchoolGuids = [...updatedSchoolGuids, row];

      dispatch(updateSchoolGuids(updatedSchoolGuids));
    }
    if (row.childDepth === 0) {
      dispatch(updateTabName(null));
      let breadcrumb = getBreadCrumbsData.crumbs;
      breadcrumb.push({
        path: `/school/${row.organizationGuid}`,
        name: details.name
      });
      dispatch(updateBreadCrumbs({ crumbs: breadcrumb }));
      navigate(`/school/${row.organizationGuid}`);
    } else {
      let breadcrumb = getBreadCrumbsData.crumbs;
      breadcrumb.push({
        path: `/institutions/${row.organizationGuid}`,
        name: details.name
      });
      dispatch(updateBreadCrumbs({ crumbs: breadcrumb }));
      navigate(`/institutions/${row.organizationGuid}`);
    }
  }

  const handleClick = async (row: SchoolGuidsResponseProps) => {
    dispatch(updateActivityIndicator(true));
    try {
      let apiObj = { guid: row.organizationGuid };
      await AdminService.getParentsWithGuid(apiObj).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          getProductionSchoolName(data, row);
        } else {
          dispatch(updateActivityIndicator(false));
        }
      });
    } catch (err) {
      //do nothing
    }
  };

  const Styles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        "& > *": {
          marginTop: theme.spacing(2)
        }
      }
    })
  );
  let classes = Styles();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setpageNum(value);
    searchSchools(value, searchText);
  };

  function sortChange(type: string) {
    switch (type) {
      case "Institutions Name":
        sortOrder.current = !custBool ? "DESC" : "ASC";
        sortBy.current = "organizationtitle";
        break;
      case "State":
        sortOrder.current = !stateBool ? "DESC" : "ASC";
        sortBy.current = "state";
        break;
      case "Category":
        sortOrder.current = !categoryBool ? "DESC" : "ASC";
        sortBy.current = "OrgType";
        break;
      case "Institution ID":
        sortOrder.current = !institutionIdBool ? "DESC" : "ASC";
        sortBy.current = "Id";
        break;
      default:
        sortOrder.current = !statusBool ? "DESC" : "ASC";
        sortBy.current = "isactive";
    }
    searchSchools(1, searchText);
  }

  async function searchSchools(pageNo: number, stext: string) {
    setData({
      page: 0,
      size: 0,
      pageSize: 0,
      values: []
    });
    try {
      dispatch(updateActivityIndicator(true));
      const jsonObj = {
        Page: pageNo,
        PageSize: getPaginationControllerState[6].paginationCount,
        OrgGuid: id,
        SearchText: stext,
        FilterBy: 1,
        SortBy: sortBy.current,
        SortOrder: sortOrder.current
      };

      AdminService.searchChildOrganizationsWithGuid(jsonObj).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            gotResponse(data, true);
          } else {
            dispatch(updateActivityIndicator(false));
            setEmptyTableText("No Records found");
            setData({
              page: 0,
              size: 0,
              pageSize: 0,
              values: []
            });
          }
        }
      );
    } catch (err) {
      dispatch(updateActivityIndicator(false));
      setEmptyTableText("No Records found");
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });
      //do nothing
    }
  }

  function handleSearchTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.charAt(0) !== " ") {
      setSearchText(event.target.value);
    }
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key === "Enter" && searchText !== "") {
      searchResultRef.current = true;
      textMessage.current = "Search";
      setpageNum(1);
      searchSchools(1, searchText);
    }
  }

  function renderSearchButton() {
    return <UxIcon icon={UxIconEnum.SEARCH} size={UxSizeEnum.MEDIUM} className="searchIcon" />;
  }

  function handleSearchButtonClick(): void {
    if (searchText !== "") {
      searchResultRef.current = true;
      textMessage.current = "Search";
      searchSchools(1, searchText);
      setpageNum(1);
    }
  }

  function renderCancelButton() {
    if (searchText !== "" && !searchResultRef.current) {
      return <img src={TimesIcon} alt="" width="20px" height="20px" />;
    } else if (searchText !== "" && searchResultRef.current) {
      return (
        <div className="searchIconText">
          <span> Clear Results </span>
        </div>
      );
    }
  }

  function clearButtonClick() {
    if (searchText !== "" && !searchResultRef.current) {
      setSearchText("");
    } else if (searchText !== "" && searchResultRef.current) {
      setSearchText("");
      searchResultRef.current = false;
      setSearchText("");
      searchSchools(1, "");
      sortOrder.current = "ASC";
      sortBy.current = "organizationtitle";
      setCustBool(false);
      setStateBool(false);
      setCategoryBool(false);
      setpageNum(1);
      setEmptyTableText("");
    }
  }

  function restoreDefaultCheckState() {
    setSelected([]);
  }

  const kebabClick = (row: User, rowindex: number) => {
    setCid(row.id);
    setCGuid(row.organizationGuid);
    setStatus(row.isActive);
    setCustomerData(row);
    setIndex(rowindex);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const updateCustomerStatus = () => {
    setShowModal(false);
    updateCustomer();
  };

  const customerDeactivateMsg = () => {
    if (customerData.parent === 0 && customerData.childDepth >= 1) {
      return configMessages.districtLevelDeactivateMsg;
    } else if (customerData.parent !== 0 && customerData.childDepth >= 1) {
      return configMessages.institutionLevelDeactivateMsg;
    } else if (customerData.childDepth === 0) {
      return configMessages.schoolLevelDeactivateMsg;
    } else {
      return "";
    }
  };

  const customerActivateMsg = () => {
    if (customerData.parent === 0 && customerData.childDepth >= 1) {
      return configMessages.districtLevelActivateMsg;
    } else if (customerData.parent !== 0 && customerData.childDepth >= 1) {
      return configMessages.institutionLevelActivateMsg;
    } else if (customerData.childDepth === 0) {
      return configMessages.schoolLevelActivateMsg;
    } else {
      return "";
    }
  };

  async function updateCustomer() {
    dispatch(updateActivityIndicator(true));
    try {
      let urltype;
      if (status) {
        urltype = `deactivate`;
      } else {
        urltype = `activate`;
      }
      if (urltype === "activate") {
        let cguids = { guid: cguid };
        await AdminService.activateCustomerWithGuid(cguids).then(({ status }: { status: number; data: Response }) => {
          if (status === 200) {
            getSearchChildOrganizationsWithGuid();
            getOrganizationGuidDetails();
            let msg;
            if ((data as UsersProps).values[index].isActive) {
              msg = customerDeactivateMsg();
            } else {
              msg = customerActivateMsg();
            }
            (data as UsersProps).values[index].isActive = !(data as UsersProps).values[index].isActive;
            setData({
              page: 0,
              size: 0,
              pageSize: 0,
              values: []
            });
            setShowToast({ val: true, msg });
          } else {
            dispatch(updateActivityIndicator(false));
          }
        });
      } else {
        deactivateCustomerApiCall();
      }
    } catch (err) {
      dispatch(updateActivityIndicator(false));
      //do nothing
    }
  }

  const deactivateCustomerApiCall = () => {
    let cGuids = { guid: cguid };
    AdminService.deactivateCustomerWithGuid(cGuids).then(({ status }: { status: number; data: Response }) => {
      if (status === 200) {
        getSearchChildOrganizationsWithGuid();
        getOrganizationGuidDetails();
        let msg;
        if ((data as UsersProps).values[index].isActive) {
          msg = customerDeactivateMsg();
        } else {
          msg = customerActivateMsg();
        }
        (data as UsersProps).values[index].isActive = !(data as UsersProps).values[index].isActive;
        setData({
          page: 0,
          size: 0,
          pageSize: 0,
          values: []
        });
        setShowToast({ val: true, msg });
      } else {
        dispatch(updateActivityIndicator(false));
      }
    });
  };

  function Alert(alertprops: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...alertprops} />;
  }

  const alertStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242"
      }
    }
  }));
  const alertclasses = alertStyles();

  function tableList() {
    return (
      <>
        <div>
          <div className="institutionActionInputHolder">
            <UxSearchInput
              flexClassName="eFlexHolder"
              inputClassName="eInputText"
              placeHolder="Search School or District"
              value={searchText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchTextChange(e)}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                handleKeyPress(e);
              }}
              searchButtonClassName="searchButton"
              searchButtonContent={renderSearchButton()}
              onSearchClick={() => {
                handleSearchButtonClick();
              }}
              clearButtonClassName="clearButton"
              clearButtonContent={renderCancelButton()}
              onClearClick={() => {
                clearButtonClick();
              }}
              restoreCheckBoxState={() => {
                restoreDefaultCheckState();
              }}
            />
          </div>
          <div ref={elementRef}>
            <UxMUITable
              MUITableClassName="bg-white institutionSchoolTable"
              columnNames={columnName}
              rowMapper={rowMapper}
              rowData={data.values}
              showColumnCheckbox={false}
              viewChild={handleClick}
              emptyTableText={emptyTableText}
              checkboxRef="loginName"
              selected={selected}
              setSelected={setSelected}
              showKebabMenu={getFlagrKeys["ReadinessFeature_Organizations"] ? false : true}
              showNameKebabMenu={false}
              KebabMenuOption={["Deactivate", "Activate"]}
              kebabAction={kebabClick}
              showToolTip={true}
              mtableHeight={tableHgt}
              showSortList={[true, true, true, true]}
              sortListMapper={{
                "Institutions Name": [
                  custBool,
                  setCustBool,
                  (args: string) => {
                    sortChange(args);
                  }
                ],
                State: [
                  stateBool,
                  setStateBool,
                  (args: string) => {
                    sortChange(args);
                  }
                ],
                Category: [
                  categoryBool,
                  setCategoryBool,
                  (args: string) => {
                    sortChange(args);
                  }
                ],
                "Institution ID": [
                  institutionIdBool,
                  setInstitutionIdBool,
                  (args: string) => {
                    sortChange(args);
                  }
                ],
                Status: [
                  statusBool,
                  setStatusBool,
                  (args: string) => {
                    sortChange(args);
                  }
                ]
              }}
            />
          </div>
        </div>
      </>
    );
  }

  const setPage = (pageName: string) => {
    dispatch(updateAssessmentTab("home"));
    setSelectedStudentView(pageName);
    setTabKey("students");
  };

  function setAssessmentTab(tabName: string) {
    dispatch(updateAssessmentTab(tabName.toString()));
    setTabKey("Assessments");
  }

  let tabs = [
    {
      eventKey: "schools",
      title: tabName,
      content: tableList()
    },
    {
      eventKey: "students",
      tabClassName: "student-tab",
      title: <StudentDropdownTab onNavClick={setPage} currentView={selectedStudentView} />,
      content: (
        <StudentsTab
          isfrom="school"
          orgLevel="district"
          selectedView={selectedStudentView}
          setSelectedView={setSelectedStudentView}
          showStudentSearch={true}
        />
      )
    },
    {
      eventKey: "educators",
      title: "Educators",
      content: <Educators isfrom="institutions" />
    },
    ...(props.getIsMyPathOnly === false ? [{
      eventKey: "Assessments",
      tabClassName: "assessments-tab",
      title: <AssessmentDropdownTab onNavClick={setAssessmentTab} />,
      content: <AssessmentCards id={id} isFromSideBarAssessment={false} />
    }] : []),
    {
      eventKey: "reports",
      title: "Reports",
      content: (
        <ReportsTab
          id={id}
          isfrom="institutions"
          MyPathLevelSubject={MyPathLevelSubject}
          BenchmarkGrowthReportBreadcrumbSubject={BenchmarkGrowthReportBreadcrumbSubject}
          ActivityIndicatorSubject={ActivityIndicatorSubject}
        />
      )
    },
    {
      eventKey: "licensing",
      title: "Licensing",
      content: <Licensing isfrom="institutions" />
    },
    {
      eventKey: "AcademicSessions",
      title: "Academic Sessions",
      content: <Sessions isFrom="districts" />
    },
    {
      eventKey: "settings",
      title: "Settings",
      content: <Settings />
    }
  ];

  tabs = getUserRoleId === 1 || getUserRoleId === 2 ? tabs : tabs.filter((item) => item.eventKey !== "licensing");

  tabs =
    getOrgLoginCodeAvailable && !getIsOrgGuidPresent(id)
      ? tabs
      : tabs.filter((item) => item.eventKey !== "reports");

  tabs = getIsOrgGuidPresent(id)
    ? tabs.filter((item) => item.eventKey !== "settings" && item.eventKey !== "Assessments")
    : tabs;

  function isAssessmentTabClicked(tab: string) {
    if (tab === "students" || tab === "Assessments") {
      return;
    }

    setSelectedStudentView("home");
    if (tab === "reports") {
      dispatch(updateReportCard("home"));
    }

    dispatch(updatePreviousURL(window.location.pathname));
    setTabKey(tab);
  }

  const fixedPaginationCount = () => {
    if (data.values.length === 0) {
      return null;
    } else {
      return (
        <div className="fixedpagination">
          <UxPaginate
            data={data}
            page={pageNum}
            paginationSize={getPaginationControllerState[6].paginationCount}
            className={classes.root}
            handleChange={handleChange}
          />
          {data.size > 50 && (
            <UxPaginationController
              rowsList={paginationControllers}
              setRowsCount={getPaginationControllerState[6].paginationCount}
              callback={callback}
            />
          )}
        </div>
      );
    }
  };

  return (
    <div className="class-nav-tabs">
      <div className={alertclasses.root}>
        <Snackbar
          open={showToast.val}
          autoHideDuration={4000}
          onClose={() => {
            setShowToast({ val: false, msg: "" });
          }}
        >
          <Alert
            style={{ backgroundColor: "#36BBA4", color: "#fff" }}
            onClose={() => {
              setShowToast({ val: false, msg: "" });
            }}
          >
            {showToast.msg}
          </Alert>
        </Snackbar>
      </div>
      {loading ? null : (
        <UxTabs
          tabsId="class-tabs"
          activeKey={tabKey}
          onTabSelect={(tab: string) => {
            isAssessmentTabClicked(tab);
            localStorage.setItem("page", tab);
          }}
          children={tabs}
          className="pt-2"
          currentKey={tabKey}
        />
      )}
      {loading || tabKey !== "schools" ? null : fixedPaginationCount()}
      {showModal ? (
        <CustomerModal
          customerdata={customerData}
          custstate={status}
          close={closeModal}
          status={updateCustomerStatus}
        />
      ) : null}
    </div>
  );
}

export default connect(mapStateToProps)(Institutions);
