import React, { useState, useEffect } from "react";
import {
  UxActivityIndicator,
  UxAction,
  UxIconEnum,
  UxStyleEnum,
  UxPriorityEnum,
  UxActionTypeEnum
} from "@wne-spa/ux-component-library";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./login.scss";
import { login, getUser } from "../../authService/AuthService";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import configMessages from "../../helpers/configMessages";
import { UserService } from "../../services/helpers/user.services";
import Settings from "../../helpers/constants";
import moment from "moment";
import { updatePageTitle } from "../../Redux/Action";
import { connect } from "react-redux";
import logo from "../../images/edgenuity-logo-large.png";
import ReduxProps from "../../Redux/Redux.props";
import { LoginProps } from "./Login.props";
import { User } from "oidc-client";
import ky from "ky";

interface Props extends ReduxProps, LoginProps {}

function mapStateToProps(state: ReduxProps) {
  return {
    getActivityIndicator: state.getActivityIndicator
  };
}
function Login(props: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState({
    password: "",
    username: "",
    loginCode: localStorage.getItem("ala:loginCode") || "",
    process: false
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showToast, setShowToast] = useState({ val: false, msg: "" });
  const { dispatch, getActivityIndicator } = props;
  const [mppApp, setMppApp] = useState(false);

  useEffect(() => {
    dispatch(updatePageTitle(""));
    setTimeout(() => {
      window.location.assign(window.location.origin);
    }, 1000 * 60 * 60 * 4 - 10000);
  }, []);

  function Alert(alertprops: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...alertprops} />;
  }
  useEffect(() => {
    window.addEventListener("resize", getZoomSize);
  }, [window.location.pathname]);

  const getZoomSize = () => {
    let zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;

    if (zoom > 100) {
      document.getElementById("loginpage").style.removeProperty("overflow");
    } else {
      document.getElementById(`loginpage`).style.overflowY = "none";
    }
  };

  const alertStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242"
      }
    }
  }));
  const alertclasses = alertStyles();

  useEffect(() => {
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).includes("oidc.")) {
        let data = JSON.parse(localStorage.getItem(localStorage.key(i)));
        localStorage.setItem("oidc:state", data.id);
        localStorage.removeItem("oidc:persistState");
      }
    }
    // Login for educators coming from the Product Selection Page
    if (localStorage.getItem("ala:MppLaunch") === "true" && !isWneUserApp()) {
      setMppApp(true);
      login();
    } else {
      if (localStorage.getItem("ala:loginCode")) {
        //@ts-ignore
        setState({
          ...state,
          loginCode: localStorage.getItem("ala:loginCode")
        });
      }
      if (isWneUserApp()) {
        localStorage.removeItem("ala:MppLaunch");
        if (localStorage.getItem("ala:redirectedFrom") !== "wneuser") {
          localStorage.setItem("ala:redirectedFrom", "wneuser");
          window.location.assign("/");
        }
      }
      localStorage.setItem("lms:isRenew", "false");
      getUser()
        .then(async (user: User) => {
          if (!user) {
            // If a non-wne user is not authenticated, redirect to ulp. All non-wne users should come in from the pss page
            if (!isWneUserApp()) {
              if (state.loginCode) {
                window.location.replace(`${window.APP_ENV.ulpRedirectUrl}/signin?sitecode=${state.loginCode}`);
              } else {
                window.location.replace(window.APP_ENV.ulpRedirectUrl);
              }
            } 
            if (new URL(`${window.location}`).searchParams.get("error")) {
              if (
                new URL(`${window.location}`).searchParams.get("error") === "Invalid username or password" ||
                new URL(`${window.location}`).searchParams.get("error") === "Invalid login code"
              ) {
                localStorage.removeItem("oidc:session");
                localStorage.removeItem("page");
                localStorage.removeItem("oidc:state");
                for (let i = 0; i < localStorage.length; i++) {
                  if (localStorage.key(i).includes("oidc.")) {
                    localStorage.removeItem(localStorage.key(i));
                  }
                }
                if (localStorage.getItem("ala:hasCode")) {
                  setState({
                    ...state,
                    loginCode: localStorage.getItem("ala:loginCode") || "",
                    process: true
                  });
                } else {
                  localStorage.removeItem("ala:loginCode");
                  // setState({
                  //   ...state,
                  //   process: true,
                  // });
                }
                setShowToast({
                  val: true,
                  msg: "You entered an incorrect username, password, or both."
                });
                focusOnUsername();
                const clientDateTime = moment().format();
                const useragent = navigator.userAgent;
                let eventURL = window.location.origin;
                const json = {
                  ClientDateTime: clientDateTime,
                  UserAgent: useragent,
                  EventSourceURL: eventURL
                };
                UserService.loginfailed(json);
                setTimeout(() => {
                  login();
                }, 5000);
              }
            } else if (!localStorage.getItem("oidc:state") || Boolean(localStorage.getItem("oidc:session"))) {
              login();
              setState({
                ...state,
                process: true
              });
            }
          } else if (!Boolean(localStorage.getItem("oidc:session"))) {
            login();
            setState({
              ...state,
              process: true
            });
          } else {
            login();
            setState({
              ...state,
              process: true
            });
          }
        })
        .catch((error) => {
          if (error.message === "No matching state found in storage") {
            localStorage.removeItem("oidc:state");
            localStorage.removeItem("oidc:session");
            localStorage.removeItem("page");
            login();
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getActivityIndicator]);

  function checker() {
    handleSubmit();
  }

  const focusOnUsername = () => {
    setTimeout(() => {
      document.getElementById("username").focus();
    }, 12000);
  };

  async function handleSubmit() {
    const clientDateTime = moment().format();
    const useragent = navigator.userAgent;
    localStorage.setItem("ala:loginCode", state.loginCode);
    try {
      setIsSubmitting(true);
      const jsonObj = {
        userName: state.username,
        password: state.password,
        IsWneForm: true,
        ClientDateTime: clientDateTime,
        UserAgent: useragent,
        loginCode: localStorage.getItem("ala:loginCode")
      };
      UserService.isMigrated(jsonObj).then(async (res: Response | ky.HTTPError) => {
        if ((res as Response).status === 200) {
          //@ts-ignore
          document.getElementById("ustate").value =
            localStorage.getItem("oidc:persistState") !== null
              ? localStorage.getItem("oidc:persistState")
              : localStorage.getItem("oidc:state");
          localStorage.setItem("oidc:session", "true");
          const getSubmit = document.getElementById("usubmit");
          getSubmit?.click();
          setIsSubmitting(false);
        } else {
          let details =
            typeof (res as ky.HTTPError).response !== "undefined" ? await (res as ky.HTTPError).response.json() : 0;
          if (details === 1) {
            setShowToast({
              val: true,
              msg: "You entered an incorrect username, password, or both."
            });
            focusOnUsername();
          } else if (details === 2) {
            setShowToast({
              val: true,
              msg: "Username/Password is not correct"
            });
            focusOnUsername();
          } else if (details === 3) {
            setShowToast({
              val: true,
              msg: configMessages.AuthenticatorErrorMessage
            });
            focusOnUsername();
          } else {
            setShowToast({
              val: true,
              msg: "If you are trying to log in simultaneously to a different user account, this is not supported. Try a different browser or open a new incognito window."
            });
            focusOnUsername();
          }
          if (localStorage.getItem("ala:hasCode")) {
            setState({
              ...state,
              password: "",
              username: "",
              loginCode: localStorage.getItem("ala:loginCode") || "",
              process: false
            });
          } else {
            localStorage.removeItem("ala:loginCode");
            setState({
              ...state,
              password: "",
              username: "",
              loginCode: "",
              process: false
            });
          }
          setIsSubmitting(false);
        }
      });
    } catch (error) {
      setShowToast({
        val: true,
        msg: configMessages.AuthenticatorErrorMessage
      });
      setIsSubmitting(false);
      setState({
        ...state,
        process: true
      });
    }
  }
  function forgotPassword() {
    navigate("/forgotpassword");
  }

  function isWneUserApp() {
    return window.location.host === window.APP_ENV.WneUserVanityURL || window.location.host === "localhost:3000"; //When running locally, to enable student/educator screen, change to 30000
  }

  function responsiveLayout() {
    if (!isWneUserApp()) {
      return (
        <>
          <div className="loginbackground"> </div>
        </>
      ); // display this instead of blank screen
    }

    return (
      <>
        <div className="loginbackground"> </div>
        {state.process ? <UxActivityIndicator type="nouser" /> : null}
        <div id="loginpage" className="loginlayout">
          <div className="loginholderadmin">
            <div className="loginfieldswne">
              <div className={"imgname"} style={{ padding: "16px" }}>
                {" "}
                <img
                  src={logo}
                  alt="Imagine MyPath logo"
                  style={{ width: "60%" }}
                />
              </div>
              {responsiveWNETab()}
            </div>
            <div className="loginreset">
              <div className="forgotPasswordHolder">
                <p
                  onClick={() => {
                    forgotPassword();
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      forgotPassword();
                    }
                  }}
                  className="forgotPasswordTag"
                  tabIndex={0}
                  role="link"
                  style={{ opacity: 1, visibility: "visible" }}
                >
                  <u> {"Reset Your Password"} </u>
                  <br />
                </p>
              </div>
            </div>
          </div>
          <div className="loginfooterfields">
            <div className="leftfooter">© {new Date().getFullYear()} Edgenuity Inc. All Rights Reserved.</div>
            <div className="rightfooter" style={{ fontWeight: 500 }} role="contentinfo" aria-label="Footer">
              <a
                className="tagRightFooter"
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.imaginelearning.com/en/us/products/mypath"
                id="about"
                tabIndex={0}
                aria-label="About link opens in a new tab"
                role="link"
                onKeyDown={(event) => {
                  if (event.key === "Spacebar" || event.key === " ") {
                    window.open("https://www.imaginelearning.com/en/us/products/mypath");
                  }
                }}
              >
                <u> About </u>
              </a>
              <span style={{ color: "#fff", fontSize: "12px" }}>•</span>
              <a
                className="tagRightFooter  "
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.imaginelearning.com/support/privacy/policy/"
                tabIndex={0}
                aria-label="Privacy Policy link opens in a new tab"
                id="policy"
                role="link"
              >
                <u> Privacy Policy</u>
              </a>
              <span style={{ color: "#fff", fontSize: "12px" }}>•</span>
              <a
                className="tagRightFooter  "
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.imaginelearning.com/media/pdf/End%20User%20TOS%202022%20IL%20LLC.pdf"
                tabIndex={0}
                aria-label="Terms of Use link opens in a new tab"
                id="terms"
                role="link"
              >
                <u> Terms of Use </u>
              </a>
            </div>
          </div>
        </div>
        <div style={{ display: "none" }}>
          <form action={`${Settings().authority}/auth/login`} method="post">
            <input type="text" id="uname" name="username" value={state.username} readOnly />
            <input type="text" id="upass" name="password" value={state.password} readOnly />
            <input type="text" id="ustate" name="state" value={localStorage.getItem("oidc:state")} readOnly />
            <input
              type="text"
              id="uclientId"
              name="clientId"
              value={
                localStorage.getItem("ala:MppLaunch") === "true" ? Settings().mpp_user_client_id : Settings().client_id
              }
              readOnly
            />
            <input
              type="text"
              id="utenant"
              name="tenant"
              value={window.APP_ENV ? window.APP_ENV.tenant : "Tenant.ALA"}
              readOnly
            />
            <input type="submit" id="usubmit" value="login" />
          </form>
        </div>
        <div className={alertclasses.root}>
          <Snackbar
            open={showToast.val}
            autoHideDuration={12000}
            onClose={() => {
              setShowToast({ val: false, msg: "" });
            }}
          >
            <Alert
              severity="warning"
              style={{ backgroundColor: "#f4473c", color: "#fff" }}
              onClose={() => {
                setShowToast({ val: false, msg: "" });
              }}
            >
              {showToast?.msg.includes("\n")
                ? showToast?.msg.split("\n").map((message: string, index: number, arr: Array<string>) => (
                    <p
                      style={{
                        marginBottom: arr.length - 1 === index && 0
                      }}
                    >
                      {message}
                    </p>
                  ))
                : showToast?.msg}
            </Alert>
          </Snackbar>
        </div>
      </>
    );
  }

  function responsiveWNETab() {
    return (
      <div className="loginHolderWNE" style={{ padding: "28px 0px" }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Login Page: Edgenuity</title>
        </Helmet>
        <div className="loginHeadingWNE extra-margin-top"> Weld North Education</div>
        <div className="loginHeadingWNE extra-margin-bottom"> Employee Login </div>
        <div className="textinputholderwne">
          <input
            style={{ border: "1px solid #cccccc" }}
            id="username"
            data-testid='username'
            className="logintextinputresponsive urname tabfocus loginellipsis"
            maxLength={50}
            placeholder="Username"
            value={state.username}
            autoComplete="username"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.value !== " ") {
                e.target.value = e.target.value.replace(" ", "").trim();
                setState({ ...state, username: e.target.value });
              }
            }}
            tabIndex={0}
          />
        </div>
        <div className="textinputholderwne">
          <div>
            <input
              style={{ border: "1px solid #cccccc" }}
              className={"logintextinputresponsive urpass tabfocus loginellipsis"}
              maxLength={50}
              data-testid="password"
              type="password"
              placeholder="Password"
              value={state.password}
              autoComplete="current-password"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value !== " ") {
                  e.target.value = e.target.value.replace(" ", "").trim();
                  setState({ ...state, password: e.target.value });
                }
              }}
              onKeyPress={(event) => {
                if (
                  event.key === "Enter" &&
                  !state.username.includes(" ") &&
                  !state.password.includes(" ") &&
                  state.username !== "" &&
                  state.password !== ""
                ) {
                  checker();
                }
              }}
              tabIndex={0}
              onClick={(event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
                if (state.username.includes(" ") || state.username === "") {
                  event.stopPropagation();
                  event.preventDefault();
                }
              }}
            />
          </div>
        </div>
        <div
          className="loginbuttonresponsive"
          style={
            !state.username.includes(" ") &&
            !state.password.includes(" ") &&
            state.username !== "" &&
            state.password !== ""
              ? { opacity: "1", marginTop: "31px" }
              : { opacity: "0.65", marginTop: "31px" }
          }
        >
          <UxAction
            callback={() => {
              checker();
            }}
            className="loginbuttonresponsive"
            size={"lg"}
            type={UxActionTypeEnum.SUBMIT}
            enabled={
              !state.username.includes(" ") &&
              !state.password.includes(" ") &&
              state.username !== "" &&
              state.password !== ""
                ? true
                : false
            }
            text={!isSubmitting ? t("Login") : undefined}
            priority={UxPriorityEnum.INFO}
            style={UxStyleEnum.ROUNDED}
            icon={
              (isSubmitting && {
                icon: UxIconEnum.CIRCLE_NOTCH,
                className: "animate spinner"
              }) ||
              undefined
            }
          />
        </div>
        <div style={{ height: "31px" }}></div>
      </div>
    );
  }

  return (
    <>
      {mppApp ? (
        <div className="signinDialog">
          <div className="signinHolder">
            <div
              className="spinner-border text-light loadingSpinner"
              role="status"
              aria-label="Authenticating------. After successful authentication, you will be directed to your home page."
            />
            <span
              style={{
                fontSize: "20px",
                fontFamily: "Roboto",
                color: "#FFFFFF"
              }}
            >
              Authenticating
            </span>
          </div>
        </div>
      ) : (
        responsiveLayout()
      )}
    </>
  );
}

export default connect(mapStateToProps)(Login);
