import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { UxTabs } from "@wne-spa/ux-component-library";
import { useParams } from "react-router-dom";
import StudentsTab from "../Students/StudentTab";
import Educators from "../EducatorsList/EducatorsList";
import Settings from "../ViewCustomer/Settings";
import Classes from "../ViewCustomer/Classes";
import AssessmentCards from "../ViewCustomer/AssessmentCards";
import {
  updateActivityIndicator,
  updateBreadCrumbs,
  updatePageTitle,
  updateTabChange,
  updateTabName,
  updateAssessmentTab,
  updatePreviousURL,
  updateFromSideBarAssessment,
  updateReportCard,
  updateStudentIds,
  updateStudentsSubtab,
  updateStudentAndClassFilterURL,
  updateSchoolGuids,
  updateIsOrganizationActive,
  updateIsOrgRegistrationOwned
} from "../../Redux/Action";
import { AdminService } from "../../services/helpers/api.services";
import "./SAClassList.scss";
import ReportsTab from "../reports/ReportsTab";
import GetToken from "../../authService/GetToken";
import Sessions from "../../components/AcademicSessions/AcademicSessions";
import ReduxProps, { SchoolGuidsResponseProps } from "../../Redux/Redux.props";
import { AppDispatch } from "../../Redux/Store";
import { RootComponentProps } from "../../root-component.props";
import StudentDropdownTab from "../HeaderTabs/StudentDropdownTab";
import AssessmentDropdownTab from "../HeaderTabs/AssessmentDropdownTab";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getDaDetails: state.getDaDetails,
    getTabChange: state.getTabChange,
    getTabName: state.getTabName,
    getStudentSubtab: state.getStudentSubtab,
    getAssessmentTab: state.getAssessmentTab,
    getSchoolGuids: state.getSchoolGuids,
    getIsMyPathOnly: state.getIsMyPathOnly
  };
}
interface SAClasslistprops {
  dispatch: AppDispatch;
}
interface Props extends SAClasslistprops, ReduxProps, RootComponentProps {}

function SAClassList(props: Props) {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();

  const {
    dispatch,
    getDaDetails,
    getTabChange,
    getTabName,
    getStudentSubtab,
    getAssessmentTab,
    schedulerAssessmentSubject,
    MyPathLevelSubject,
    BenchmarkGrowthReportBreadcrumbSubject,
    getSchoolGuids,
    ActivityIndicatorSubject
  } = props;
  const [tabKey, setTabKey] = useState("classes");
  const [schoolName, setSchoolName] = useState("");

  const [selectedStudentView, setSelectedStudentView] = useState("home");

  useEffect(() => {
    dispatch(updateTabChange(!getTabChange));
    // eslint-disable-next-line
  }, [tabKey]);

  useEffect(() => {
    let apiObj = { guid: id.toString() };
    AdminService.getGUIDSWithGuid(apiObj).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        responseGUIDS(data);
      } else {
        // do nothing
      }
    });

    setTabKey(getTabName ? getTabName : tabKey);
    dispatch(updateFromSideBarAssessment(false));

    setSelectedStudentView(getStudentSubtab);

    if (getTabName === "reports") {
      isAssessmentTabClicked(getTabName);
    }

    if (getTabName != "reports") {
      dispatch(updateTabName(null));
    }

    dispatch(updateStudentsSubtab("home"));
    dispatch(updateStudentAndClassFilterURL(window.location.pathname));
    dispatch(updateTabName(tabKey));
  }, [id]);

  useEffect(() => {
    dispatch(updateStudentIds([]));
  }, []);

  async function responseGUIDS(responsedata: Response) {
    let allData = await responsedata.json();
    let exist = getSchoolGuids.filter((item) => item.id?.toString() === id);
    if (exist.length === 0) {
      let updatedSchoolGuids: SchoolGuidsResponseProps[] = [];
      updatedSchoolGuids = [...updatedSchoolGuids, allData[0]];
      dispatch(updateSchoolGuids(updatedSchoolGuids));
    }
    schedulerAssessmentSubject.next({
      token: GetToken(),
      guid: allData[0].orgGUID
    });
    dispatch(updateIsOrganizationActive(allData[0].isActive));
    dispatch(updateIsOrgRegistrationOwned(allData[0]?.isRegistrationOwned));
  }

  useEffect(() => {
    const fetchData = async () => {
      if (getDaDetails.type === 1) {
        const breadcrumb = {
          crumbs: [{ path: `/saclasses/${getDaDetails.id}`, name: "Classes" }]
        };
        dispatch(updateBreadCrumbs(breadcrumb));
        dispatch(updateActivityIndicator(false));
        setSchoolName(getDaDetails.name);
      } else {
        try {
          let apiObj = { rid: id, cid: id };
          await AdminService.getParentsWithRootWithGuid(apiObj).then(
            ({ status, data }: { status: number; data: Response }) => {
              if (status === 200) {
                handleSuccessResponse(data);
              } else {
                const breadcrumb = {
                  crumbs: [
                    { path: `/saschools/${getDaDetails.id}`, name: "Schools" },
                    { path: `/saclasslist/${id}`, name: "Classes" }
                  ]
                };
                dispatch(updateBreadCrumbs(breadcrumb));
                dispatch(updateActivityIndicator(false));
              }
            }
          );
        } catch (err) {
          //do nothing
        }
      }
    };
    dispatch(updateActivityIndicator(true));
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getDaDetails]);

  async function handleSuccessResponse(responsedata: Response) {
    let details = await responsedata.json();
    //@ts-ignore
    const breadcrumb = {
      crumbs: [
        { path: `/saschools/${getDaDetails.id}`, name: "Schools" },
        //@ts-ignore
        { path: `/saclasslist/${id}`, name: details.name }
      ]
    };
    //@ts-ignore
    dispatch(updatePageTitle(`- Schools - ${details.name}`));
    dispatch(updateBreadCrumbs(breadcrumb));
    //@ts-ignore
    setSchoolName(details.name);
    dispatch(updateActivityIndicator(false));
  }

  const setPage = (pageName: string) => {
    dispatch(updateAssessmentTab("home"));
    setSelectedStudentView(pageName);
    setTabKey("students");
  };

  function setAssessmentTab(tabName: string) {
    dispatch(updateAssessmentTab(tabName.toString()));
    if (tabName === "schedule") {
      dispatch(updatePreviousURL(window.location.pathname));
    }
    setTabKey("Assessments");
  }

  let tabs = [
    {
      eventKey: "classes",
      title: "Classes",
      content: <Classes />
    },
    {
      eventKey: "students",
      tabClassName: "student-tab",
      title: <StudentDropdownTab onNavClick={setPage} currentView={selectedStudentView} />,
      content: (
        <StudentsTab isfrom="school" orgLevel="school" selectedView={selectedStudentView} setSelectedView={setSelectedStudentView} />
      )
    },
    {
      eventKey: "educators",
      title: "Educators",
      content: <Educators isfrom="school" schoolname={schoolName} />
    },
    ...(props.getIsMyPathOnly === false ? [{
      eventKey: "Assessments",
      tabClassName: "assessments-tab",
      title: <AssessmentDropdownTab onNavClick={setAssessmentTab} />,
      content: <AssessmentCards id={id} isFromSideBarAssessment={false} />
    }] : []),
    {
      eventKey: "reports",
      title: "Reports",
      content: (
        <ReportsTab
          id={id}
          isfrom="school"
          MyPathLevelSubject={MyPathLevelSubject}
          BenchmarkGrowthReportBreadcrumbSubject={BenchmarkGrowthReportBreadcrumbSubject}
          ActivityIndicatorSubject={ActivityIndicatorSubject}
        />
      )
    },
    {
      eventKey: "AcademicSessions",
      title: "Academic Sessions",
      content: <Sessions isFrom="school" />
    },
    {
      eventKey: "settings",
      title: "Settings",
      content: <Settings isfrom="school" />
    }
  ];

  function isAssessmentTabClicked(tab: string) {
    if (tab === "students" || tab === "Assessments") {
      return;
    }

    setSelectedStudentView("home");

    if (tab === "reports") {
      dispatch(updateReportCard("home"));
    }
    dispatch(updatePreviousURL(window.location.pathname));
    setTabKey(tab);
  }

  return (
    <>
      <div className="class-nav-tabs">
        <UxTabs
          tabsId="class-tabs"
          activeKey={tabKey}
          onTabSelect={(tab: string) => {
            isAssessmentTabClicked(tab);
            localStorage.setItem("page", tab);
          }}
          children={tabs}
          className="pt-2"
          currentKey={tabKey}
        />
      </div>
    </>
  );
}

export default connect(mapStateToProps)(SAClassList);
