import { get, post, put } from "../config/ky.userconfig";
import {
  WNEUserProfileUpdateProps,
  WNEUserTypeUpdateProps,
  AddWNEUserProps,
  SupportUsersProps,
  StudentUpdateInfoProps,
  CreateManualPlacementProps,
  AuthenticationProps,
  IsMigratedProps,
  LoginFailedProps,
  UserJobsProps,
  SearchEducatorsWithGuidProps,
  SearchStudentsWithGuidProps,
  EducatorsWithGuidProps,
  StudentListWithGuidProps,
  DAUsersWithGuidProps,
  BulkStudentsPlacementsDataWithGuidProps,
  UserNWEAIDWithGuidProps,
  StudentUpdateBasicInfoWithGuidProps,
  AddStudentWithGuidProps,
  CreateSAorTeacherWithGuidProps,
  UpdateEducatorOrgWithGuidProps,
  ChangeUserTypeWithGuidProps,
  CreateDistrictAdminWithGuidProps,
  StudentPlacementByOrgWithGuidProps,
  StudentdetailslistwithguidProps,
  StudentUpdateBasicInfoProps,
  GetStudentClassesProps
} from "./user.service.props";

export const UserService = {
  updateWNEUserProfile: (args: WNEUserProfileUpdateProps) =>
    post(`wneuser/updateprofilewithguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  updateWNEUserType: (args: WNEUserTypeUpdateProps) =>
    post(`wneuser/changetypewithguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  addWNEUser: (args: AddWNEUserProps) =>
    post(`api/user/adduser`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getSupportUsers: (args: SupportUsersProps) =>
    post(`api/user/getsupportusers`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  studentUpdateBasicInfo: (args: StudentUpdateBasicInfoProps) =>
    post(`student/updatebasicinfo`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  studentUpdateInfo: (args: StudentUpdateInfoProps) =>
    post(`student/updateinfo`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  subjectScoreRanges: (id: number) =>
    get(`studentplacement/subjectscoreranges?SubjectId=${id}`, {
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  createManualPlacement: (args: CreateManualPlacementProps) =>
    post(`v2/placement/manual`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  restoreilpStudentPlacementId: (id: number) =>
    post(`studentplacement/restoreilp?studentPlacementId=${id}`, {
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  deactivateStudentPlacementId: (id: number) =>
    post(`studentplacement/deactivate?studentPlacementId=${id}`, {
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getGradeLevels: () =>
    get(`api/user/getgradelevels`, { timeout: 120000 })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  Authentication: (args: AuthenticationProps) =>
    post(`api/authenticate`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  updatePaginationCount: (userId: number | string, pageNO: number, count: number) =>
    post(`user/updatepaginationcount?userId=${userId}&paginationCount=${count}&pageName=${pageNO}`)
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  isMigrated: (args: IsMigratedProps) =>
    post("authenticate/ismigrated", {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      }),
  loginfailed: (args: LoginFailedProps) =>
    post(`api/loginfailed`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getZendeskToken: () =>
    get(`api/getzendesktoken`)
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getDoLittleToken: () =>
    get(`token/doolittle`)
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  pendoStudentDetails: (id: number | string) =>
    get(`api/studentpendometadata?userGuid=${id}`)
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getUserJobs: (args: UserJobsProps) =>
    post(`import/users/jobs`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getCurrentUserDetails: (userGuid: string) =>
    get(`v2/user/${userGuid}`)
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getUserDetails: (id: number) =>
    get(`import/users/jobdetails?jobid=${id}`)
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getImportFile: (id: number) =>
    get(`import/users/importedfile?jobid=${id}`)
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getErrorFile: (id: number) =>
    get(`import/users/errorfile?jobid=${id}`)
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getPaginationCountWithGUID: (userId: string) =>
    get(`user/getpaginationcountwithguid?userGuid=${userId}`, {
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getTeachersForMultipleSchoolsWithGuid: (orgIds: string[]) =>
    post(`api/user/teachersformultipleschoolswithguid`, {
      json: [...orgIds],
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getTeachersToMapToClassWithGuid: (id: string) =>
    get(`api/user/getteacherstomaptoclasswithguid?organizationGuid=${id}`, {
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  searchEducatorsWithGuid: (args: SearchEducatorsWithGuidProps) =>
    post(`api/user/searcheducatorswithguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  searchStudentsWithGuid: (args: SearchStudentsWithGuidProps) =>
    post(`api/user/searchstudentswithguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  studentDetailsWithGuid: (id: string) =>
    get(`student/detailswithguid/${id}`, { timeout: 120000 })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getWNEUsersProfileWithGuid: (Guid: string) =>
    get(`api/user/geteducatorprofilewithguid?userGuid=${Guid}`, {
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getEducatorDetailsWithGuid: (id: string) =>
    get(`api/user/geteducatordetailswithguid?userGuid=${id}`, {
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getEducatorsWithGuid: (args: EducatorsWithGuidProps) =>
    post(`api/user/geteducatorswithguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getStudentListWithGuid: (args: StudentListWithGuidProps) =>
    post(`api/user/getstudentlistwithguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  studentListForClassEnrollmentWithGuid: (schoolGUID: string) =>
    get(`api/user/studentlistforclassenrollmentwithguid?organizationGuid=${schoolGUID}`, {
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getDAUsersWithGuid: (args: DAUsersWithGuidProps, id: string) =>
    post(`api/user/getdauserswithguid?organizationGuid=${id}`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  bulkStudentsPlacementsDataWithGuid: (args: BulkStudentsPlacementsDataWithGuidProps) =>
    post(`students/placements/manual`, { json: { ...args } })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  pendoEducatorDetailsWithGuid: (id: string) =>
    get(`api/geteducatorpendometadatabyguid?userguId=${id}`)
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  userNWEAIDWithGuid: (args: UserNWEAIDWithGuidProps) =>
    post(`studentplacementbyguid/usernweaid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  studentUpdateBasicInfoWithGuid: (args: StudentUpdateBasicInfoWithGuidProps) =>
    post(`student/updatebasicinfowithguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  addStudentWithGuid: (args: AddStudentWithGuidProps, id: string) =>
    post(`api/user/addstudentwithguid?organizationGuid=${id}`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  createSAorTeacherWithGuid: (args: CreateSAorTeacherWithGuidProps) =>
    post(`api/users/saorteacherswithguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  updateEducatorOrgWithGuid: (args: UpdateEducatorOrgWithGuidProps, id: string) =>
    post(`api/user/updateeducatororgwithguid?organizationGuid=${id}`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  changeUserTypeWithGuid: (args: ChangeUserTypeWithGuidProps) =>
    post(`educator/changetypewithguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  deactivateWNEUsersWithGuid: (Guids: string[] | number[]) =>
    post(`wneuser/deactivatewithguid`, {
      json: [...Guids],
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  activateWNEUsersWithGuid: (Guids: string[] | number[]) =>
    post(`wneuser/activatewithguid`, {
      json: [...Guids],
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  resetPasswordWithGuid: (id: string) =>
    get(`api/resetpasswordwithguid?userGuid=${id}`, { timeout: 120000 })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getStudentListforTeacherWithGuid: (args: StudentListWithGuidProps) =>
    post(`student/forteacherwithguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  createDistrictAdminWithGuid: (args: CreateDistrictAdminWithGuidProps, id: string) =>
    post(`api/users/districtadminswithguid?organizationGuid=${id}`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  studentPlacementByClass: (args:StudentPlacementByOrgWithGuidProps) =>
    post(`v2/placement/byclass`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  studentPlacementByClassCSV: (args:StudentPlacementByOrgWithGuidProps) =>
    post(`v2/placement/byclass-csv`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  studentPlacementBySchool: (args:StudentPlacementByOrgWithGuidProps) =>
    post(`v2/placement/byschool`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  studentPlacementBySchoolCSV: (args:StudentPlacementByOrgWithGuidProps) =>
    post(`v2/placement/byschool-csv`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  studentPlacementByDistrict: (args:StudentPlacementByOrgWithGuidProps) =>
    post(`v2/placement/bydistrict`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  studentPlacementByDistrictCSV: (args:StudentPlacementByOrgWithGuidProps) =>
    post(`v2/placement/bydistrict-csv`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  bulkExportStudentsWithGuid: (id: string | number, oid: string, flag: boolean) =>
    get(`bulkexport/studentswithguid?UserGuid=${id}&ExpectedOrgGuid=${oid}&flag=${flag}`, {
      timeout: false
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  bulkExportEducatorsWithGuid: (id: string | number, oid: string, flag: boolean) =>
    get(`bulkexport/educatorswithguid?UserGuid=${id}&ExpectedOrgGuid=${oid}&flag=${flag}`, {
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getstudentdetailslistwithguid: (args: StudentdetailslistwithguidProps) =>
    post(`api/user/getstudentdetailslistwithguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getStudentClasses: (args: GetStudentClassesProps) =>
    post(`student/enrolledclasses`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getDomainPlacements: (placementId: number) =>
    get(`v2/placement/${placementId}/mypathdomains`, {
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  updatePlacement: (placementId: number, placementvalue: number, isLocked: boolean) =>
    put(`v2/placement/${placementId}`, {
      json: { placementGrade: placementvalue, isLocked: isLocked },
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getEducatorsSchoolLevelCSV: (args: EducatorsWithGuidProps) =>
    post(`school/educators/csv`, {
      json: args,
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getEducatorsDistrictLevelCSV: (args: DAUsersWithGuidProps) =>
    post(`district/educators/csv`, {
      json: args,
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getEducatorsCSVForSADA: (args: SearchEducatorsWithGuidProps) =>
    post(`users/educators/csv`, {
      json: args,
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getStudentRosterSchoollevelCSVData: (args: StudentListWithGuidProps) =>
    post(`school/students/csv`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getStudentRosterSchoollevelFORMSCSVData: (args: StudentListWithGuidProps) =>
    post(`teacher/schools/students/csv`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getStudentRosterUserlevelCSVData: (args: StudentListWithGuidProps) =>
    post(`students/search/csv`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getStudentFirstLanguageSubjects: () =>
    get("api/user/getlanguages")
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      })
};
