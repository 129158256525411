const paths = [
  "",
  "signin",
  "page-not-found",
  "customers",
  "users",
  "admin",
  "schools",
  "school",
  "daschool",
  "dalist",
  "saschools",
  "saclasses",
  "sausers",
  "sasettings",
  "saclassview",
  "sessions",
  "teacherschools",
  "teacherclasses",
  "teacherclass",
  "cvieweducator",
  "dvieweducator",
  "svieweducator",
  "suvieweducator",
  "duvieweducator",
  "searchvieweducator",
  "wneuser",
  "searchdvieweducator",
  "vieweducator",
  "mpasettingscontainer",
  "viewstudent",
  "profile",
  "institutions",
  "classview",
  "dainstitutions",
  "saclasslist",
  "teacherclassview",
  "tstudents",
  "eusers",
  "eadmin",
  "studentplacement",
  "bulkimports",
  "sbulkimports",
  "forgotpassword",
  "resetpassword",
  "assessments",
  "reports",
  "home",
  "assessmentschedule",
  "classprogress",
  "saassessmentschools",
  "logout",
  "teacherassessmentschools",
  "mypath-assessment-reports",
  "benchmark-growth-reports",
  "reportcards",
  "impersonation-error",
  "assignmentbuilder",
  "createstudent",
  "noLicenseProducts",
  "classsummary",
  "dsdsreport",
  "addstudentroster",
  "studentprogress",
  "studentclasses",
  "launch",
  "domainmapping",
  "cumulativestandardsproficiency",
  "dsds-report-launch"
];

export default paths;
