import React, { useEffect, useState } from "react";
import {
  updateReportCard,
  updateTabName,
  updateAssessmentTab,
  updateSchoolGuids,
  updateActivityIndicator,
  updateStudentsSubtab
} from "../../Redux/Action";
import "./ReportsTab.scss";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { UxTabs } from "@wne-spa/ux-component-library";
import { AdminService } from "../../services/helpers/api.services";
import ReduxProps from "../../Redux/Redux.props";
import { ReportsProps, ItemProps, SchoolGuidsResponseProps, GuidResponseProps } from "./Reports.props";
import { RootComponentProps } from "../../root-component.props";
import StudentDropdownTab from "../HeaderTabs/StudentDropdownTab";
import AssessmentDropdownTab from "../HeaderTabs/AssessmentDropdownTab";

function mapStateToProps(state: ReduxProps) {
  return {
    getFlagrKeys: state.getFlagrKeys,
    getDistrictLevelFirstTab: state.getDistrictLevelFirstTab,
    getUserRoleId: state.getUserRoleId,
    getLSBReport: state.getLSBReport,
    getSchoolGuids: state.getSchoolGuids,
    getAssessmentTab: state.getAssessmentTab,
    getUpdateLevel: state.getUpdateLevel,
    getDSDSReportToken: state.getDSDSReportToken,
    getIsMyPathOnly: state.getIsMyPathOnly
  };
}
interface Props extends ReportsProps, ReduxProps, RootComponentProps {}

function DsdsReport(props: Props) {
  const {
    dispatch,
    getDistrictLevelFirstTab,
    getUserRoleId,
    getLSBReport,
    getAssessmentTab,
    getUpdateLevel,
    getSchoolGuids,
    dsdsReportSubject,
    getDSDSReportToken
  } = props;
  const [tabKey] = useState("reports");
  const { id } = useParams<{ id: string | undefined }>();
  const navigate = useNavigate();
  const [selectedStudentView, setSelectedStudentView] = useState("home");

  useEffect(() => {
    dsdsReportSubject.next({ token: getDSDSReportToken });
    dispatch(updateActivityIndicator(false));
  }, []);
  function isReportTabsClicked(tab: string, subtab?: string) {
    if (tab === "students" || tab === "Assessments") {
      return;
    }
    dispatch(updateReportCard("home"));
    dispatch(updateAssessmentTab("home"));
    dispatch(updateStudentsSubtab("home"));

    if (tab === "sudo-students") {
      dispatch(updateTabName("students"));
      dispatch(updateStudentsSubtab(subtab));
    } else if (tab === "sudo-Assessments") {
      dispatch(updateTabName("Assessments"));
      dispatch(updateAssessmentTab(subtab));
    } else if (tab === "institutions" || tab === "school") {
      dispatch(updateTabName("schools"));
    } else {
      dispatch(updateTabName(tab));
    }

    if (getUpdateLevel === "school") {
      if (getUserRoleId !== 4) {
        fetchIds();
      } else {
        navigate(`/saclasslist/${id}`);
      }
    } else if (getUpdateLevel === "district") {
      if (getUserRoleId === 3) {
        navigate(`/dainstitutions/${id}`);
      } else {
        navigate(`/institutions/${id}`);
      }
    }
  }
  const fetchIds = async () => {
    try {
      let ids = window.location.pathname.split("/");
      let guids: string[] = [];
      guids = [...guids, `${ids[ids.length - 1]}`];
      let data = { guids };
      AdminService.getIds(data).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          guidDetails(data, guids);
        }
      });
    } catch (err) {
      //do nothing
    }
  };
  async function guidDetails(repsonseData: Response, guids: string[]) {
    let details = JSON.parse(await repsonseData.text());
    let filteredData = details.filter((item: GuidResponseProps) => item.organizationGuid === guids[0]);
    let exist = getSchoolGuids.filter((item: SchoolGuidsResponseProps) => item.id === filteredData[0].organizationId);
    if (exist.length === 0) {
      let guids = [...getSchoolGuids, filteredData[0]];
      dispatch(updateSchoolGuids(guids));
    }

    if (getUserRoleId === 3) {
      navigate(`/daschool/${filteredData[0].organizationGuid}`);
    } else {
      navigate(`/school/${filteredData[0].organizationGuid}`);
    }
  }

  function setAssessmentTab(tabName: string) {
    isReportTabsClicked("sudo-Assessments", tabName);
  }

  const setPage = (pageName: string) => {
    dispatch(updateAssessmentTab("home"));
    setSelectedStudentView(pageName);
    isReportTabsClicked("sudo-students", pageName);
  };

  let schoolLevel = ["school", "daschool", "saschool", "saclasslist"];
  let districtLevel = ["dainstitutions", "institutions", "district"];
  let classLevel = ["class", "classview"];
  let studentLevel = ["student"];

  function checkLevel() {
    let urlParts = window.location.pathname.split("/");
    for (let str in urlParts) {
      if (urlParts[str] !== "") {
        if (schoolLevel.includes(urlParts[str])) {
          return "school";
        } else if (classLevel.includes(urlParts[str])) {
          return "class";
        } else if (districtLevel.includes(urlParts[str])) {
          return "district";
        } else if (studentLevel.includes(urlParts[str])) {
          return "student";
        }
      }
    }
  }
  let schoolTabs = [
    {
      eventKey: "classes",
      tabClassName: "",
      title: "Classes"
    },
    {
      eventKey: "students",
      tabClassName: "student-tab",
      title: <StudentDropdownTab onNavClick={setPage} currentView={selectedStudentView} />
    },
    {
      eventKey: "educators",
      tabClassName: "",
      title: "Educators"
    },
    ...(props.getIsMyPathOnly === false ? [{
      eventKey: "Assessments",
      tabClassName: "assessments-tab",
      title: <AssessmentDropdownTab onNavClick={setAssessmentTab} />
    }] : []),
    {
      eventKey: "reports",
      tabClassName: "",
      title: "Reports"
    },
    {
      eventKey: "AcademicSessions",
      tabClassName: "",
      title: "Academic Sessions"
    },
    {
      eventKey: "settings",
      tabClassName: "",
      title: "Settings"
    }
  ];

  let districtLeveltabs = [
    {
      eventKey: "institutions",
      tabClassName: "",
      title: getDistrictLevelFirstTab
    },
    {
      eventKey: "students",
      tabClassName: "student-tab",
      title: <StudentDropdownTab onNavClick={setPage} currentView={selectedStudentView} />
    },
    {
      eventKey: "educators",
      title: "Educators"
    },
    ...(props.getIsMyPathOnly === false ? [{
      eventKey: "Assessments",
      tabClassName: "assessments-tab",
      title: <AssessmentDropdownTab onNavClick={setAssessmentTab} />
    }] : []),
    {
      eventKey: "reports",
      title: "Reports"
    },
    {
      eventKey: "licensing",
      title: "Licensing"
    },
    {
      eventKey: "AcademicSessions",
      title: "Academic Sessions"
    },
    {
      eventKey: "settings",
      title: "Settings"
    }
  ];

  districtLeveltabs =
    getUserRoleId === 3
      ? districtLeveltabs.filter((item: ItemProps) => item.eventKey !== "licensing")
      : districtLeveltabs;

  return (
    <>
      {!getLSBReport && (
        <UxTabs
          tabsId="class-tabs"
          activeKey={tabKey}
          onTabSelect={(tab: string) => {
            isReportTabsClicked(tab);
            localStorage.setItem("page", tab);
          }}
          children={getUpdateLevel === "school" ? schoolTabs : districtLeveltabs}
          className="pt-2"
          currentKey={tabKey}
        />
      )}

      <div className={getLSBReport ? "bar" : "barmargin"} style={{ width: "calc(100vw - 90px)" }}>
        <div className="reportsBar">
          <label className="reportsBarTextSchedule">Cumulative Performance Report</label>
        </div>
        <div>
          <div id="dsdsreport"></div>
        </div>
      </div>
    </>
  );
}

export default connect(mapStateToProps)(DsdsReport);
