/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { connect } from "react-redux";
import "./MPASettingsContainer.scss";
import { updateBreadCrumbs } from "../../Redux/Action";
import { AdminService } from "../../services/helpers/api.services";
import MyPathSettings from "../Settings/MyPathSettings";
import ReduxProps from "../../Redux/Redux.props";
import { MPASettingsContainerProps } from "./MPASettingsContainer.props";

interface Props extends ReduxProps, MPASettingsContainerProps {}

function mapStateToProps(state: ReduxProps) {
  return {
    getActivityIndicator: state.getActivityIndicator,
    getDaDetails: state.getDaDetails,
    getUserRoleId: state.getUserRoleId,
    getFlagrKeys: state.getFlagrKeys
  };
}

function MPASettingsContainer(props: Props): JSX.Element {
  const { dispatch, getDaDetails, getUserRoleId, id, isFromSideBarAssessment } = props;

  React.useEffect(() => {
    if (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) {
      adminBreadcrumbs();
    } else if (getUserRoleId === 3) {
      districtAdminBreadCrumb();
    } else if (getUserRoleId === 4 && getDaDetails.type === 1) {
      schoolAdminBreadCrumb();
    }
  }, []);

  async function schoolAdminBreadCrumb() {
    try {
      let apiObj = { rid: id, cid: id };
      await AdminService.getParentsWithRootWithGuid(apiObj).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            setSchoolAdminBreadCrumb(data);
          } else {
            // do nothing
          }
        }
      );
    } catch (err) {
      //do nothing
    }
  }

  async function setSchoolAdminBreadCrumb(responsedata: Response) {
    let details = JSON.parse(await responsedata.text());
    //@ts-ignore
    const breadcrumb = {
      crumbs: [
        { path: `/saschools/${getDaDetails.id}`, name: "Schools" },
        //@ts-ignore
        { path: `/saclasslist/${id}`, name: `${details.name}` },
        { path: `/mpasettingscontainer/${id}`, name: "Assessments" }
      ]
    };

    if (!isFromSideBarAssessment) {
      breadcrumb.crumbs.pop();
    } else {
      breadcrumb.crumbs = [];
      breadcrumb.crumbs.push({
        path: `/mpasettingscontainer/${id}`,
        name: "Assessments"
      });
    }
    dispatch(updateBreadCrumbs(breadcrumb));
  }

  async function districtAdminBreadCrumb() {
    try {
      let apiObj = { rid: getDaDetails.id, cid: id };
      await AdminService.getParentsWithRootWithGuid(apiObj).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            setDistrictAdminBreadCrumb(data);
          } else {
            // do nothing
          }
        }
      );
    } catch (err) {
      //do nothing
    }
  }

  async function setDistrictAdminBreadCrumb(responsedata: Response) {
    let details = JSON.parse(await responsedata.text());
    //details = JSON.parse(details);
    //@ts-ignore
    if (details.parents === null) {
      //@ts-ignore
      if (details.childDepth === 0) {
        //@ts-ignore
        const breadcrumb = {
          crumbs: [
            //@ts-ignore
            { path: `/daschool/${details.orgGUID}`, name: details.name },
            { path: `/mpasettingscontainer/${id}`, name: "Assessments" }
          ]
        };
        if (!isFromSideBarAssessment) {
          breadcrumb.crumbs.pop();
        } else {
          breadcrumb.crumbs = [];
          breadcrumb.crumbs.push({
            path: `/mpasettingscontainer/${id}`,
            name: "Assessments"
          });
        }
        dispatch(updateBreadCrumbs(breadcrumb));
      } else {
        //@ts-ignore
        const breadcrumb = {
          crumbs: [
            //@ts-ignore
            { path: `/dainstitutions/${details.orgGUID}`, name: details.name },
            { path: `/mpasettingscontainer/${id}`, name: "Assessments" }
          ]
        };
        if (!isFromSideBarAssessment) {
          breadcrumb.crumbs.pop();
        } else {
          breadcrumb.crumbs = [];

          breadcrumb.crumbs.push({
            //@ts-ignore
            path: `/mpasettingscontainer/${id}`,
            //@ts-ignore
            name: "Assessments"
          });
        }
        dispatch(updateBreadCrumbs(breadcrumb));
      }
    } else {
      updateDistrictAdminBreadCrumb(details);
    }
  }

  const updateDistrictAdminBreadCrumb = (details) => {
    const breadcrumb = { crumbs: [] };
    //@ts-ignore
    const crumbs = details.parents.reverse();
    for (let x of crumbs) {
      //@ts-ignore
      breadcrumb.crumbs.push({
        //@ts-ignore
        path: `/dainstitutions/${x.orgGUID}`,
        //@ts-ignore
        name: x.name
      });
    }
    //@ts-ignore
    if (details.childDepth === 0) {
      //@ts-ignore
      breadcrumb.crumbs.push({
        //@ts-ignore
        path: `/daschool/${details.orgGUID}`,
        //@ts-ignore
        name: details.name
      });
    } else {
      //@ts-ignore
      breadcrumb.crumbs.push({
        //@ts-ignore
        path: `/dainstitutions/${details.orgGUID}`,
        //@ts-ignore
        name: details.name
      });
    }
    //@ts-ignore
    breadcrumb.crumbs.push({
      //@ts-ignore
      path: `/mpasettingscontainer/${id}`,
      //@ts-ignore
      name: "Assessments"
    });

    if (!isFromSideBarAssessment) {
      breadcrumb.crumbs.pop();
    } else {
      breadcrumb.crumbs = [];

      breadcrumb.crumbs.push({
        //@ts-ignore
        path: `/mpasettingscontainer/${id}`,
        //@ts-ignore
        name: "Assessments"
      });
    }
    dispatch(updateBreadCrumbs(breadcrumb));
  };

  async function adminBreadcrumbs() {
    try {
      let apiObj = { guid: id };
      await AdminService.getParentsWithGuid(apiObj).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          setAdminBreadcrumbs(data);
        } else {
          const breadcrumb = {
            crumbs: [{ path: "/customers", name: "Customers" }]
          };
          dispatch(updateBreadCrumbs(breadcrumb));
        }
      });
    } catch (err) {
      //do nothing
    }
  }

  async function setAdminBreadcrumbs(responsedata: Response) {
    let details = JSON.parse(await responsedata.text());
    //@ts-ignore
    if (details.parents === null) {
      //@ts-ignore
      if (details.childDepth === 0) {
        //@ts-ignore
        const breadcrumb = {
          crumbs: [
            { path: `/customers/`, name: "Customers" },
            //@ts-ignore
            { path: `/school/${details.orgGUID}`, name: details.name },
            { path: `/mpasettingscontainer/${id}`, name: "Assessments" }
          ]
        };

        if (!isFromSideBarAssessment) {
          breadcrumb.crumbs.pop();
        } else {
          breadcrumb.crumbs = [];
          breadcrumb.crumbs.push({
            //@ts-ignore
            path: `/mpasettingscontainer/${id}`,
            //@ts-ignore
            name: "Assessments"
          });
        }

        dispatch(updateBreadCrumbs(breadcrumb));
      } else {
        //@ts-ignore
        const breadcrumb = {
          crumbs: [
            { path: `/customers/`, name: "Customers" },
            //@ts-ignore
            { path: `/institutions/${details.orgGUID}`, name: details.name },
            { path: `/mpasettingscontainer/${id}`, name: "Assessments" }
          ]
        };

        if (!isFromSideBarAssessment) {
          breadcrumb.crumbs.pop();
        } else {
          breadcrumb.crumbs = [];
          breadcrumb.crumbs.push({
            //@ts-ignore
            path: `/mpasettingscontainer/${id}`,
            //@ts-ignore
            name: "Assessments"
          });
        }

        dispatch(updateBreadCrumbs(breadcrumb));
      }
    } else {
      updateAdminBreadCrumbs(details);
    }
  }

  const updateAdminBreadCrumbs = (details) => {
    const breadcrumb = {
      crumbs: [{ path: `/customers/`, name: "Customers" }]
    };
    //@ts-ignore
    const crumbs = details.parents.reverse();
    for (let x of crumbs) {
      //@ts-ignore
      breadcrumb.crumbs.push({
        path: `/institutions/${x.orgGUID}`,
        name: x.name
      });
    }
    //@ts-ignore
    if (details.childDepth === 0) {
      //@ts-ignore
      breadcrumb.crumbs.push({
        path: `/school/${details.orgGUID}`,
        name: details.name
      });
    } else {
      //@ts-ignore
      breadcrumb.crumbs.push({
        path: `/institutions/${details.orgGUID}`,
        name: details.name
      });
    }
    //@ts-ignore
    breadcrumb.crumbs.push({
      path: `/mpasettingscontainer/${id}`,
      name: "Assessments"
    });
    if (!isFromSideBarAssessment) {
      breadcrumb.crumbs.pop();
    } else {
      breadcrumb.crumbs = [];
      breadcrumb.crumbs.push({
        //@ts-ignore
        path: `/mpasettingscontainer/${id}`,
        //@ts-ignore
        name: "Assessments"
      });
    }

    dispatch(updateBreadCrumbs(breadcrumb));
  };

  return (
    <>
      <div className="class-nav-tabs" style={{ marginTop: "-1.5rem" }}>
        <div className="bar">
          <div className="settingsBar">
            <label className="settingsBarText">Assessment Settings</label>
          </div>
          <div>
            <MyPathSettings orgId={id} />
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(mapStateToProps)(MPASettingsContainer);
