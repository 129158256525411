import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  TimesIcon,
  UxIcon,
  UxIconEnum,
  UxMUITable,
  UxPaginate,
  UxPaginationController,
  UxSearchInput,
  UxSizeEnum,
  UxTitleBar
} from "@wne-spa/ux-component-library";
import { MpngExportReport } from "@wne/mpng-components";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { paginationControllers } from "../../helpers/configs";
import HelpIcon from "../../images/help_search_icon.png";
import {
  updateActivityIndicator,
  updateCurrentSchoolGuid,
  updateCurrentSchoolID,
  updatePaginationControllerState,
  updateSearchStudentListSort
} from "../../Redux/Action";
import ReduxProps from "../../Redux/Redux.props";
import { UserService } from "../../services/helpers/user.services";
import "./EducatorsList.scss";
import { IStudents, RowProps, StudentProps } from "./IStudentList";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getUserRoleId: state.getUserRoleId,
    getDaDetails: state.getDaDetails,
    getUserId: state.getUserId,
    getUserGuId: state.getUserGuId,
    getSearchStudentListSort: state.getSearchStudentListSort,
    getTabChange: state.getTabChange,
    getPaginationControllerState: state.getPaginationControllerState,
    getActivityIndicator: state.getActivityIndicator,
    getFlagrKeys: state.getFlagrKeys,
    getUserRole: state.getUserRole,
    getIsOrgHasRegistrationOwned: state.getIsOrgHasRegistrationOwned
  };
}
interface Props extends ReduxProps, StudentProps {}

function StudentList(props: Props): JSX.Element {
  const navigate = useNavigate();
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const [showTable, setShowTable] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState(false);
  const {
    dispatch,
    getUserRoleId,
    getDaDetails,
    getUserId,
    getSearchStudentListSort,
    getTabChange,
    getPaginationControllerState,
    getUserGuId,
    getBreadCrumbsData
  } = props;
  const [data, setData] = useState({
    page: 0,
    size: 0,
    pageSize: 0,
    values: []
  });
  const [emptyTableText, setEmptyTableText] = useState<JSX.Element>();
  const [selected, setSelected] = React.useState<string[]>([]);
  const sortBy = useRef(getSearchStudentListSort.name);
  const sortOrder = useRef(getSearchStudentListSort.val);
  const [studentNameBool, setStudentNameBool] = useState<boolean | null>(null);
  const [usernameBool, setUsernameBool] = useState<boolean | null>(null);
  const [gradeLevelBool, setGradeLevelBool] = useState<boolean | null>(null);
  const [schoolBool, setSchoolBool] = useState<boolean | null>(null);
  const [statusBool, setStatusBool] = useState<boolean | null>(null);
  const columnNames = ["Student Name", "Username", "School", "External ID", "Grade Level", "Status"];
  const rowMapper = [
    ["lastName", "firstName"],
    "loginName",
    "schoolName",
    "districtAssignedId",
    "gradeLevelId",
    "isEnabled"
  ];
  const grades = ["K", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "OTHER"];
  const [pageNo, setPageNo] = useState(1);
  const elementRef = useRef(null);
  const [tableHgt, setTableHgt] = useState(0);

  const headersForCsv = [
    {
      label: "School Id",
      id: "schoolId"
    },
    {
      label: "Student Id",
      id: "studentId"
    },
    {
      label: "Student Last Name",
      id: "lastName"
    },
    {
      label: "Student First Name",
      id: "firstName"
    },
    {
      label: "Username",
      id: "userName"
    },
    {
      label: "External ID",
      id: "externalId"
    },
    {
      label: "Grade Level",
      id: "gradeLevel"
    },
    {
      label: "Status",
      id: "status"
    }
  ];

  const helpSearch = (
    <div className="emptyContentWrapper">
      <img src={HelpIcon} className="resultIcon" />
      <div className="helpMessage">No Results Found </div>
      <div className="helpcenter">
        Try modifying the search terms or use the{" "}
        <a href="https://help.edgenuitymypath.com/hc/en-us/articles/4412008995479" target="_blank">
          tips
        </a>{" "}
        in this Help Center article.
      </div>
    </div>
  );

  async function callback(args: number) {
    let updateState = getPaginationControllerState;
    setPageNo(1);
    updateState[0] = { paginationPageName: "Students", paginationCount: args };
    dispatch(updatePaginationControllerState(updateState));
    try {
      dispatch(updateActivityIndicator(true));
      UserService.updatePaginationCount(getUserId, 1, args).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            searchStudents(1);
          } else {
            dispatch(updateActivityIndicator(false));
          }
        }
      );
    } catch (err) {
      dispatch(updateActivityIndicator(false));
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight > 650) {
        //@ts-ignore
        setTableHgt(
          //@ts-ignore
          parseInt(window.innerHeight - (elementRef.current.offsetTop + 90))
        );
      } else {
        //@ts-ignore
        setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
      }
      //@ts-ignore
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    if (window.innerHeight > 650) {
      //@ts-ignore
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef.current.offsetTop + 90))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
    }
  }, [getTabChange]);

  useEffect(() => {
    getSearchStudentListSortFunc();
    localStorage.setItem("page", "studentsRoster");
  }, []);

  const getSearchStudentListSortFunc = () => {
    if (getSearchStudentListSort.val === "desc" || getSearchStudentListSort.val === "DESC") {
      switch (getSearchStudentListSort.name) {
        case "lastname":
          setStudentNameBool(true);
          break;
        case "loginname":
          setUsernameBool(true);
          break;
        case "schoolname":
          setSchoolBool(true);
          break;
        case "gradelevelname":
          setGradeLevelBool(true);
          break;
        default:
          setStatusBool(true);
      }
    } else if (getSearchStudentListSort.val === "asc" || getSearchStudentListSort.val === "ASC") {
      switch (getSearchStudentListSort.name) {
        case "lastname":
          setStudentNameBool(false);
          break;
        case "loginname":
          setUsernameBool(false);
          break;
        case "schoolname":
          setSchoolBool(false);
          break;
        case "gradelevelname":
          setGradeLevelBool(false);
          break;
        default:
          setStatusBool(false);
      }
    }
  };

  function handleTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.charAt(0) !== " ") {
      setSearchText(event.target.value);
    }
  }

  function handleSearchButtonClick(): void {
    if (searchText !== "") {
      searchStudents(1);
      setSearchResult(true);
      setShowTable(true);
      setPageNo(1);
    }
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key === "Enter" && searchText !== "") {
      searchStudents(1);
      setSearchResult(true);
      setShowTable(true);
      setPageNo(1);
    }
  }

  function viewStudent(row: RowProps) {
    dispatch(updateCurrentSchoolID(row.orgId));
    dispatch(updateCurrentSchoolGuid(row.orgGuid));
    dispatch(updateActivityIndicator(true));
    navigate(`/viewstudent/${row.orgGuid}_${row.userGuid}`);
  }

  function getSearchRequest(pagenumber: number) {
    let jsonObj;
    if (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) {
      if (id === undefined) {
        jsonObj = {
          Page: pagenumber,
          PageSize: getPaginationControllerState[0].paginationCount,
          SearchText: searchText,
          SortBy: sortBy.current,
          SortOrder: sortOrder.current
        };
      } else {
        jsonObj = {
          Page: pagenumber,
          PageSize: getPaginationControllerState[0].paginationCount,
          SearchText: searchText,
          OrgGuid: id,
          UserGuid: getUserGuId,
          SearchingInsideDistrict: true,
          SortBy: sortBy.current,
          SortOrder: sortOrder.current
        };
      }
    } else if (getUserRoleId === 3) {
      let oid;
      if (id === undefined) {
        oid = getDaDetails.id;
      } else {
        oid = id;
      }
      jsonObj = {
        Page: pagenumber,
        PageSize: getPaginationControllerState[0].paginationCount,
        SearchText: searchText,
        OrgGuid: oid,
        UserGuid: getUserGuId,
        SearchingInsideDistrict: true,
        SortBy: sortBy.current,
        SortOrder: sortOrder.current
      };
    } else {
      jsonObj = {
        Page: pagenumber,
        PageSize: getPaginationControllerState[0].paginationCount,
        SearchText: searchText,
        UserGuid: getUserGuId,
        SearchingInsideDistrict: false,
        SortBy: sortBy.current,
        SortOrder: sortOrder.current
      };
    }
    return jsonObj;
  }

  async function searchStudents(pagenumber: number) {
    try {
      dispatch(updateActivityIndicator(true));
      setSearchResult(true);
      dispatch(
        updateSearchStudentListSort({
          name: sortBy.current,
          val: sortOrder.current
        })
      );

      const request = getSearchRequest(pagenumber);

      await UserService.searchStudentsWithGuid(request).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          gotResponse(data);
        } else {
          dispatch(updateActivityIndicator(false));
          setEmptyTableText(helpSearch);
          setData({
            page: 0,
            size: 0,
            pageSize: 0,
            values: []
          });
        }
      });
    } catch (err) {
      //do nothing
    }
  }

  async function gotResponse(responsedata: Response) {
    if (window.innerHeight > 650) {
      //@ts-ignore
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef.current.offsetTop + 90))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
    }
    let allData = JSON.parse(await responsedata.text());
    if (sortBy.current === "lastname") {
      setUsernameBool(null);
      setGradeLevelBool(null);
      setStatusBool(null);
      setSchoolBool(null);
    } else if (sortBy.current === "loginname") {
      setStudentNameBool(null);
      setGradeLevelBool(null);
      setStatusBool(null);
      setSchoolBool(null);
    } else if (sortBy.current === "schoolname") {
      setStudentNameBool(null);
      setUsernameBool(null);
      setGradeLevelBool(null);
      setStatusBool(null);
    } else if (sortBy.current === "gradelevelname") {
      setStudentNameBool(null);
      setUsernameBool(null);
      setStatusBool(null);
      setSchoolBool(null);
    } else {
      setStudentNameBool(null);
      setUsernameBool(null);
      setGradeLevelBool(null);
      setSchoolBool(null);
    }
    if (allData.size === 0) {
      setEmptyTableText(helpSearch);
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });
    } else {
      let students = {
        page: allData.page,
        size: allData.size,
        pageSize: allData.pageSize,
        values: []
      };
      for (let x in allData.values) {
        let val = allData.values[x];
        val.gradeLevelId = allData.values[x].gradeLevelId === -1 ? "PK" : grades[allData.values[x].gradeLevelId - 1];
        //@ts-ignore
        students.values.push(val);
      }
      setData(students);
    }
    dispatch(updateActivityIndicator(false));
  }

  async function getCSVData(csvCallback: (data: any) => void) {
    dispatch(updateActivityIndicator(true));
    const request = getSearchRequest(1);
    try {
      const { status, data } = await UserService.getStudentRosterUserlevelCSVData(request);
      if (status === 200) {
        const csvData = JSON.parse(await data.text());
        csvCallback({rows: csvData, columns: headersForCsv});
      }
      dispatch(updateActivityIndicator(false));
    } catch (err) {
      dispatch(updateActivityIndicator(false));
    }
  }

  function renderCancelButton() {
    if (searchText !== "" && !searchResult) {
      return <img src={TimesIcon} alt="" width="20px" height="20px" />;
    } else if (searchText !== "" && searchResult) {
      return (
        <div className="searchIconText">
          <span> Clear Results </span>
        </div>
      );
    }
  }

  function clearButtonClick() {
    if (searchText !== "" && !searchResult) {
      setSearchText("");
    } else if (searchText !== "" && searchResult) {
      setSearchText("");
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });
      setSearchResult(false);
      setShowTable(false);
      setPageNo(1);
    }
  }

  const Styles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        "& > *": {
          marginTop: theme.spacing(2)
        }
      }
    })
  );
  let classes = Styles();

  function sortChange(type: string) {
    switch (type) {
      case "Student Name":
        sortOrder.current = !studentNameBool ? "desc" : "asc";
        sortBy.current = "lastname";
        break;
      case "Username":
        sortOrder.current = !usernameBool ? "desc" : "asc";
        sortBy.current = "loginname";
        break;
      case "School":
        sortOrder.current = !schoolBool ? "desc" : "asc";
        sortBy.current = "schoolname";
        break;
      case "Grade Level":
        sortOrder.current = !gradeLevelBool ? "desc" : "asc";
        sortBy.current = "gradelevelname";
        break;
      default:
        sortOrder.current = !statusBool ? "desc" : "asc";
        sortBy.current = "isenabled";
    }
    searchStudents(1);
    setPageNo(1);
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    searchStudents(value);
    setPageNo(value);
  };

  function renderSearchButton() {
    return <UxIcon icon={UxIconEnum.SEARCH} size={UxSizeEnum.MEDIUM} className="searchIcon" />;
  }

  const searchResultSchoolInputClass = () => {
    if (!searchResult) {
      return "schoolFullInputTextMiddle";
    } else {
      return "schoolInputTextMiddle";
    }
  };
  const searchTextSchoolInputClass = () => {
    if (searchText) {
      return "schoolFullInputTextTop";
    } else {
      return "schoolInputTextTop";
    }
  };

  return (
    <div className="schools">
      {props.showTitle && <UxTitleBar title="Student Roster" />}
      {((!showTable && (getUserRoleId === 3 || getUserRoleId === 4)) ||
        ((getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) &&
          window.location.pathname.includes("institutions"))) && (
        <div className="search-csv-export-container">
          <MpngExportReport
            reportName={
              "Student Roster_" +
              (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7
                ? getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name.replace(/[.]/g, " ")
                : getDaDetails.name.replace(/[.]/g, " ")) +
              "_" +
              (new Date(`${new Date()}`).getUTCMonth() + 1) +
              "_" +
              new Date(`${new Date()}`).getUTCDate() +
              "_" +
              new Date(`${new Date()}`).getUTCFullYear() +
              "_" +
              Date.now()
            }
            includePDF={false}
            csvCallback={getCSVData}
          />
        </div>
      )}
      <div className={!showTable ? "searchMiddle" : "searchTop"}>
        {!showTable ? <span className="searchHeading">Search For Students</span> : null}
        <UxSearchInput
          inputClassName={showTable === false ? searchResultSchoolInputClass() : searchTextSchoolInputClass()}
          flexClassName={showTable === false ? "schoolFlexHolderMiddle" : "schoolFlexHolderTop"}
          placeHolder="Search Student Name, Username, External ID"
          value={searchText}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e)}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            handleKeyPress(e);
          }}
          searchButtonClassName="searchButton"
          searchButtonContent={renderSearchButton()}
          onSearchClick={() => {
            handleSearchButtonClick();
          }}
          clearButtonClassName="clearButton"
          clearButtonContent={renderCancelButton()}
          onClearClick={() => {
            clearButtonClick();
          }}
        />
        {showTable && (getUserRoleId === 3 || getUserRoleId === 4) && (
          <div className="search-csv-export-container">
            <MpngExportReport
              reportName={
                "Student Roster_" +
                getDaDetails.name.replace(/[.]/g, " ") +
                "_" +
                (new Date(`${new Date()}`).getUTCMonth() + 1) +
                "_" +
                new Date(`${new Date()}`).getUTCDate() +
                "_" +
                new Date(`${new Date()}`).getUTCFullYear() +
                "_" +
                Date.now()
              }
              includePDF={false}
              csvCallback={getCSVData}
            />
          </div>
        )}
      </div>
      <div ref={elementRef}>
        {showTable ? (
          <UxMUITable
            MUITableClassName={data.values.length === 0 ? "emptyTable1" : "StudentsscrollableTable"}
            columnNames={columnNames}
            rowMapper={rowMapper}
            rowData={(data as IStudents).values}
            showColumnCheckbox={false}
            viewChild={viewStudent}
            emptyTableText={emptyTableText}
            checkboxRef="loginName"
            selected={selected}
            setSelected={setSelected}
            mtableHeight={tableHgt}
            showHelpIconFlag={true}
            showSortList={[true, true, true, false, true, true]}
            disableKebabMenuOverValue="isSynced"
            removeEditabilityFlag={true}
            sortListMapper={{
              "Student Name": [
                studentNameBool,
                setStudentNameBool,
                (args: string) => {
                  sortChange(args);
                }
              ],
              Username: [
                usernameBool,
                setUsernameBool,
                (args: string) => {
                  sortChange(args);
                }
              ],
              School: [
                schoolBool,
                setSchoolBool,
                (args: string) => {
                  sortChange(args);
                }
              ],
              "Grade Level": [
                gradeLevelBool,
                setGradeLevelBool,
                (args: string) => {
                  sortChange(args);
                }
              ],
              Status: [
                statusBool,
                setStatusBool,
                (args: string) => {
                  sortChange(args);
                }
              ]
            }}
          />
        ) : null}
      </div>
      {showTable ? (
        <div className="fixedpagination">
          <UxPaginate
            data={data}
            page={pageNo}
            paginationSize={getPaginationControllerState[0].paginationCount}
            className={classes.root}
            handleChange={handleChange}
          />
          {data.size > 50 && (
            <UxPaginationController
              rowsList={paginationControllers}
              setRowsCount={getPaginationControllerState[0].paginationCount}
              callback={callback}
            />
          )}
        </div>
      ) : null}
    </div>
  );
}

export default connect(mapStateToProps)(StudentList);
