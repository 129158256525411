import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { UxTabs } from "@wne-spa/ux-component-library";
import Classes from "../ViewCustomer/Classes";
import StudentsTab from "../Students/StudentTab";
import Educators from "../EducatorsList/EducatorsList";
import Settings from "../ViewCustomer/Settings";
import {
  updateActivityIndicator,
  updateBreadCrumbs,
  updatePageTitle,
  updateTabChange,
  updateTabName,
  updateAssessmentTab,
  updatePreviousURL,
  updateFromSideBarAssessment,
  updateReportCard,
  updateLevel,
  updateStudentIds,
  updateStudentsSubtab,
  updateStudentAndClassFilterURL,
  updateSchoolGuids,
  updateIsOrganizationActive,
  updateCurrentOrganizationDetails
} from "../../Redux/Action";
import { AdminService } from "../../services/helpers/api.services";
import ReportsTab from "../reports/ReportsTab";
import AssessmentCards from "../ViewCustomer/AssessmentCards";
import GetToken from "../../authService/GetToken";
import Sessions from "../AcademicSessions/AcademicSessions";
import ReduxProps, { SchoolGuidsResponseProps, OrganizationDetails } from "Redux/Redux.props";
import { AppDispatch } from "Redux/Store";
import { RootComponentProps } from "../../root-component.props";
import StudentDropdownTab from "../HeaderTabs/StudentDropdownTab";
import AssessmentDropdownTab from "../HeaderTabs/AssessmentDropdownTab";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getDaDetails: state.getDaDetails,
    getUserRoleId: state.getUserRoleId,
    getTabChange: state.getTabChange,
    getTabName: state.getTabName,
    getStudentSubtab: state.getStudentSubtab,
    getAssessmentTab: state.getAssessmentTab,
    getClassFilter: state.getClassFilter,
    getSchoolGuids: state.getSchoolGuids,
    getIsMyPathOnly: state.getIsMyPathOnly
  };
}
interface DASchoolProps {
  dispatch: AppDispatch;
}

interface Props extends DASchoolProps, ReduxProps, RootComponentProps {}

function DASchool(props: Props) {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const [tabKey, setTabKey] = useState("classes");
  const [schoolName, setSchoolName] = useState("");

  const [selectedStudentView, setSelectedStudentView] = useState("home");

  const {
    dispatch,
    getDaDetails,
    getBreadCrumbsData,
    getUserRoleId,
    getTabChange,
    getTabName,
    getStudentSubtab,
    getAssessmentTab,
    schedulerAssessmentSubject,
    MyPathLevelSubject,
    BenchmarkGrowthReportBreadcrumbSubject,
    getSchoolGuids,
    ActivityIndicatorSubject,
    dsdsReportSubject
  } = props;

  const tabArrayDASchool = [
    "classes",
    "students",
    "educators",
    "Assessments",
    "reports",
    "AcademicSessions",
    "settings"
  ];

  useEffect(() => {
    dispatch(updateTabChange(!getTabChange));
    // eslint-disable-next-line
  }, [tabKey]);
  useEffect(() => {
    dispatch(updateStudentIds([]));
  }, []);
  useEffect(() => {
    let apiObj = { guid: id.toString() };
    AdminService.getGUIDSWithGuid(apiObj).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        responseGUIDS(data);
      } else {
        // do nothing
      }
    });

    dispatch(updateFromSideBarAssessment(false));
    if (getTabName === "reports") {
      isAssessmentTabClicked(getTabName);
    }
    dispatch(updateTabName(tabKey));
    if (getTabName != "reports") {
      dispatch(updateTabName(null));
    }
    setTabKey(getTabName ? getTabName : tabKey);
    setSelectedStudentView(getStudentSubtab);
    dispatch(updateStudentsSubtab("home"));
  }, [id]);

  useEffect(() => {
    if (getTabName === null || getTabName === "") {
      localStorage.setItem("page", tabKey);
    } else {
      localStorage.setItem("page", getTabName);
    }
    dispatch(updateLevel("school"));

    dispatch(updateStudentAndClassFilterURL(window.location.pathname));
    dispatch(updateTabName(tabKey));
    if (!tabArrayDASchool.includes(getTabName) || getTabName === null) {
      setTabKey("classes");
    } else {
      setTabKey(getTabName);
    }
  }, []);

  async function responseGUIDS(responsedata: Response) {
    let allData = await responsedata.json();
    let exist = getSchoolGuids.filter((item: SchoolGuidsResponseProps) => item.id?.toString() === id);
    if (exist.length === 0) {
      let updatedSchoolGuids = [];
      updatedSchoolGuids = [...updatedSchoolGuids, allData[0]];
      dispatch(updateSchoolGuids(updatedSchoolGuids));
    }
    schedulerAssessmentSubject.next({
      token: GetToken(),
      guid: allData[0].orgGUID
    });
    dispatch(updateIsOrganizationActive(allData[0].isActive));
  }

  useEffect(() => {
    const fetchOrgDetails = async () => {
      try {
        const { status, data } = await AdminService.getCurrentOrganizationDetails(id);
        if (status === 200) {
          const details: OrganizationDetails = await data.json();
          dispatch(updateCurrentOrganizationDetails(details));
        }
      } catch (err) {
        console.error("Failed to fetch organization details", err);
      }
    };
    const fetchBreadCrumbs = async () => {
      try {
        let apiObj = { rid: getDaDetails.id, cid: id };
        await AdminService.getParentsWithRootWithGuid(apiObj).then(
          ({ status, data }: { status: number; data: Response }) => {
            if (status === 200) {
              handleSuccessResponse(data);
            } else {
              dispatch(updateActivityIndicator(false));
            }
          }
        );
      } catch (err) {
        //do nothing
      }
    };
    dispatch(updateActivityIndicator(true));
    fetchBreadCrumbs();
    fetchOrgDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  async function handleSuccessResponse(responsedata: Response) {
    let details = await responsedata.json();
    //@ts-ignore
    if (details.parents === null) {
      //@ts-ignore
      const breadcrumb = {
        crumbs: [{ path: `/dainstitutions/${id}`, name: details.name }]
      };
      dispatch(updateBreadCrumbs(breadcrumb));
    } else {
      let breadcrumb = { crumbs: [] };
      //@ts-ignore
      const crumbs = details.parents.reverse();
      for (let x of crumbs) {
        //@ts-ignore
        breadcrumb.crumbs.push({
          //@ts-ignore
          path: `/dainstitutions/${x.orgGUID}`,
          //@ts-ignore
          name: x.name
        });
      }
      //@ts-ignore
      breadcrumb.crumbs.push({
        //@ts-ignore
        // path: `/dainstitutions/${details.id}`,
        //@ts-ignore
        path: `/daschool/${details.orgGUID}`,
        // path: `/daschool/${details.id}`,
        //@ts-ignore
        name: details.name
      });
      dispatch(updateBreadCrumbs(breadcrumb));
      //@ts-ignore
      setSchoolName(details.name);
    }
    dispatch(updateActivityIndicator(false));
  }

  useEffect(() => {
    if ((getDaDetails.type === 1 || getDaDetails.type === 2) && getUserRoleId === 3) {
      if (getBreadCrumbsData.crumbs[0].name !== "") {
        if (getDaDetails.type === 1) {
          dispatch(
            updatePageTitle(`- Schools - ${getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name}`)
          );
        } else {
          dispatch(
            updatePageTitle(`- Districts - ${getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name}`)
          );
        }
      }
    }
    // eslint-disable-next-line
  }, [getDaDetails, getBreadCrumbsData]);

  const setPage = (pageName: string) => {
    dispatch(updateAssessmentTab("home"));
    setSelectedStudentView(pageName);
    setTabKey("students");
  };

  function setAssessmentTab(tabName: string) {
    dispatch(updateAssessmentTab(tabName.toString()));
    if (tabName === "schedule") {
      dispatch(updatePreviousURL(window.location.pathname));
    }
    setTabKey("Assessments");
  }

  let tabs = [
    {
      eventKey: "classes",
      title: "Classes",
      content: <Classes />
    },
    {
      eventKey: "students",
      tabClassName: "student-tab",
      title: <StudentDropdownTab onNavClick={setPage} currentView={selectedStudentView} />,
      content: (
        <StudentsTab isfrom="school" orgLevel="school" selectedView={selectedStudentView} setSelectedView={setSelectedStudentView} />
      )
    },
    {
      eventKey: "educators",
      title: "Educators",
      content: <Educators isfrom="school" schoolname={schoolName} />
    },
    ...(props.getIsMyPathOnly === false ? [{
      eventKey: "Assessments",
      tabClassName: "assessments-tab",
      title: <AssessmentDropdownTab onNavClick={setAssessmentTab} />,
      content: <AssessmentCards id={id} isFromSideBarAssessment={false} />
    }] : []),
    {
      eventKey: "reports",
      title: "Reports",
      content: (
        <ReportsTab
          id={id}
          isfrom="school"
          MyPathLevelSubject={MyPathLevelSubject}
          BenchmarkGrowthReportBreadcrumbSubject={BenchmarkGrowthReportBreadcrumbSubject}
          ActivityIndicatorSubject={ActivityIndicatorSubject}
          dsdsReportSubject={dsdsReportSubject}
        />
      )
    },
    {
      eventKey: "AcademicSessions",
      title: "Academic Sessions",
      content: <Sessions isFrom="school" />
    },
    {
      eventKey: "settings",
      title: "Settings",
      content: <Settings isfrom="school" />
    }
  ];

  function isAssessmentTabClicked(tab: string) {
    if (tab === "students" || tab === "Assessments") {
      return;
    }

    setSelectedStudentView("home");
    if (tab === "reports") {
      dispatch(updateReportCard("home"));
    }

    dispatch(updatePreviousURL(window.location.pathname));
    setTabKey(tab);
  }

  return (
    <div className="class-nav-tabs">
      <UxTabs
        tabsId="class-tabs"
        activeKey={tabKey}
        onTabSelect={(tab: string) => {
          isAssessmentTabClicked(tab);
          localStorage.setItem("page", tab);
        }}
        children={tabs}
        className="pt-2"
        currentKey={tabKey}
      />
    </div>
  );
}

export default connect(mapStateToProps)(DASchool);
