import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { UxTabs } from "@wne-spa/ux-component-library";
import Classes from "../ViewCustomer/Classes";
import Educators from "../EducatorsList/EducatorsList";
import Settings from "../Settings/Settings";
import StudentTab from "../Students/StudentTab";
import AssessmentCards from "../../components/ViewCustomer/AssessmentCards";
import Sessions from "../AcademicSessions/AcademicSessions";
import {
  updateActivityIndicator,
  updateBreadCrumbs,
  updatePageTitle,
  updateTabChange,
  updateAssessmentTab,
  updatePreviousURL,
  updateFromSideBarAssessment,
  updateReportCard,
  updateLevel,
  updateStudentIds,
  updateSchoolGuids,
  updateStudentAndClassFilterURL,
  updateStudentsSubtab,
  updateTabName,
  updateIsOrganizationActive,
  updateOrgLoginCodeAvailable,
  updateIsOrgRegistrationOwned,
  updateCurrentOrganizationDetails
} from "../../Redux/Action";
import { AdminService } from "../../services/helpers/api.services";
import ReportsTab from "../reports/ReportsTab";
import GetToken from "../../authService/GetToken";
import ReduxProps, { SchoolGuidsResponseProps, OrganizationDetails } from "../../Redux/Redux.props";
import { SchoolProps } from "./School.props";
import { RootComponentProps } from "root-component.props";
import { getIsOrgGuidPresent } from "../../helpers/orgGuidList";
import StudentDropdownTab from "../HeaderTabs/StudentDropdownTab";
import AssessmentDropdownTab from "../HeaderTabs/AssessmentDropdownTab";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getTabChange: state.getTabChange,
    getTabName: state.getTabName,
    getStudentSubtab: state.getStudentSubtab,
    getAssessmentTab: state.getAssessmentTab,
    getClassFilter: state.getClassFilter,
    getStudentAndClassFilterURL: state.getStudentAndClassFilterURL,
    getSchoolGuids: state.getSchoolGuids,
    getIsOrgHasRegistrationOwned: state.getIsOrgHasRegistrationOwned,
    getIsMyPathOnly: state.getIsMyPathOnly
  };
}

interface Props extends SchoolProps, ReduxProps, RootComponentProps {}

function School(props: Props): JSX.Element {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const [tabKey, setTabKey] = useState("classes");
  const [schoolName, setSchoolName] = useState("");
  const [selectedStudentView, setSelectedStudentView] = useState("home");

  const {
    dispatch,
    getTabChange,
    getTabName,
    getStudentSubtab,
    getAssessmentTab,
    schedulerAssessmentSubject,
    MyPathLevelSubject,
    BenchmarkGrowthReportBreadcrumbSubject,
    getSchoolGuids,
    ActivityIndicatorSubject,
    getIsOrgHasRegistrationOwned
  } = props;

  useEffect(() => {
    // dispatch(updateActivityIndicator(true))
    dispatch(updateTabChange(!getTabChange));
    // eslint-disable-next-line
  }, [tabKey]);
  useEffect(() => {
    dispatch(updateStudentAndClassFilterURL(window.location.pathname));
    dispatch(updateStudentIds([]));
    dispatch(updateTabName(tabKey));
    let orgids = { guid: id.toString() };
    AdminService.getLogincodeWithGuid(orgids).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        gotLogincodeResponse(data);
      } else {
        // do nothing
      }
    });
  }, []);
  useEffect(() => {
    //Reset the student placement & classes Subject filter as per ALABlue-511
    let apiObj = { guid: id.toString() };
    AdminService.getGUIDSWithGuid(apiObj).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        responseGUIDS(data);
      } else {
        // do nothing
      }
    });

    dispatch(updateFromSideBarAssessment(false));

    setTabKey(getTabName || tabKey);
    if (getTabName === "reports") {
      isAssessmentTabClicked(getTabName);
    }
    if (getTabName !== "reports") {
      dispatch(updateTabName(null));
    }

    if (getTabName === null || getTabName === "") {
      localStorage.setItem("page", tabKey);
    } else {
      localStorage.setItem("page", getTabName);
    }
    setSelectedStudentView(getStudentSubtab);

    if(getTabName !== "Assessments"){
      dispatch(updateAssessmentTab("home"));
    }
  }, [id]);

  async function responseGUIDS(responsedata: Response) {
    let allData = JSON.parse(await responsedata.text());
    let exist = getSchoolGuids.filter((item: SchoolGuidsResponseProps) => item.id === Number(id));
    if (exist.length === 0) {
      let updatedSchoolGuids: SchoolGuidsResponseProps[] = [];
      updatedSchoolGuids = [...updatedSchoolGuids, allData[0]];
      dispatch(updateSchoolGuids(updatedSchoolGuids));
    }
    schedulerAssessmentSubject.next({
      token: GetToken(),
      guid: allData[0].orgGUID
    });
    dispatch(updateIsOrganizationActive(allData[0].isActive));
    dispatch(updateIsOrgRegistrationOwned(allData[0]?.isRegistrationOwned));
  }

  useEffect(() => {
    const fetchOrgDetails = async () => {
      try {
        const { status, data } = await AdminService.getCurrentOrganizationDetails(id);
        if (status === 200) {
          const details: OrganizationDetails = await data.json();
          dispatch(updateCurrentOrganizationDetails(details));
        }
      } catch (err) {
        console.error("Failed to fetch organization details", err);
      }
    };
    const fetchBreadCrumbs = async () => {
      try {
        let apiObj = { guid: id };
        AdminService.getParentsWithGuid(apiObj).then(({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            handleSuccessResponse(data);
          } else {
            dispatch(updateActivityIndicator(false));
            // do nothing
          }
        });
      } catch (err) {
        //do nothing
      }
    };
    dispatch(updateActivityIndicator(true));
    dispatch(updateLevel("school"));
    fetchBreadCrumbs();
    fetchOrgDetails();
    // eslint-disable-next-line
  }, [dispatch, id]);

  async function gotLogincodeResponse(responsedata: Response) {
    let details = await responsedata.json();
    if (details[0].loginCode === "") {
      dispatch(updateOrgLoginCodeAvailable(false));
    } else {
      dispatch(updateOrgLoginCodeAvailable(true));
    }
  }
  const isAssessmentTabClicked = (tab: string) => {
    if (tab === "students" || tab === "Assessments") {
      return;
    }

    setSelectedStudentView("home");
    if (tab === "reports") {
      dispatch(updateReportCard("home"));
    }
    dispatch(updatePreviousURL(window.location.pathname));
    setTabKey(tab);
  };
  async function handleSuccessResponse(responsedata: Response) {
    let details = JSON.parse(await responsedata.text());
    //@ts-ignore
    setSchoolName(details.name);
    //@ts-ignore
    if (details.parents === null) {
      //@ts-ignore
      const breadcrumb = {
        crumbs: [
          { path: "/customers", name: "Customers" },
          //@ts-ignore
          { path: `/school/${id}`, name: details.name }
        ]
      };
      dispatch(updateBreadCrumbs(breadcrumb));
    } else {
      let breadcrumb = { crumbs: [{ path: "/customers", name: "Customers" }] };
      //@ts-ignore
      const crumbs = details.parents.reverse();
      for (let x of crumbs) {
        //@ts-ignore
        breadcrumb.crumbs.push({
          path: `/institutions/${x.orgGUID}`,
          name: x.name
        });
      }
      //@ts-ignore
      breadcrumb.crumbs.push({
        path: `/school/${details.orgGUID}`,
        name: details.name
      });
      dispatch(updateBreadCrumbs(breadcrumb));
    }
    //@ts-ignore
    dispatch(updatePageTitle(`- Customers - ${details.name}`));
    dispatch(updateStudentsSubtab("home"));
    dispatch(updateActivityIndicator(false));
  }

  const setPage = (pageName: string) => {
    dispatch(updatePreviousURL(window.location.pathname));
    dispatch(updateAssessmentTab("home"));
    setSelectedStudentView(pageName);
    setTabKey("students");
  };

  function setAssessmentTab(tabName: string) {
    dispatch(updatePreviousURL(window.location.pathname));
    dispatch(updateAssessmentTab(tabName.toString()));
    setTabKey("Assessments");
  }

  let tabs = [
    {
      eventKey: "classes",
      title: "Classes",
      content: <Classes />
    },
    {
      eventKey: "students",
      tabClassName: "student-tab",
      title: <StudentDropdownTab onNavClick={setPage} currentView={selectedStudentView} />,
      content: (
        <StudentTab isfrom="school" orgLevel="school" selectedView={selectedStudentView} setSelectedView={setSelectedStudentView} />
      )
    },
    {
      eventKey: "educators",
      title: "Educators",
      content: <Educators isfrom="school" schoolname={schoolName} />
    },
    ...(props.getIsMyPathOnly === false ? [{
      eventKey: "Assessments",
      tabClassName: "assessments-tab",
      title: <AssessmentDropdownTab onNavClick={setAssessmentTab} />,
      content: <AssessmentCards id={id} isFromSideBarAssessment={false} />
    }] : []),
    {
      eventKey: "reports",
      title: "Reports",
      content: (
        <ReportsTab
          id={id}
          isfrom={"school"}
          MyPathLevelSubject={MyPathLevelSubject}
          BenchmarkGrowthReportBreadcrumbSubject={BenchmarkGrowthReportBreadcrumbSubject}
          ActivityIndicatorSubject={ActivityIndicatorSubject}
        />
      )
    },
    {
      eventKey: "AcademicSessions",
      title: "Academic Sessions",
      content: <Sessions isFrom="school" />
    },
    {
      eventKey: "settings",
      title: "Settings",
      content: <Settings isfrom="school" />
    }
  ];

  tabs = getIsOrgGuidPresent(id)
    ? tabs.filter(
        (item) => item.eventKey !== "settings" && item.eventKey !== "Assessments" && item.eventKey !== "reports"
      )
    : tabs;

  return (
    <div className="class-nav-tabs">
      <UxTabs
        tabsId="class-tabs"
        activeKey={tabKey}
        onTabSelect={(tab: string) => {
          isAssessmentTabClicked(tab);
          localStorage.setItem("page", tab);
        }}
        children={tabs}
        className="pt-2"
        currentKey={tabKey}
      />
    </div>
  );
}

export default connect(mapStateToProps)(School);
