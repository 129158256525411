import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { UxTabs } from "@wne-spa/ux-component-library";
import {
  updateActivityIndicator,
  updateTabName,
  updateLSBReport,
  updateStudentsSubtab,
  updateAssessmentTab,
  updateReportCard,
  updateSchoolGuids,
  updateClassViewTab,
  updatePreviousURLForClassProgress
} from "../../Redux/Action";
import { LMSService } from "../../services/helpers/lms.services";
import "./ReportsTab.scss";
import ReduxProps from "../../Redux/Redux.props";
import { ReportsProps, ItemProps, SchoolGuidsResponseProps } from "./Reports.props";
import { RootComponentProps } from "../../root-component.props";
import { AdminService } from "../../services/helpers/api.services";
import StudentDropdownTab from "../HeaderTabs/StudentDropdownTab";
import AssessmentDropdownTab from "../HeaderTabs/AssessmentDropdownTab";

function mapStateToProps(state: ReduxProps) {
  return {
    getTabName: state.getTabName,
    getClassGUID: state.getClassGUID,
    getFlagrKeys: state.getFlagrKeys,
    getLSBReport: state.getLSBReport,
    getBreadCrumbsData: state.getBreadCrumbsData,
    getDistrictLevelFirstTab: state.getDistrictLevelFirstTab,
    getReportsLevel: state.getReportsLevel,
    getUserRoleId: state.getUserRoleId,
    getAssessmentTab: state.getAssessmentTab,
    getSchoolGuids: state.getSchoolGuids,
    getClassViewTab: state.getClassViewTab,
    userRoleDetails: state.userRoleDetails,
    getSchoolGUID: state.getSchoolGUID,
    getIsMyPathOnly: state.getIsMyPathOnly
  };
}

interface TabProps {
  title: JSX.Element | string;
  tabClassName?: string;
  eventKey: string;
  content?: JSX.Element;
  className?: string;
}
interface Props extends ReduxProps, ReportsProps, RootComponentProps {}

const Reports = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const {
    dispatch,
    isfrom,
    getTabName,
    getUserRoleId,
    id,
    getClassGUID,
    getLSBReport,
    getBreadCrumbsData,
    getDistrictLevelFirstTab,
    getSchoolGuids,
    userRoleDetails,
    timeZoneSubject,
    getSchoolGUID
  } = props;

  const [tabKey, setTabKey] = useState("reports");
  const [previousTab, setPreviousTab] = useState("");
  const [, setUrlId] = useState("");
  const [showTabs, setShowTabs] = useState<boolean>(false);
  const [selectedStudentView, setSelectedStudentView] = useState("home");

  useEffect(() => {
    dispatch(updateAssessmentTab("home"));
    if (userRoleDetails) {
      let timeZoneArg = { orgGuid: "" };
      if (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 3 || getUserRoleId === 7) {
        const isOrganizationGuid = Object.keys(getSchoolGuids[0]).indexOf("organizationGuid");
        timeZoneArg = {
          orgGuid: isOrganizationGuid !== -1 ? getSchoolGuids[0]?.organizationGuid : getSchoolGuids[0]?.orgGUID
        };
      } else if (getUserRoleId === 4) {
        timeZoneArg =
          JSON.parse(userRoleDetails)?.orgGuidList?.length > 1
            ? { orgGuid: getSchoolGuids[0]?.orgGUID }
            : { orgGuid: getSchoolGuids[0]?.organizationGuid };
      } else if (getUserRoleId === 5) {
        timeZoneArg =
          JSON.parse(userRoleDetails)?.orgGuidList?.length > 1
            ? {
                orgGuid: getSchoolGUID
              }
            : { orgGuid: getSchoolGuids[0]?.orgGUID };
      }
      getOrgTimezoneApiCall(timeZoneArg);
    }
  }, []);

  const getOrgTimezoneApiCall = (timeZoneArg: { orgGuid: string }) => {
    AdminService.getOrgTimezoneWithGuid(timeZoneArg).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        updateTimeZoneInSubject(data);
      }
    });
  };
  async function updateTimeZoneInSubject(responseData: Response) {
    let timeZoneData = await responseData.json();
    if (timeZoneSubject) {
      timeZoneSubject.next(timeZoneData?.timeZoneName ? timeZoneData?.timeZoneName : "");
    }
  }

  useEffect(() => {
    if (getTabName === "ReportsTab" && isfrom == "class") {
      dispatch(updateLSBReport(false));

      navigate(
        `/reports/usage/class/${getClassGUID}?className=${
          getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name
        }`
      );
      dispatch(updateTabName(""));
      dispatch(updateActivityIndicator(false));
    }
    if (getTabName === "ReportsTab" && isfrom == "school") {
      navigate(
        `/reports/usage/school/${id}?schoolName=${getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name}`
      );
      dispatch(updateLSBReport(false));
      dispatch(updateTabName(""));
      dispatch(updateActivityIndicator(false));
    }
    if (getTabName === "ReportsTab" && isfrom === "institutions") {
      let district = getSchoolGuids.filter(
        (item: SchoolGuidsResponseProps) => item.organizationGuid === id || item.orgGUID === id
      );
      dispatch(updateLSBReport(false));
      if (typeof district[0].orgGUID === "undefined") {
        navigate(
          `/reports/usage/district/${id}?districtName=${
            getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name
          }`
        );
      }
      navigate(
        `/reports/usage/district/${id}?districtName=${
          getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name
        }`
      );
      dispatch(updateTabName(""));
      dispatch(updateActivityIndicator(false));
    }
    setPreviousTabFunc();

    if (window.location.pathname.includes("reports/usage")) {
      dispatch(updateActivityIndicator(false));
    }
  }, [location.pathname, getTabName]);

  const setPreviousTabFunc = () => {
    const currentTab = getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1];

    if (currentTab.path.split("/")[1] === "reports" && currentTab.path.includes("school")) {
      setPreviousTab("school");

      let urlSplit: string[] = currentTab.path.split("/");
      let getUrlId = urlSplit[urlSplit.length - 1].split("?")[0];
      setUrlId(getUrlId);
    } else if (currentTab.path.split("/")[1] === "reports" && currentTab.path.includes("class")) {
      setPreviousTab("class");

      setUrlId(getClassGUID);
    } else if (currentTab.path.split("/")[1] === "reports" && currentTab.path.includes("district")) {
      setPreviousTab("district");

      let urlSplit: string[] = currentTab.path.split("/");
      let getUrlId = urlSplit[urlSplit.length - 1].split("?")[0];
      setUrlId(getUrlId);
    }

    if (location.pathname.split("/")[1] === "reports" && location.pathname.includes("school")) {
      setPreviousTab("school");
    } else if (location.pathname.split("/")[1] === "reports" && location.pathname.includes("class")) {
      setPreviousTab("class");
    } else if (location.pathname.split("/")[1] === "reports" && location.pathname.includes("district")) {
      setPreviousTab("district");
    } else {
      setPreviousTab("student");
    }
  };

  const educatorNavigation = (tab: string) => {
    if (getUserRoleId === 3) {
      if (previousTab === "district") {
        navigate(`/dainstitutions/${params.id}`);
      } else if (previousTab == "school") {
        navigate(`/daschool/${params.id}`);
      } else if (previousTab == "class") {
        getGUIDDetails(tab);
      }
    } else if (getUserRoleId === 4) {
      if (previousTab === "school") {
        navigate(`/saclasslist/${params.id}`);
      } else if (previousTab == "class") {
        getGUIDDetails(tab);
      }
    } else if (getUserRoleId === 5) {
      if (previousTab === "school") {
        navigate(`/teacherclasses/${params.id}`);
      } else if (previousTab == "class") {
        getGUIDDetails(tab);
      }
    }
  };

  const roleWiseNavigation = (tab: string) => {
    if (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) {
      if (previousTab !== "school" && previousTab !== "class") {
        navigate(`/institutions/${params.id}`);
      } else if (previousTab == "school") {
        navigate(`/school/${params.id}`);
      } else if (previousTab == "class") {
        getGUIDDetails(tab);
      }
    } else {
      educatorNavigation(tab);
    }
  };

  const isAssessmentTabClicked = (tab: string, subtab: string = "") => {
    if (tab === "students" || tab === "Assessments") {
      return;
    } else {
      addGUIDS();
      //We are navigating away from reports tab. Reset all the subtabs so tabs aren't highlighted.
      dispatch(updateReportCard("home"));
      dispatch(updateAssessmentTab("home"));
      dispatch(updateStudentsSubtab("home"));

      if (tab === "reports") {
        dispatch(updateReportCard("home"));
      }
      if (tab !== "institutions" && tab !== "school") {
        if (tab !== "classprogress" && tab !== "classsummary") {
          dispatch(updateTabName(tab));
          dispatch(updateClassViewTab(tab));
        }
      } else {
        dispatch(updateTabName("schools"));
      }

      if (tab === "sudo-students") {
        dispatch(updateTabName("students"));
        dispatch(updateStudentsSubtab(subtab));
      }

      if (tab === "sudo-Assessments") {
        dispatch(updateTabName("Assessments"));
        dispatch(updateAssessmentTab(subtab));
      }
      roleWiseNavigation(tab);
    }
    setTabKey(tab);
  };
  const addGUIDS = () => {
    let exist = getSchoolGuids.filter((item: SchoolGuidsResponseProps) => Number(item.id) === Number(`${params.id}`));
    if (exist.length === 0) {
      let guid: string;
      if (new URL(`${window.location}`).searchParams.get("schoolGuid")) {
        guid = new URL(`${window.location}`).searchParams.get("schoolGuid");
      } else if (new URL(`${window.location}`).searchParams.get("districtGuid")) {
        guid = new URL(`${window.location}`).searchParams.get("districtGuid");
      }
      if (guid != "") {
        let guids = getSchoolGuids;
        let details = {};
        details = { ...details, id: `${params.id}`, organizationGuid: guid };
        guids = [...guids, details];
        dispatch(updateSchoolGuids(guids));
      }
    }
  };

  async function getGUIDDetails(tab: string) {
    dispatch(updateActivityIndicator(true));
    await LMSService.getClassGUIDDetails(params.id).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        setGUIDDetails(data, tab);
      } else {
        // do nothing
      }
    });
  }

  async function setGUIDDetails(response: Response, tab: string) {
    let details = await response.json();
    if (tab === "classprogress") {
      dispatch(updatePreviousURLForClassProgress(`/classview/${details.classGUID}`));
      navigate("/classprogress");
    } else if (tab === "classsummary") {
      navigate(`/classsummary/${details.classGUID}`);
    } else {
      navigate(`/classview/${details.classGUID}`);
    }
  }

  const setPage = (pageName: string) => {
    dispatch(updateAssessmentTab("home"));
    setSelectedStudentView(pageName);
    isAssessmentTabClicked("sudo-students", pageName);
  };

  function setAssessmentTab(tabName: string) {
    isAssessmentTabClicked("sudo-Assessments", tabName);
  }

  let classViewTabs: TabProps[] = [
    {
      eventKey: "classsummary",
      tabClassName: "",
      title: "Class Summary"
    },
    {
      eventKey: "classprogress",
      tabClassName: "",
      title: "Class Progress"
    },
    {
      eventKey: "placement",
      tabClassName: "",
      title: "Student Placement"
    },
    {
      eventKey: "roster",
      tabClassName: "",
      title: "Student Roster"
    },
    {
      eventKey: "reports",
      tabClassName: "",
      title: "Reports"
    },
    {
      eventKey: "settings",
      tabClassName: "",
      title: "Settings"
    }
  ];

  let schoolViewTabs: TabProps[] = [
    {
      eventKey: "classes",
      tabClassName: "",
      title: "Classes"
    },
    {
      eventKey: "students",
      tabClassName: "student-tab",
      title: <StudentDropdownTab onNavClick={setPage} currentView={selectedStudentView} />
    },
    {
      eventKey: "educators",
      tabClassName: "",
      title: "Educators"
    },
    ...(props.getIsMyPathOnly === false ? [{
        eventKey: "Assessments",
        tabClassName: "assessments-tab",
        title: <AssessmentDropdownTab onNavClick={setAssessmentTab} />
    }] : []),
    {
      eventKey: "reports",
      tabClassName: "",
      title: "Reports"
    },
    {
      eventKey: "AcademicSessions",
      tabClassName: "",
      title: "Academic Sessions"
    },
    {
      eventKey: "settings",
      tabClassName: "",
      title: "Settings"
    }
  ];

  let institutionsTab: TabProps[] = [
    {
      eventKey: "institutions",
      tabClassName: "",
      title: getDistrictLevelFirstTab
    },
    {
      eventKey: "students",
      tabClassName: "student-tab",
      title: <StudentDropdownTab onNavClick={setPage} currentView={selectedStudentView} />
    },
    {
      eventKey: "educators",
      tabClassName: "",
      title: "Educators"
    },
    ...(props.getIsMyPathOnly === false ? [{
      eventKey: "Assessments",
      tabClassName: "assessments-tab",
      title: <AssessmentDropdownTab onNavClick={setAssessmentTab} />
    }] : []),
    {
      eventKey: "reports",
      tabClassName: "",
      title: "Reports"
    },
    {
      eventKey: "licensing",
      tabClassName: "",
      title: "Licensing"
    },
    {
      eventKey: "AcademicSessions",
      tabClassName: "",
      title: "Academic Sessions"
    },
    {
      eventKey: "settings",
      tabClassName: "",
      title: "Settings"
    }
  ];

  const classPreviousTab = () => {
    if (previousTab === "class") {
      return classViewTabs;
    } else {
      return institutionsTab;
    }
  };
  institutionsTab =
    getUserRoleId === 1 || getUserRoleId === 2
      ? institutionsTab
      : institutionsTab.filter((item) => item.eventKey !== "licensing");

  let tabs = previousTab === "school" ? schoolViewTabs : classPreviousTab();

  setTimeout(() => {
    setShowTabs(true);
  }, 100);

  return (
    <>
      {!getLSBReport && showTabs && previousTab != "student" ? (
        <>
          <UxTabs
            tabsId="class-tabs"
            activeKey={tabKey}
            onTabSelect={(tab: string) => {
              isAssessmentTabClicked(tab);
              localStorage.setItem("page", tab);
            }}
            children={tabs}
            className="pt-2"
            currentKey={tabKey}
          />
          <div
            className="bar"
            style={{
              marginTop: "-1.5rem"
            }}
          >
            <div className="reportsBar">
              <label className="reportsBarTextSchedule">Usage Report</label>
            </div>
            <div>
              <div id="reports"></div>
            </div>
          </div>
        </>
      ) : (
        <div className="bar">
          <div className="reportsBar">
            <label className="reportsBarTextSchedule" style={{ marginLeft: "19px" }}>
              Usage Report
            </label>
          </div>
          <div>
            <div id="reports"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default connect(mapStateToProps)(Reports);
