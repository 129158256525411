import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { IconEnum, ReportLinkCard, ReportLinkCardEnum, Banner } from '@wne/mpng-components';
import "./ReportsTab.scss";
import {
  updateReportCard,
  updateTabName,
  updateBreadCrumbs,
  updateActivityIndicator,
  updatePreviousMyPathURL,
  updatePreviousBenchmarkGrowthReportURL,
  updateLevel,
  updateDSDSReportToken
} from "../../Redux/Action";
import jwt_decode from "jwt-decode";
import Reports from "./Reports";
import { makeLogs } from "react-logger-js";
import { AdminService } from "../../services/helpers/api.services";
import ReduxProps from "../../Redux/Redux.props";
import { ReportsProps, SchoolGuidsResponseProps } from "./Reports.props";
import { RootComponentProps } from "../../root-component.props";
import useHasStandardsProficiencyReportAccess from "../hooks/useHasStandardsProficiencyReportAccess";

function mapStateToProps(state: ReduxProps) {
  return {
    getFlagrKeys: state.getFlagrKeys,
    getReportCard: state.getReportCard,
    getLSBReport: state.getLSBReport,
    getBreadCrumbsData: state.getBreadCrumbsData,
    getUserRoleId: state.getUserRoleId,
    getDaDetails: state.getDaDetails,
    getClassDetails: state.getClassDetails,
    getClassGUID: state.getClassGUID,
    getSchoolGuids: state.getSchoolGuids,
    getDistrictLevelLSBReportsData: state.getDistrictLevelLSBReportsData,
    getUpdateLevel: state.getUpdateLevel,
    isOrganizationActive: state.isOrganizationActive,
    getUserGuId: state.getUserGuId,
    getIsMyPathOnly: state.getIsMyPathOnly,
    getCurrentOrganizationDetails: state.getCurrentOrganizationDetails
  };
}
interface Props extends ReportsProps, ReduxProps, RootComponentProps {}

function ReportsTab(props: Props) {
  const [activateCard, setActivateCard] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [urlId, setURLId] = useState("");
  const [urlName, setURLName] = useState("");
  const {
    getReportCard,
    dispatch,
    isfrom,
    getLSBReport,
    getBreadCrumbsData,
    getUserRoleId,
    getDaDetails,
    isMultiple,
    getFlagrKeys,
    getClassDetails,
    MyPathLevelSubject,
    getClassGUID,
    getSchoolGuids,
    getDistrictLevelLSBReportsData,
    BenchmarkGrowthReportBreadcrumbSubject,
    getUpdateLevel,
    isOrganizationActive,
    getUserGuId
  } = props;
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();

  const navigate = useNavigate();
  const location = useLocation();

  const isSchoolYearLaterThan2025: boolean =  props.getCurrentOrganizationDetails?.academicSession.schoolYear > 2025
  useEffect(() => {
    let level = checkLevel();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    if (level !== "class") {
      getDSDSReportToken(level);
    } else {
      // do nothing
    }
    dispatch(updateLevel(level));

    setActivateCardFunc(level);
    if (getUserRoleId === 5 && getLSBReport) {
      setActivateCard(false);
    }

    (level === "school" || level === "district" || level === "class") &&
    isOrganizationActive !== null &&
    !isOrganizationActive
      ? setIsActive(false)
      : setIsActive(true);

    if (getLSBReport) {
      if (isMultiple) {
        let urlIdName = window.location.pathname.split("/");
        setURLId(urlIdName[2]);
        setURLName(urlIdName[3]);
      }
    }
    dispatch(updateActivityIndicator(false));
  }, [location.pathname, id, dispatch]);

  const setActivateCardFunc = (level: string) => {
    if ((level === "school" || getLSBReport) && getFlagrKeys.MyPathAssessmentSchoolLevelReports) {
      setActivateCard(true);
    } else if (level === "class" && getFlagrKeys.MyPathAssessmentClassLevelReports) {
      setActivateCard(true);
    } else if ((level === "district" || getLSBReport) && getFlagrKeys.MyPathAssessmentDistrictLevelReports) {
      setActivateCard(true);
    } else {
      setActivateCard(false);
    }
  };

  const daGuidDetails = (guid: string) => {
    if (getDaDetails.type === 1) {
      return `${getDaDetails.id}`;
    } else {
      return guid;
    }
  };
  const guidForLSBReport = (guid: string) => {
    if (getLSBReport) {
      return null;
    } else {
      return guid;
    }
  };

  const updateDSDSToken = async (data: Response) => {
    const extractedData = await data.text();
    dispatch(updateDSDSReportToken(extractedData));
    const clear = setInterval(async () => {
      const nbf = (jwt_decode(extractedData) as Record<string, number>).nbf;

      let timeDiff = new Date().getTime() - new Date(nbf * 1000).getTime();
      timeDiff = Math.ceil(timeDiff / 1000 / 60);

      if (timeDiff >= parseInt(window.APP_ENV.token_expiry_time)) {
        clearInterval(clear);
        let level = checkLevel();
        getDSDSReportToken(level);
      }
    }, 1000);
  };

  const getDSDSReportToken = async (level: string) => {
    if (getUserRoleId === 5) {
      return;
    }

    let guid = window.location.pathname.split("/").reverse()[0];
    if (getDaDetails.type === 2 && getUserRoleId === 4 && getLSBReport) {
      guid = window.location.pathname.split("/").reverse()[1];
    }

    const config = { districtGuid: null, schoolGuid: null };
    if (!getLSBReport && level === "district") {
      config.districtGuid = guid;
      config.schoolGuid = null;
    } else {
      const saGuid = getUserRoleId === 4 ? daGuidDetails(guid) : null;
      config.districtGuid = getUserRoleId === 3 ? `${getDaDetails.id}` : null;
      config.schoolGuid = getUserRoleId === 4 ? saGuid : guidForLSBReport(guid);
    }

    const response = getFlagrKeys["DSDSReport-Use_JWT_V2_Token"]
      ? await AdminService.getDSDSReportToken(config)
      : await AdminService.getSisenseReportToken(config);
    if (response.status === 200) {
      updateDSDSToken(response.data);
    }
  };

  function myPathAssessmentReport() {
    if (!activateCard || !isActive) {
      return;
    }
    let level = checkLevel();
    if (getLSBReport) {
      lsbMyPathAssessmentAndGrowthReportURL(getUpdateLevel, true);
      return;
    }
    dispatch(updatePreviousMyPathURL(window.location.pathname));
    dispatch(updateReportCard("mypathassessmentreport"));

    if (level === "school") {
      MyPathLevelSubject.next({
        name: getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name
      });

      let school = getSchoolGuids.filter(
        (item: SchoolGuidsResponseProps) => item.organizationGuid === id || item.orgGUID === id
      );

      if (typeof school[0].orgGUID === "undefined") {
        navigate(`/mypath-assessment-reports/school/${school[0].organizationGuid}`);
      } else {
        navigate(`/mypath-assessment-reports/school/${school[0].orgGUID}`);
      }
    } else if (level === "class") {
      MyPathLevelSubject.next({
        name: getClassDetails.className
      });
      navigate(`/mypath-assessment-reports/class/${getClassGUID}`);
    } else if (level === "district") {
      let school = getSchoolGuids.filter(
        (item: SchoolGuidsResponseProps) => item.organizationGuid === id || item.orgGUID === id
      );
      MyPathLevelSubject.next({
        name: school[0].name
      });

      if (typeof school[0].orgGUID === "undefined") {
        navigate(`/mypath-assessment-reports/district/${school[0].organizationGuid}`);
      } else {
        navigate(`/mypath-assessment-reports/district/${school[0].orgGUID}`);
      }
    } else {
      //do nothing
    }
  }

  function standardProficiencyReport() {
    if (!activateCard || !isActive) {
      return;
    }
    let level = checkLevel();

    if (getLSBReport) {
      lsbCumulativeStandardsProficiencyReportURL(level);
      return;
    }

    dispatch(updatePreviousMyPathURL(window.location.pathname));
    dispatch(updateReportCard("cumulativestandardsproficiency"));

    if (level === "school") {
      let school = getSchoolGuids.filter(
        (item: SchoolGuidsResponseProps) => item.organizationGuid === id || item.orgGUID === id
      );

      if (typeof school[0].orgGUID === "undefined") {
        navigate(`/cumulativestandardsproficiency/school/${school[0].organizationGuid}`);
      } else {
        navigate(`/cumulativestandardsproficiency/school/${school[0].orgGUID}`);
      }
    } else if (level === "class") {
      navigate(`/cumulativestandardsproficiency/class/${getClassGUID}`);
    } else if (level === "district") {
      let school = getSchoolGuids.filter(
        (item: SchoolGuidsResponseProps) => item.organizationGuid === id || item.orgGUID === id
      );
      if (typeof school[0].orgGUID === "undefined") {
        navigate(`/cumulativestandardsproficiency/district/${school[0].organizationGuid}`);
      } else {
        navigate(`/cumulativestandardsproficiency/district/${school[0].orgGUID}`);
      }
    } else {
      //do nothing
    }
  }

  function growthReport() {
    if (!activateCard || !isActive) {
      return;
    }
    let level = checkLevel();
    if (getLSBReport) {
      lsbMyPathAssessmentAndGrowthReportURL(getUpdateLevel, false);
      return;
    }
    dispatch(updatePreviousBenchmarkGrowthReportURL(window.location.pathname));
    dispatch(updateReportCard("growthreportcard"));

    if (level === "school") {
      BenchmarkGrowthReportBreadcrumbSubject.next({
        name: getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name
      });
      let school = getSchoolGuids.filter(
        (item: SchoolGuidsResponseProps) => item.organizationGuid || item.orgGUID === id
      );

      if (typeof school[0].orgGUID === "undefined") {
        navigate(`/benchmark-growth-reports/school/${school[0].organizationGuid}`);
      } else {
        navigate(`/benchmark-growth-reports/school/${school[0].orgGUID}`);
      }
    } else if (level === "class") {
      BenchmarkGrowthReportBreadcrumbSubject.next({
        name: getClassDetails.className
      });
      navigate(`/benchmark-growth-reports/class/${getClassGUID}`);
    } else if (level === "district") {
      let school = getSchoolGuids.filter(
        (item: SchoolGuidsResponseProps) => item.orgGUID === id || item.organizationGuid === id
      );
      BenchmarkGrowthReportBreadcrumbSubject.next({
        name: school[0].name
      });

      if (typeof school[0].orgGUID === "undefined") {
        navigate(`/benchmark-growth-reports/district/${school[0].organizationGuid}`);
      } else {
        navigate(`/benchmark-growth-reports/district/${school[0].orgGUID}`);
      }
    } else {
      //do nothing
    }
  }
  function dsdsReport() {
    let level = checkLevel();
    if (getLSBReport) {
      let crumbs = [
        ...getBreadCrumbsData.crumbs,
        {
          path: `${window.location.pathname}`,
          name: "Reports"
        }
      ];
      let persistedReduxData = JSON.parse(localStorage.getItem("persist:ala-admin") || "");
      let getBreadCrumbReduxData = JSON.parse(persistedReduxData?.getBreadCrumbsData);
      let isBenchmarkGrowthReportPathExists = getBreadCrumbReduxData?.crumbs?.findIndex((item) =>
        item?.path?.includes("benchmark-growth-reports")
      );
      if (isBenchmarkGrowthReportPathExists !== -1) {
        crumbs?.splice(isBenchmarkGrowthReportPathExists, 1);
      }
      dispatch(updateBreadCrumbs({ crumbs: crumbs }));
    }

    if (getLSBReport || level !== "class") {
      if (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) {
        navigate(`/dsdsreport/${id}`);
      } else {
        navigate(`/dsdsreport/${getDaDetails.id}`);
      }
    }
  }
  let schoolLevel = ["school", "daschool", "saschool", "saclasslist", "teacherclasses", "saclasses"];
  let districtLevel = ["dainstitutions", "institutions"];
  let classLevel = ["class", "classview"];

  function checkLevel() {
    let urlParts = window.location.pathname.split("/");
    for (let str in urlParts) {
      if (urlParts[str] !== "") {
        if (schoolLevel.includes(urlParts[str])) {
          return "school";
        } else if (classLevel.includes(urlParts[str])) {
          return "class";
        } else if (districtLevel.includes(urlParts[str])) {
          return "district";
        } else {
          return "";
        }
      }
    }
  }

  const hasStandardsReportAccess = useHasStandardsProficiencyReportAccess(
    id,
    getSchoolGuids,
    getFlagrKeys,
    getUserGuId,
    checkLevel()
  );

  function lsbMyPathAssessmentAndGrowthReportURL(level: string, isMyPath: boolean): void {
    if (
      (getUserRoleId === 4 && getDaDetails.type === 2) ||
      (getUserRoleId === 5 && getDaDetails.type === 2 && level === "school")
    ) {
      let school = getSchoolGuids.filter((item: SchoolGuidsResponseProps) => item.organizationGuid === urlId);
      const newCrumbs = [
        ...getBreadCrumbsData.crumbs,
        isMyPath
          ? {
              path: `/mypath-assessment-reports/school/${school[0].organizationGuid}`,
              name: school[0].name
            }
          : {
              path: `/benchmark-growth-reports/school/${school[0].organizationGuid}`,
              name: school[0].name
            }
      ];
      let removeDuplicatesInNewCrumbs = newCrumbs.filter((v, i, a) => a.findIndex((v2) => v2.name === v.name) === i);
      dispatch(updateBreadCrumbs({ crumbs: removeDuplicatesInNewCrumbs }));
      isMyPath
        ? MyPathLevelSubject.next({
            name: school[0].name
          })
        : BenchmarkGrowthReportBreadcrumbSubject.next({
            name: school[0].name
          });

      isMyPath
        ? navigate(`/mypath-assessment-reports/school/${school[0].organizationGuid}`)
        : navigate(`/benchmark-growth-reports/school/${school[0].organizationGuid}`);
    } else if (getUserRoleId === 4 && getDaDetails.type === 1) {
      let school = getSchoolGuids.filter((item: SchoolGuidsResponseProps) => item.organizationGuid === getDaDetails.id);
      makeLogs("data1", school);
      const newCrumbs = [
        ...getBreadCrumbsData.crumbs,
        isMyPath
          ? {
              path: `/mypath-assessment-reports/school/${school[0].organizationGuid}`,
              name: getDaDetails.name
            }
          : {
              path: `/benchmark-growth-reports/school/${school[0].organizationGuid}`,
              name: getDaDetails.name
            }
      ];
      let removeDuplicatesInNewCrumbs = newCrumbs.filter((v, i, a) => a.findIndex((v2) => v2.name === v.name) === i);
      dispatch(updateBreadCrumbs({ crumbs: removeDuplicatesInNewCrumbs }));
      isMyPath
        ? MyPathLevelSubject.next({
            name: getDaDetails.name
          })
        : BenchmarkGrowthReportBreadcrumbSubject.next({
            name: getDaDetails.name
          });
      isMyPath
        ? navigate(`/mypath-assessment-reports/school/${school[0].organizationGuid}`)
        : navigate(`/benchmark-growth-reports/school/${school[0].organizationGuid}`);
    } else if (getUserRoleId === 5 && getDaDetails.type === 1) {
      let teacher = getSchoolGuids.filter(
        (item: SchoolGuidsResponseProps) => item.organizationGuid === getDaDetails.id
      );

      const newCrumbs = [
        ...getBreadCrumbsData.crumbs,
        isMyPath
          ? {
              path: `/mypath-assessment-reports/school/${teacher[0].orgGUID}`,
              name: getDaDetails.name
            }
          : {
              path: `/benchmark-growth-reports/school/${teacher[0].orgGUID}`,
              name: getDaDetails.name
            }
      ];
      let removeDuplicatesInNewCrumbs = newCrumbs.filter((v, i, a) => a.findIndex((v2) => v2.name === v.name) === i);
      dispatch(updateBreadCrumbs({ crumbs: removeDuplicatesInNewCrumbs }));
      isMyPath
        ? MyPathLevelSubject.next({
            name: getDaDetails.name
          })
        : BenchmarkGrowthReportBreadcrumbSubject.next({
            name: getDaDetails.name
          });

      isMyPath
        ? navigate(`/mypath-assessment-reports/school/${teacher[0].orgGUID}`)
        : navigate(`/benchmark-growth-reports/school/${teacher[0].orgGUID}`);
    } else if (getUserRoleId === 3) {
      let district = getSchoolGuids.filter(
        (item: SchoolGuidsResponseProps) => item.organizationGuid === getDaDetails.id
      );
      if (district.length === 0) {
        district = [...district, getDistrictLevelLSBReportsData];
      }

      const newCrumbs = [
        ...getBreadCrumbsData.crumbs,
        isMyPath
          ? {
              path: `/mypath-assessment-reports/district/${district[0].orgGUID}`,
              name: getDaDetails.name
            }
          : {
              path: `/benchmark-growth-reports/district/${district[0].orgGUID}`,
              name: getDaDetails.name
            }
      ];
      let removeDuplicatesInNewCrumbs = newCrumbs.filter((v, i, a) => a.findIndex((v2) => v2.name === v.name) === i);
      dispatch(updateBreadCrumbs({ crumbs: removeDuplicatesInNewCrumbs }));
      isMyPath
        ? MyPathLevelSubject.next({
            name: getDaDetails.name
          })
        : BenchmarkGrowthReportBreadcrumbSubject.next({
            name: getDaDetails.name
          });
      isMyPath
        ? navigate(`/mypath-assessment-reports/district/${district[0].orgGUID}`)
        : navigate(`/benchmark-growth-reports/district/${district[0].orgGUID}`);
    }
  }

  function usageReport() {
    if (getLSBReport) {
      lsbReportURL();
    } else {
      dispatch(updateReportCard("usagereport"));
      dispatch(updateTabName("ReportsTab"));
    }
  }

  function lsbReportURL() {
    if ((getUserRoleId === 5 && getDaDetails.type == 2) || (getUserRoleId === 4 && getDaDetails.type == 2)) {
      let _school = getSchoolGuids.filter((item: SchoolGuidsResponseProps) => item.organizationGuid === urlId);

      const newCrumbs = [
        ...getBreadCrumbsData.crumbs,
        {
          path: `/reports/usage/school/${urlId}?schoolName=${urlName.split("%20").join(" ")}`,
          name: urlName.split("%20").join(" ")
        }
      ];
      let persistedReduxData = JSON.parse(localStorage.getItem("persist:ala-admin") || "");
      let getBreadCrumbReduxData = JSON.parse(persistedReduxData?.getBreadCrumbsData);
      let isBenchmarkGrowthReportPathExists = getBreadCrumbReduxData?.crumbs?.findIndex((item) =>
        item?.path?.includes("benchmark-growth-reports")
      );
      if (isBenchmarkGrowthReportPathExists !== -1) {
        newCrumbs?.splice(isBenchmarkGrowthReportPathExists, 1);
      }
      dispatch(updateBreadCrumbs({ crumbs: newCrumbs }));
      navigate(`/reports/usage/school/${urlId}?schoolName=${urlName}`, {
        replace: false
      });
    } else if (getUserRoleId === 5 && getDaDetails.type == 1) {
      let _teacher = getSchoolGuids.filter(
        (item: SchoolGuidsResponseProps) => item.organizationGuid === getDaDetails.id
      );

      navigate(`/reports/usage/school/${getDaDetails.id}?schoolName=${getDaDetails.name}`, { replace: false });
    } else if (getUserRoleId === 4 && getDaDetails.type == 1) {
      let _school = getSchoolGuids.filter(
        (item: SchoolGuidsResponseProps) => item.organizationGuid === getDaDetails.id
      );
      const newCrumbs = [
        ...getBreadCrumbsData.crumbs,
        {
          path: `/reportcards`,
          name: "Reports"
        }
      ];
      dispatch(updateBreadCrumbs({ crumbs: newCrumbs }));
      navigate(`/reports/usage/school/${getDaDetails.id}?schoolName=${getDaDetails.name}`, { replace: false });
    } else if (getUserRoleId === 3) {
      districtLevelReportBreadCrumb();
    }
  }

  function lsbCumulativeStandardsProficiencyReportURL(level: string): void {
    if (
      (getUserRoleId === 4 && getDaDetails.type === 2) ||
      (getUserRoleId === 5 && getDaDetails.type === 2 && level === "school")
    ) {
      let school = getSchoolGuids.filter((item: SchoolGuidsResponseProps) => item.organizationGuid === urlId);
      const newCrumbs = [
        ...getBreadCrumbsData.crumbs,
        {
          path: `/cumulativestandardsproficiency/school/${school[0].organizationGuid}`,
          name: school[0].name
        }
      ];
      let removeDuplicatesInNewCrumbs = newCrumbs.filter((v, i, a) => a.findIndex((v2) => v2.name === v.name) === i);
      dispatch(updateBreadCrumbs({ crumbs: removeDuplicatesInNewCrumbs }));
      navigate(`/cumulativestandardsproficiency/school/${school[0].organizationGuid}`);
    } else if (getUserRoleId === 4 && getDaDetails.type === 1) {
      let school = getSchoolGuids.filter((item: SchoolGuidsResponseProps) => item.organizationGuid === getDaDetails.id);
      const newCrumbs = [
        ...getBreadCrumbsData.crumbs,
        {
          path: `/cumulativestandardsproficiency/school/${school[0].organizationGuid}`,
          name: getDaDetails.name
        }
      ];
      let removeDuplicatesInNewCrumbs = newCrumbs.filter((v, i, a) => a.findIndex((v2) => v2.name === v.name) === i);
      dispatch(updateBreadCrumbs({ crumbs: removeDuplicatesInNewCrumbs }));
      navigate(`/cumulativestandardsproficiency/school/${school[0].organizationGuid}`);
    } else if (getUserRoleId === 5 && getDaDetails.type === 1) {
      let teacher = getSchoolGuids.filter(
        (item: SchoolGuidsResponseProps) => item.organizationGuid === getDaDetails.id
      );
      const newCrumbs = [
        ...getBreadCrumbsData.crumbs,
        {
          path: `/cumulativestandardsproficiency/school/${teacher[0].orgGUID}`,
          name: getDaDetails.name
        }
      ];
      let removeDuplicatesInNewCrumbs = newCrumbs.filter((v, i, a) => a.findIndex((v2) => v2.name === v.name) === i);
      dispatch(updateBreadCrumbs({ crumbs: removeDuplicatesInNewCrumbs }));
      navigate(`/cumulativestandardsproficiency/school/${teacher[0].orgGUID}`);
    } else if (getUserRoleId === 3) {
      let district = getSchoolGuids.filter(
        (item: SchoolGuidsResponseProps) => item.organizationGuid === getDaDetails.id
      );
      if (district.length === 0) {
        district = [...district, getDistrictLevelLSBReportsData];
      }

      const newCrumbs = [
        ...getBreadCrumbsData.crumbs,
        {
          path: `/cumulativestandardsproficiency/district/${district[0].orgGUID}`,
          name: getDaDetails.name
        }
      ];
      let removeDuplicatesInNewCrumbs = newCrumbs.filter((v, i, a) => a.findIndex((v2) => v2.name === v.name) === i);
      dispatch(updateBreadCrumbs({ crumbs: removeDuplicatesInNewCrumbs }));
      navigate(`/cumulativestandardsproficiency/district/${district[0].orgGUID}`);
    }
  }

  const districtLevelReportBreadCrumb = () => {
    let district = getSchoolGuids.filter((item: SchoolGuidsResponseProps) => item.organizationGuid === getDaDetails.id);
    if (district.length === 0) {
      //  district = [...district, getDistrictLevelLSBReportsData]
    }
    const newCrumbs = [
      ...getBreadCrumbsData.crumbs,
      {
        path: `/reportcards`,
        name: "Reports"
      }
    ];
    let persistedReduxData = JSON.parse(localStorage.getItem("persist:ala-admin") || "");
    let getBreadCrumbReduxData = JSON.parse(persistedReduxData?.getBreadCrumbsData);
    let isBenchmarkGrowthReportPathExists = getBreadCrumbReduxData?.crumbs?.findIndex((item) =>
      item?.path?.includes("benchmark-growth-reports")
    );
    if (isBenchmarkGrowthReportPathExists !== -1) {
      newCrumbs?.splice(isBenchmarkGrowthReportPathExists, 1);
    }
    dispatch(updateBreadCrumbs({ crumbs: newCrumbs }));
    navigate(`/reports/usage/district/${getDaDetails.id}?districtName=${getDaDetails.name}`, { replace: false });
  };

  const redirectToIPlusA = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = `${window.APP_ENV.mps_ui_assessment_url}`;
  }

  return (
    <>
      {getReportCard === "home" && (
        <div>
          {props.getCurrentOrganizationDetails.licensedProducts.includes("IPA") && (
            <Banner 
              bannerTitle="Find your Diagnostic report in our Imagine+ Assessment Suite!"
              icon={IconEnum.IL_PLUS_BRAND}
              buttonTitle="Take me there"
              onClick={redirectToIPlusA}></Banner>
          )}
          <div className='report-link-cards-container'>
            {[1, 2, 3, 4, 5, 7].includes(getUserRoleId) && hasStandardsReportAccess && (
              <ReportLinkCard
                title={isSchoolYearLaterThan2025 ? 'Cumulative Standards' : 'Cumulative Standards Proficiency'}
                description={'Track standards mastery and proficiency across key skills'}
                image={ReportLinkCardEnum.CUMULATIVESTANDARDS}
                icon={IconEnum.CHEVRON_RIGHT}
                onClick={standardProficiencyReport}
                mpngStyle={true}
              />
            )}
            <ReportLinkCard
              title={'Usage Report'}
              description={'Track lesson progress, time spent, and completion status across schools and subjects'}
              image={ReportLinkCardEnum.USAGE}
              icon={IconEnum.CHEVRON_RIGHT}
              onClick={usageReport}
              mpngStyle={true}
            />
            {getUpdateLevel !== "class" &&
              getFlagrKeys["S59-B1146-ShowSisenseReport"] &&
              getUserRoleId !== 5 &&
              (getUserRoleId === 1 || getUserRoleId === 7 || getUserRoleId === 2
                ? getFlagrKeys["ShowSisenseReportForWNEUsers"]
                : true) && (
                <ReportLinkCard
                  title={isSchoolYearLaterThan2025 ? 'Cumulative Performance Report (CPR)' : 'Cumulative Performance Report'}
                  description={'Track progress, scores, and usage with advanced filtering for various metrics'}
                  image={ReportLinkCardEnum.CUMULATIVEPERFORMANCE}
                  icon={IconEnum.CHEVRON_RIGHT}
                  onClick={dsdsReport}
                  mpngStyle={true}
                />
              )}
              {getUpdateLevel !== "class" && getFlagrKeys.S49_383_ShowDistrictLevelGrowthReportCard && (
                <ReportLinkCard
                title={isSchoolYearLaterThan2025 ? 'Growth and Usage Report' : 'Benchmark Growth Report'}
                description={'Analyze progress and growth using assessment data and MyPath usage'}
                image={ReportLinkCardEnum.GROWTHUSAGE}
                icon={IconEnum.CHEVRON_RIGHT}
                onClick={growthReport}
                mpngStyle={true}
              />
              )}
              {!props.getIsMyPathOnly && (
                <ReportLinkCard
                  title={isSchoolYearLaterThan2025 ? 'Diagnostic Report' : 'MyPath Assessment Report'}
                  description={'Assess student grade level, analyze domain performance, and track growth over time'}
                  image={ReportLinkCardEnum.DIAGNOSTIC}
                  icon={IconEnum.CHEVRON_RIGHT}
                  onClick={myPathAssessmentReport}
                  mpngStyle={true}
                />
              )}
          </div>
        </div>
      )}
      {getReportCard === "usagereport" && <Reports id={id} isfrom={isfrom} />}
      </>
  );
}

export default connect(mapStateToProps)(ReportsTab);
