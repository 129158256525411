import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import MPASettingsContainer from "./MPASettingsContainer";
import { updateAssessmentTab, updatePreviousURL } from "../../Redux/Action";
import "./Assessment.scss";
import AssessmentSchedule from "./AssessmentSchedule";
import { UxTabs } from "@wne-spa/ux-component-library";
import ReduxProps from "../../Redux/Redux.props";
import { AppDispatch } from "../../Redux/Store";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getAssessmentTab: state.getAssessmentTab,
    getFlagrKeys: state.getFlagrKeys,
    updateFromSideBarAssessment: state.updateFromSideBarAssessment
  };
}
type AssesmentCardsProps = {
  dispatch: AppDispatch;
  isFromSideBarAssessment: boolean;
  assessmentTab?: string;
  id?: string;
};

interface Props extends AssesmentCardsProps, ReduxProps {}
function AssessmentCards(props: Props) {
  useEffect(() => {
    //do nothing
  }, []);
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const navigate = useNavigate();
  const [tabKey, setTabKey] = useState("schedule");
  const { dispatch, isFromSideBarAssessment, assessmentTab, getAssessmentTab, updateFromSideBarAssessment } = props;

  useEffect(() => {
    if (updateFromSideBarAssessment) {
      setTabKey("settings");
    }
  }, [dispatch, assessmentTab, updateFromSideBarAssessment]);

  function settings() {
    dispatch(updateAssessmentTab("settings"));
  }

  function schedule() {
    dispatch(updateAssessmentTab("schedule"));
  }

  let tabs = [
    {
      eventKey: "settings",
      title: "Settings",
      content: <MPASettingsContainer id={id} isFromSideBarAssessment={isFromSideBarAssessment} />
    },
    {
      eventKey: "schedule",
      title: "Scheduling",
      content: <AssessmentSchedule isFromSideBarAssessment={isFromSideBarAssessment} />
    }
  ];

  function isAssessmentTabClicked(tab: string) {
    dispatch(updatePreviousURL(window.location.pathname));
    if (tab === "schedule") {
      navigate("/assessmentschedule");
    }
    setTabKey(tab);
  }

  return (
    <>
      {updateFromSideBarAssessment ? (
        <div>
          <UxTabs
            tabsId="class-tabs"
            activeKey={tabKey}
            onTabSelect={(tab: string) => {
              isAssessmentTabClicked(tab);
            }}
            children={tabs}
            className="pt-2"
            currentKey={tabKey}
          />
        </div>
      ) : null}

      {!updateFromSideBarAssessment && getAssessmentTab === "home" && (
        <div className={isFromSideBarAssessment ? "assessmentSideBarCards" : "assessmentCards"}>
          <div
            className="assessmentSettingsCard"
            onClick={() => {
              //@ts-ignore
              settings();
            }}
          >
            <label className="assessmentSettingsText">Assessment Settings</label>
          </div>
          <div
            className="assessmentScheduleCard"
            onClick={() => {
              schedule();
            }}
          >
            <label className="assessmentScheduleText">Assessment Scheduling</label>
          </div>
        </div>
      )}

      {!updateFromSideBarAssessment && getAssessmentTab === "settings" && (
        <div>
          <MPASettingsContainer id={id} isFromSideBarAssessment={isFromSideBarAssessment} />
        </div>
      )}
      {!updateFromSideBarAssessment && getAssessmentTab === "schedule" && (
        <>
          <AssessmentSchedule isFromSideBarAssessment={isFromSideBarAssessment} />
        </>
      )}
    </>
  );
}

export default connect(mapStateToProps)(AssessmentCards);
