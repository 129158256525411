import React, { useEffect, useState } from "react";
import ReduxProps from "../../Redux/Redux.props";
import { AppDispatch } from "../../Redux/Store";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  updateActivityIndicator,
  updateReportCard,
  updateTabName,
  updatePreviousURLForClassProgress,
  updateClassViewTab,
  updateClassSummaryReport,
  updateAssessmentTab,
  updateSchoolGuids,
  updateStudentsSubtab
} from "../../Redux/Action";
import { UxTabs } from "@wne-spa/ux-component-library";
import { LMSService } from "../../services/helpers/lms.services";
import { AdminService } from "../../services/helpers/api.services";
import { RootComponentProps } from "../../root-component.props";
import StudentDropdownTab from "../HeaderTabs/StudentDropdownTab";
import AssessmentDropdownTab from "../HeaderTabs/AssessmentDropdownTab";
import { GuidResponseProps, ItemProps, SchoolGuidsResponseProps } from "./Reports.props";

function mapStateToProps(state: ReduxProps) {
  return {
    getLSBReport: state.getLSBReport,
    getUpdateLevel: state.getUpdateLevel,
    getClassDetails: state.getClassDetails,
    classSummaryReport: state.classSummaryReport,
    getSchoolGuids: state.getSchoolGuids,
    getUserRoleId: state.getUserRoleId,
    getPreviousMyPathURL: state.getPreviousMyPathURL,
    getDistrictLevelFirstTab: state.getDistrictLevelFirstTab,
    getIsMyPathOnly: state.getIsMyPathOnly
  };
}

type CumulativeStandardsProficiencyProps = {
  dispatch: AppDispatch;
};
interface Props extends ReduxProps, RootComponentProps, CumulativeStandardsProficiencyProps { }

function CumulativeStandardsProficiency(props: Props) {
  const { id, level } = useParams<{ id: string | undefined; level: string | undefined }>();
  const navigate = useNavigate();
  const tabKey = "reports";

  const {
    dispatch,
    getLSBReport,
    getUpdateLevel,
    getClassDetails,
    mpngStudentIlpSubject,
    getSchoolGuids,
    getUserRoleId,
    getPreviousMyPathURL,
    getDistrictLevelFirstTab,
    classSummaryReport,
  } = props;

  const [selectedStudentView, setSelectedStudentView] = useState("home");

  useEffect(() => {
    dispatch(updateActivityIndicator(false));
  }, []);

  const fetchIds = async () => {
    try {
      let ids = window.location.pathname.split("/");
      let guids: string[] = [];
      guids = [...guids, `${ids[ids.length - 1]}`];
      let orgguids = { guids };
      AdminService.getIds(orgguids).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          guidDetails(data, guids);
        }
      });
    } catch (err) {
      //do nothing
    }
  };

  async function guidDetails(repsonseData: Response, guids: string[]) {
    let details = JSON.parse(await repsonseData.text());
    let filteredData = details.filter((item: GuidResponseProps) => item.organizationGuid === guids[0]);
    let exist = getSchoolGuids.filter((item: SchoolGuidsResponseProps) => item.id === filteredData[0].organizationId);
    if (exist.length === 0) {
      let guids = [...getSchoolGuids, filteredData[0]];
      dispatch(updateSchoolGuids(guids));
    }

    if (getUserRoleId === 3) {
      navigate(`/daschool/${filteredData[0].organizationGuid}`);
    } else {
      navigate(`/school/${filteredData[0].organizationGuid}`);
    }
  }

  function isReportsTabClicked(tab: string, _pageName: string = "") {
    if (tab === "students" || tab === "Assessments") {
      return;
    }
    dispatch(updateReportCard("home"));
    dispatch(updateAssessmentTab("home"));
    dispatch(updateStudentsSubtab("home"));

    if (tab === "sudo-students") {
      dispatch(updateTabName("students"));
      dispatch(updateStudentsSubtab(_pageName));
    } else if (tab === "sudo-Assessments") {
      dispatch(updateTabName("Assessments"));
      dispatch(updateAssessmentTab(_pageName));
    } else if (tab === "institutions" || tab === "school") {
      dispatch(updateTabName("schools"));
    } else if (tab !== "classprogress" && tab !== "classsummary") {
      dispatch(updateTabName(tab));
    }

    if (getUpdateLevel === "school") {
      if (getUserRoleId != 4 && getUserRoleId != 5) {
        fetchIds();
      } else {
        navigate(`${getPreviousMyPathURL}`);
      }
    } else if (getUpdateLevel === "district") {
      navigate(`${getPreviousMyPathURL}`);
    } else if (getUpdateLevel === "class" || getUpdateLevel === "student") {
      if (tab === "classprogress") {
        dispatch(updatePreviousURLForClassProgress(`/classview/${getClassDetails.classGUID}`));
        navigate("/classprogress");
      } else if (tab === "classsummary") {
        navigate(`/classsummary/${getClassDetails.classGUID}`);
      } else {
        dispatch(updateTabName(tab));
        dispatch(updateClassViewTab(tab));
        navigate(`/classview/${getClassDetails.classGUID}`);
      }
    }
  }

  let classTabs = [
    {
      eventKey: "classsummary",
      title: "Class Summary"
    },
    {
      eventKey: "classprogress",
      title: "Class Progress"
    },
    {
      eventKey: "placement",
      title: "Student Placement"
    },
    {
      eventKey: "roster",
      title: "Student Roster"
    },

    {
      eventKey: "reports",
      title: "Reports"
    },

    {
      eventKey: "settings",
      title: "Settings"
    }
  ];

  function setAssessmentTab(tabName: string) {
    isReportsTabClicked("sudo-Assessments", tabName);
  }

  const setPage = (pageName: string) => {
    dispatch(updateAssessmentTab("home"));
    setSelectedStudentView(pageName);
    isReportsTabClicked("sudo-students", pageName);
  };

  let schoolTabs = [
    {
      eventKey: "classes",
      tabClassName: "",
      title: "Classes"
    },
    {
      eventKey: "students",
      tabClassName: "student-tab",
      title: <StudentDropdownTab onNavClick={setPage} currentView={selectedStudentView} />
    },
    {
      eventKey: "educators",
      tabClassName: "",
      title: "Educators"
    },
    ...(props.getIsMyPathOnly === false ? [{
      eventKey: "Assessments",
      tabClassName: "assessments-tab",
      title: <AssessmentDropdownTab onNavClick={setAssessmentTab} />
    }] : []),
    {
      eventKey: "reports",
      tabClassName: "",
      title: "Reports"
    },
    {
      eventKey: "AcademicSessions",
      tabClassName: "",
      title: "Academic Sessions"
    },
    {
      eventKey: "settings",
      tabClassName: "",
      title: "Settings"
    }
  ];

  let districtLeveltabs = [
    {
      eventKey: "institutions",
      tabClassName: "",
      title: getDistrictLevelFirstTab
    },
    {
      eventKey: "students",
      tabClassName: "student-tab",
      title: <StudentDropdownTab onNavClick={setPage} currentView={selectedStudentView} />
    },
    {
      eventKey: "educators",
      title: "Educators"
    },
    ...(props.getIsMyPathOnly === false ? [{
      eventKey: "Assessments",
      tabClassName: "assessments-tab",
      title: <AssessmentDropdownTab onNavClick={setAssessmentTab} />
    }] : []),
    {
      eventKey: "reports",
      title: "Reports"
    },
    {
      eventKey: "licensing",
      title: "Licensing"
    },
    {
      eventKey: "AcademicSessions",
      title: "Academic Sessions"
    },
    {
      eventKey: "settings",
      title: "Settings"
    }
  ];

  districtLeveltabs =
    getUserRoleId === 3
      ? districtLeveltabs.filter((item: ItemProps) => item.eventKey != "licensing")
      : districtLeveltabs;


  const updateChildrenLevel = () => {
    if (getUpdateLevel === "district") {
      return districtLeveltabs;
    } else if (getUpdateLevel === "school") {
      return schoolTabs;
    }
    else {
      return classTabs;
    }
  };

  const processClassDetails = async (data: Response) => {
    const classDetails = await data.json();

    dispatch(
      updateClassSummaryReport({
        classId: classDetails.classGUID,
        className: classDetails.classTitle,
        subject: classDetails.subjects[0]?.subject,
        academicSessionId: classDetails.academicSessionGuid,
        orgTimezone: classDetails.organizationTimeZone,
        schoolYear: classDetails.academicSessionSchoolYear,
        schoolGeoState: classDetails.schoolGeoState,
        organizationId: classDetails.organizationGuid
      })
    );

    const classDetailsWithGuid = {
      academicSessionId: `${classDetails.academicSessionGuid}`,
      schoolYear: `${classDetails.academicSessionSchoolYear}`,
      orgTimezone: `${classDetails.organizationTimeZone}`,
      ilpId: ``,
      studentId: ``,
      subject: ``,
      firstname: ``,
      lastname: ``,
      className: `${classDetails.classTitle}`
    };
    mpngStudentIlpSubject?.next(classDetailsWithGuid);
  };

  const fetchClassDetailsWithGuid = async () => {
    try {
      LMSService.getClassDetailsWithGuid(id).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          processClassDetails(data);
        }
      });
    } catch (err) {
      // Do nothing
    }
  };

  const updateMpngStudentIlpSubjectFromStore = () => {
    const classDetailsWithGuid = {
      academicSessionId: `${classSummaryReport?.academicSessionId}`,
      schoolYear: `${classSummaryReport?.schoolYear}`,
      orgTimezone: `${classSummaryReport?.orgTimezone}`,
      ilpId: ``,
      studentId: ``,
      subject: ``,
      firstname: ``,
      lastname: ``,
      className: `${classSummaryReport?.className}`
    };
    mpngStudentIlpSubject?.next(classDetailsWithGuid);
  };

  useEffect(() => {
    if (level !== "class") {
      updateMpngStudentIlpSubjectFromStore();
    } else {
      fetchClassDetailsWithGuid();
    }
  }, [id, level]);

  return (
    <>
      {!getLSBReport && (
        <UxTabs
          tabsId="class-tabs"
          activeKey={tabKey}
          onTabSelect={(tab: string) => {
            isReportsTabClicked(tab);
            localStorage.setItem("page", tab);
          }}
          children={updateChildrenLevel()}
          className="pt-2"
          currentKey={tabKey}
        />
      )}
      <div id="standardsproficiencyreport"></div>
    </>
  );
}

export default connect(mapStateToProps)(CumulativeStandardsProficiency);
