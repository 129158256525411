import React, { useEffect, useState, useRef } from "react";
import {
  UxMUITable,
  UxSearchInput,
  UxIcon,
  UxIconEnum,
  UxSizeEnum,
  TimesIcon,
  UxPaginationController,
  UxPaginate
} from "@wne-spa/ux-component-library";
import {
  updateBreadCrumbs,
  updateActivityIndicator,
  updatePaginationControllerState,
  updateDistrictLevelFirstTab,
  updateSchoolGuids,
  updateDistrictLevelLSBReportsData,
  updateAssignmentBuilderOrg,
  updatePageTitle
} from "../../Redux/Action";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { UserService } from "../../services/helpers/user.services";
import { AdminService } from "../../services/helpers/api.services";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { paginationControllers } from "../../helpers/configs";
import { LMSService } from "../../services/helpers/lms.services";
import { SchoolGuidsResponseProps, Value } from "./AssignmentBuilder.props";
import { AppDispatch } from "../../Redux/Store";
import ReduxProps, { OrganizationDetails } from "../../Redux/Redux.props";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getActivityIndicator: state.getActivityIndicator,
    getDaDetails: state.getDaDetails,
    getUserRoleId: state.getUserRoleId,
    getUserId: state.getUserId,
    getTabChange: state.getTabChange,
    getPaginationControllerState: state.getPaginationControllerState,
    getTabName: state.getTabName,
    getStudentSubtab: state.getStudentSubtab,
    getFlagrKeys: state.getFlagrKeys,
    getAssessmentTab: state.getAssessmentTab,
    getClassFilter: state.getClassFilter,
    getSchoolGuids: state.getSchoolGuids
  };
}

type DAABuilderListProps = {
  dispatch: AppDispatch;
};

interface Props extends DAABuilderListProps, ReduxProps {}

const DAABuilderList = (props: Props) => {
  const { dispatch, getPaginationControllerState, getBreadCrumbsData, getSchoolGuids, getDaDetails, getUserId } = props;
  const [tableHgt, setTableHgt] = useState(0);
  const [searchText, setSearchText] = useState("");
  const searchResultRef = useRef(false);
  const textMessage = useRef("none");
  const [emptyTableText, setEmptyTableText] = useState("");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [custBool, setCustBool] = useState<boolean | null>(null);
  const [categoryBool, setCategoryBool] = useState<boolean | null>(null);
  const [pageNum, setpageNum] = useState(1);
  const sortBy = useRef("organizationtitle");
  const sortOrder = useRef("ASC");
  const { id } = useParams<{ id: string | undefined }>();
  const [data, setData] = useState({
    page: 0,
    size: 0,
    pageSize: 0,
    values: []
  });
  const elementRef = useRef(null);
  const rowMapper = [["name", "rootPath"], "orgType"];
  const [columnName, setcolumnName] = useState(["Institutions Name", "Category"]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getSelectedOrgInfo = async (selectedOrgGuid: string) => {
    try {
      const { status, data } = await AdminService.getCurrentOrganizationDetails(selectedOrgGuid);
      if (status === 200) {
        const details: OrganizationDetails = await data.json();
        return details;
      }
    } catch (err) {
      console.error("Failed to fetch organization details", err);
    }
    return null;
  };

  useEffect(() => {
    dispatch(updatePageTitle(`- Assignment Builder`));
    const fetchData = async () => {
      try {
        setLoading(true);
        setSearchText("");
        const jsonObj = {
          Page: 1,
          PageSize: getPaginationControllerState[6].paginationCount,
          OrgGuid: id,
          SearchText: "",
          FilterBy: 1,
          SortBy: sortBy.current,
          SortOrder: sortOrder.current
        };
        AdminService.searchChildOrganizationsWithGuid(jsonObj).then(
          ({ status, data }: { status: number; data: Response }) => {
            if (status === 200) {
              gotResponse(data, false);
            } else {
              // do nothing
            }
          }
        );
      } catch (err) {
        //do nothing
      }
    };

    const fetchBreadCrumbs = async () => {
      try {
        let apiObj = { rid: getDaDetails.id, cid: id };
        await AdminService.getParentsWithRootWithGuid(apiObj).then(
          ({ status, data }: { status: number; data: Response }) => {
            if (status === 200) {
              handleSuccessResponse(data);
            } else {
              dispatch(updateActivityIndicator(false));
            }
          }
        );
      } catch (err) {
        //do nothing
      }
    };

    dispatch(updateActivityIndicator(true));
    fetchBreadCrumbs();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  async function handleSuccessResponse(responsedata: Response) {
    let details = await responsedata.json();
    // @ts-ignore
    if (details.parents === null) {
      //@ts-ignore
      const breadcrumb = {
        crumbs: [
          {
            path: `/assignmentbuilder/districts/${getDaDetails.id}`,
            name: "Assignment Builder"
          }
        ]
      };
      dispatch(updateBreadCrumbs(breadcrumb));
    } else {
      let breadcrumb = { crumbs: [] };
      //@ts-ignore
      let crumbs = details.parents.reverse();
      crumbs[0].name = "Assignment Builder";
      for (let x of crumbs) {
        //@ts-ignore
        breadcrumb.crumbs.push({
          //@ts-ignore
          path: `/assignmentbuilder/districts/${x.orgGUID}`,
          //@ts-ignore
          name: x.name
        });
      }
      //@ts-ignore
      breadcrumb.crumbs.push({
        //@ts-ignore
        path: `/assignmentbuilder/districts/${details.orgGUID}`,
        //@ts-ignore
        name: details.name
      });
      dispatch(updateBreadCrumbs(breadcrumb));
    }
    let exist = getSchoolGuids.filter((item: SchoolGuidsResponseProps) => item.id === details.id);
    if (exist.length === 0) {
      let schoolGuids: SchoolGuidsResponseProps[] = [...getSchoolGuids, details];
      dispatch(updateSchoolGuids(schoolGuids));
      if (details.parents === null) {
        dispatch(updateDistrictLevelLSBReportsData(details));
      } else {
        dispatch(updateDistrictLevelLSBReportsData(details.parents[details.parents.length - 1]));
      }
    }
    dispatch(updateActivityIndicator(false));
  }

  function handleSearchTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.charAt(0) !== " ") {
      setSearchText(event.target.value);
    }
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key === "Enter" && searchText !== "") {
      searchResultRef.current = true;
      textMessage.current = "Search";
      setpageNum(1);
      searchSchools(1, searchText);
    }
  }

  function renderSearchButton() {
    return <UxIcon icon={UxIconEnum.SEARCH} size={UxSizeEnum.MEDIUM} className="searchIcon" />;
  }

  function handleSearchButtonClick(): void {
    if (searchText !== "") {
      searchResultRef.current = true;
      textMessage.current = "Search";
      searchSchools(1, searchText);
      setpageNum(1);
    }
  }

  function renderCancelButton() {
    if (searchText !== "" && !searchResultRef.current) {
      return <img src={TimesIcon} alt="" width="20px" height="20px" />;
    } else if (searchText !== "" && searchResultRef.current) {
      return (
        <div className="searchIconText">
          <span> Clear Results </span>
        </div>
      );
    }
  }

  async function gotResponse(responsedata: Response, from: boolean) {
    let allData = await responsedata.json();
    if (sortBy.current === "Category") {
      setCustBool(null);
    } else {
      setCategoryBool(null);
    }
    if (allData.size === 0) {
      setEmptyTableText("No Records found");
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });
    } else {
      setData(allData);
      for (const x of allData.values) {
        if (x.childDepth > 0) {
          dispatch(updateDistrictLevelFirstTab("Institutions"));
          setcolumnName(["Institutions Name", "Category"]);
          break;
        }
      }
      setLoading(false);
      handleResize();
    }
    if (from) {
      dispatch(updateActivityIndicator(false));
    }
  }

  function handleResize() {
    if (elementRef.current !== null) {
      if (window.innerHeight > 650) {
        //@ts-ignore
        setTableHgt(
          //@ts-ignore
          parseInt(window.innerHeight - (elementRef.current.offsetTop + 85))
        );
      } else {
        //@ts-ignore
        setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
      }
    }
  }

  function clearButtonClick() {
    if (searchText !== "" && !searchResultRef.current) {
      setSearchText("");
    } else if (searchText !== "" && searchResultRef.current) {
      setSearchText("");
      searchResultRef.current = false;
      setSearchText("");
      searchSchools(1, "");
      sortOrder.current = "ASC";
      sortBy.current = "organizationtitle";
      setCustBool(false);
      setCategoryBool(false);
      setpageNum(1);
    }
  }
  async function searchSchools(pageNo: number, stext: string) {
    setData({
      page: 0,
      size: 0,
      pageSize: 0,
      values: []
    });
    try {
      dispatch(updateActivityIndicator(true));
      const jsonObj = {
        Page: pageNo,
        PageSize: getPaginationControllerState[6].paginationCount,
        OrgGuid: id,
        SearchText: stext,
        FilterBy: 1,
        SortBy: sortBy.current,
        SortOrder: sortOrder.current
      };

      AdminService.searchChildOrganizationsWithGuid(jsonObj).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            gotResponse(data, true);
          } else {
            dispatch(updateActivityIndicator(false));
            setEmptyTableText("No Records found");
            setData({
              page: 0,
              size: 0,
              pageSize: 0,
              values: []
            });
          }
        }
      );
    } catch (err) {
      dispatch(updateActivityIndicator(false));
      setEmptyTableText("No Records found");
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });
      //do nothing
    }
  }
  const Styles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        "& > *": {
          marginTop: theme.spacing(2)
        }
      }
    })
  );
  let classes = Styles();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setpageNum(value);
    dispatch(updateActivityIndicator(true));
    searchSchools(value, searchText);
  };
  function restoreDefaultCheckState() {
    setSelected([]);
  }

  function sortChange(type: string) {
    if (type === "Institutions Name") {
      if (!custBool) {
        sortOrder.current = "DESC";
      } else {
        sortOrder.current = "ASC";
      }
      sortBy.current = "organizationtitle";
    } else {
      if (!categoryBool) {
        sortOrder.current = "DESC";
      } else {
        sortOrder.current = "ASC";
      }
      sortBy.current = "OrgType";
    }
    searchSchools(1, searchText);
  }

  async function callback(args: number) {
    let updateState = getPaginationControllerState;
    updateState[6] = {
      paginationPageName: "Institutions",
      paginationCount: args
    };
    setpageNum(1);
    dispatch(updatePaginationControllerState(updateState));
    try {
      dispatch(updateActivityIndicator(true));
      UserService.updatePaginationCount(getUserId, 7, args).then(({ status }: { status: number; data: Response }) => {
        if (status === 200) {
          searchSchools(1, searchText);
        } else {
          dispatch(updateActivityIndicator(false));
        }
      });
    } catch (err) {
      dispatch(updateActivityIndicator(false));
    }
  }

  async function getLicensedSubjects(schoolId: number) {
    try {
      await LMSService.getSubjects(schoolId).then(({ status }: { status: number; data: Response }) => {
        if (status === 200) {
          navigate(`/assignmentbuilder/dashboard`);
        } else {
          navigate(`/noLicenseProducts`);
          dispatch(updateActivityIndicator(true));
        }
      });
    } catch (error) {
      //do nothing
    }
  }

  const handleClick = async (row: Value) => {
    setData({
      page: 0,
      size: 0,
      pageSize: 0,
      values: []
    });

    const exist = getSchoolGuids.filter((item: SchoolGuidsResponseProps) => item.id === row.id);
    if (exist.length === 0) {
      let updatedSchoolGuids: SchoolGuidsResponseProps[] = [];
      updatedSchoolGuids = [...updatedSchoolGuids, row];
      dispatch(updateSchoolGuids(updatedSchoolGuids));
    }

    if (row.childDepth === 0) {
      const orgInfo = await getSelectedOrgInfo(row.organizationGuid);
      const orgData = {
        schoolId: row.id,
        schoolGuid: row.organizationGuid,
        schoolName: row.name,
        timezone: orgInfo?.timezone,
        licensedProducts: orgInfo?.licensedProducts,
        schoolYear: orgInfo?.academicSession.schoolYear
      };
      dispatch(updateAssignmentBuilderOrg(orgData));
      dispatch(updateActivityIndicator(true));
      getLicensedSubjects(row.id);
      let breadcrumb = getBreadCrumbsData.crumbs;
      breadcrumb.push({
        path: `/assignmentbuilder/dashboard`,
        name: row.name
      });
      dispatch(updateBreadCrumbs({ crumbs: breadcrumb }));
      navigate(`/assignmentbuilder/dashboard`);
    } else {
      let breadcrumb = getBreadCrumbsData.crumbs;
      breadcrumb.push({
        path: `/assignmentbuilder/districts/${row.organizationGuid}`,
        name: row.name
      });
      dispatch(updateBreadCrumbs({ crumbs: breadcrumb }));
      navigate(`/assignmentbuilder/districts/${row.organizationGuid}`);
    }
  };

  function tableList() {
    return (
      <div className="class-nav-tabs">
        <div className="tab-content">
          <div className="institutionActionInputHolder">
            <UxSearchInput
              flexClassName="eFlexHolder"
              inputClassName="eInputText"
              placeHolder="Search School or District"
              value={searchText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchTextChange(e)}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                handleKeyPress(e);
              }}
              searchButtonClassName="searchButton"
              searchButtonContent={renderSearchButton()}
              onSearchClick={() => {
                handleSearchButtonClick();
              }}
              clearButtonClassName="clearButton"
              clearButtonContent={renderCancelButton()}
              onClearClick={() => {
                clearButtonClick();
              }}
              restoreCheckBoxState={() => {
                restoreDefaultCheckState();
              }}
            />
          </div>
          <div ref={elementRef}>
            <UxMUITable
              MUITableClassName="bg-white institutionSchoolTable"
              columnNames={columnName}
              rowMapper={rowMapper}
              rowData={data.values}
              showColumnCheckbox={false}
              viewChild={handleClick}
              emptyTableText={emptyTableText}
              checkboxRef="loginName"
              selected={selected}
              setSelected={setSelected}
              showKebabMenu={false}
              showNameKebabMenu={false}
              showToolTip={true}
              mtableHeight={tableHgt}
              showSortList={[true, true]}
              showStatusText={false}
              sortListMapper={{
                "Institutions Name": [
                  custBool,
                  setCustBool,
                  (args: string) => {
                    sortChange(args);
                  }
                ],
                Category: [
                  categoryBool,
                  setCategoryBool,
                  (args: string) => {
                    sortChange(args);
                  }
                ]
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  function loadingFunc() {
    if (data.values.length === 0) {
      return null;
    } else {
      return (
        <div className="fixedpagination">
          <UxPaginate
            data={data}
            page={pageNum}
            paginationSize={getPaginationControllerState[6].paginationCount}
            className={classes.root}
            handleChange={handleChange}
          />
          {data.size > 50 && (
            <UxPaginationController
              rowsList={paginationControllers}
              setRowsCount={getPaginationControllerState[6].paginationCount}
              callback={callback}
            />
          )}
        </div>
      );
    }
  }
  return (
    <>
      {tableList()}
      {loading ? null : loadingFunc()}
    </>
  );
};

export default connect(mapStateToProps)(DAABuilderList);
