import { WindowAPPENVprops } from "../root-component.props";

declare global {
  interface Window {
    APP_ENV: WindowAPPENVprops;
  }
}

export function clearLocalStorage() {
  localStorage.removeItem("lms:isRenew");
  localStorage.removeItem("isLogoutClicked");
  localStorage.removeItem("persist:growth-reports");
  localStorage.removeItem("persist:mypath-reports");
  localStorage.removeItem("oidc:session");
  localStorage.removeItem("page");
  localStorage.removeItem("oidc:state");
  localStorage.removeItem("ala:MppLaunch");
  for (let i = 0; i < localStorage.length; i++) {
    if (localStorage.key(i).includes("oidc.")) {
      localStorage.removeItem(localStorage.key(i));
    }
  }
}
